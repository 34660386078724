import { observer } from 'mobx-react-lite';
import {
	SigmaFolder,
	SigmaFolderItem,
	SigmaWorkbook,
} from 'modules/clients/customer-api/src/sigmaEmbed';
import { useSnackbar } from 'notistack';
import {
	FC,
	ReactNode,
	createContext,
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useClients } from 'shared/hooks/useClients';

//#region Types

export type SigmaMode = 'view' | 'create' | 'edit';

//#endregion

//#region Context

export type SigmaContextProps = {
	initializing: boolean;
	sigmaUrl?: string;
	recentlyDeletedFolder?: SigmaFolder;
	recentlyDeletedWorkbooks: SigmaWorkbook[];
	workbooks: SigmaWorkbook[];
	workbook?: SigmaWorkbook;
	folders: SigmaFolder[];
	folder?: SigmaFolder;
	generateSigmaUrl: (
		sigmaWorkbook?: SigmaWorkbook | undefined,
		mode?: SigmaMode,
	) => void;
	addFolder: (folder: SigmaFolder) => void;
	updateFolder: (folder: SigmaFolder) => void;
	removeFolder: (folder: SigmaFolder) => void;
	selectFolder: (folder: SigmaFolder) => void;
	deleteWorkbook: (deletedWorkbook: SigmaWorkbook) => void;
	addWorkbook: (newWorkbook: SigmaWorkbook) => void;
	updateWorkbook: (updatedWorkbook: SigmaWorkbook) => void;
	updateWorkbooks: (updatedWorkbooks: SigmaWorkbook[]) => void;
	removeWorkbooks: (deletedWorkbooks: SigmaWorkbook[]) => void;
	selectWorkbook: (
		selectedWorkbook: SigmaWorkbook | undefined,
		mode?: SigmaMode,
	) => void;
	path?: string[];
	loadingNewItem?: boolean;
	createFolderItems?: (
		item: SigmaFolder | SigmaWorkbook,
		order: number,
		workbook: boolean,
	) => void;
	getFolderItems?: (parentId: string) => void;
	updateFolderItems?: (
		item: SigmaFolder | SigmaWorkbook,
		order: number,
		workbook: boolean,
	) => void;
};

const SigmaContext = createContext<SigmaContextProps>({
	initializing: true,
	recentlyDeletedWorkbooks: [],
	workbooks: [],
	folders: [],
	addFolder: () => {},
	updateFolder: () => {},
	removeFolder: () => {},
	selectFolder: () => {},
	addWorkbook: () => {},
	deleteWorkbook: () => {},
	updateWorkbook: () => {},
	updateWorkbooks: () => {},
	removeWorkbooks: () => {},
	selectWorkbook: () => {},
	generateSigmaUrl: () => {},
	path: [],
	loadingNewItem: false,
	createFolderItems: () => {},
	getFolderItems: () => {},
	updateFolderItems: () => {},
});

//#endregion

//#region Provider

export type SigmaProviderProps = {
	solution?: string;
	children: ReactNode;
};

export const SigmaProvider: FC<SigmaProviderProps> = observer(
	({ children, solution }) => {
		const { customerApiClient } = useClients();
		const { enqueueSnackbar } = useSnackbar();
		const [path, setPath] = useState<string[]>([]);
		const [initializing, setInitalizing] = useState(true);
		const [sigmaUrl, setSigmaUrl] = useState<string>();
		const [recentlyDeletedFolder, setRecentlyDeletedFolder] =
			useState<SigmaFolder>();
		const [workbooks, setWorkbooks] = useState<SigmaWorkbook[]>([]);
		const [workbook, setWorkbook] = useState<SigmaWorkbook>();
		const [folders, setFolders] = useState<SigmaFolder[]>([]);
		const [folder, setFolder] = useState<SigmaFolder>();
		const [userId, setUserId] = useState<string>();
		const [loadingData, setLoadingData] = useState(false);
		const [loadedOrders, setLoadedOrders] = useState(false);
		const recentlyDeletedWorkbooks = useMemo(() => {
			if (recentlyDeletedFolder) {
				return workbooks.filter((x) => x.folderId === recentlyDeletedFolder.id);
			} else {
				return [];
			}
		}, [recentlyDeletedFolder, workbooks]);
		const [loadingNewItem, setLoadingNewItem] = useState(false);

		const generateSigmaUrl = useCallback(
			async (
				sigmaWorkbook: SigmaWorkbook | undefined = workbook,
				mode: SigmaMode = 'view',
			) => {
				const response = await customerApiClient.sigmaEmbed.sigmaUrl({
					workbookId: sigmaWorkbook?.id!,
					workbookUrlId: sigmaWorkbook?.urlId!,
					embedMode: mode,
				});
				setSigmaUrl(response.data);
			},
			[customerApiClient.sigmaEmbed, workbook],
		);

		const updateWorkbooks = useCallback(
			(updatedWorkbooks: SigmaWorkbook[]) => {
				const nextWorkbooks = workbooks.filter(
					(currentWorkbook) =>
						!updatedWorkbooks.some((x) => x.id === currentWorkbook.id),
				);
				nextWorkbooks.push(...updatedWorkbooks);
				setWorkbooks(nextWorkbooks);
			},
			[workbooks],
		);

		const getFolderPath = useCallback(
			(folderId: string, folderList: SigmaFolder[] = folders): string[] => {
				const currentFolder = folderList.find((x) => x.id === folderId);
				if (currentFolder) {
					if (currentFolder.parentId) {
						return [
							...getFolderPath(currentFolder.parentId, folderList),
							currentFolder.id,
						];
					} else {
						return [currentFolder.id];
					}
				} else {
					return [];
				}
			},
			[folders],
		);

		const selectWorkbook = useCallback(
			async (
				selectedWorkbook: SigmaWorkbook | undefined,
				mode: SigmaMode = 'view',
			) => {
				setLoadingNewItem(true);
				setWorkbook(undefined);
				if (selectedWorkbook?.folderId !== folder?.id) {
					setFolder(folders.find((x) => x.id === selectedWorkbook?.folderId));
					setPath(getFolderPath(selectedWorkbook?.folderId!));
				}
				if (workbook !== selectedWorkbook) {
					await generateSigmaUrl(selectedWorkbook, mode);
					setWorkbook(selectedWorkbook);
				}
				setLoadingNewItem(false);
			},
			[folder?.id, folders, generateSigmaUrl, workbook, getFolderPath],
		);

		const selectFolder = useCallback(
			(selectFolder: SigmaFolder, path: string[] | null = null) => {
				if (selectFolder) {
					setLoadingNewItem(true);
					setFolder(selectFolder);
					const sortedWorkbooks = workbooks
						.filter((x) => x.folderId === selectFolder.id)
						.sort((a, b) => {
							if (a.order == null && b.order != null) return 1;
							if (a.order != null && b.order == null) return -1;
							return (a.order ?? 0) - (b.order ?? 0);
						});

					if (sortedWorkbooks.length > 0) {
						selectWorkbook(sortedWorkbooks[0]);
					} else {
						setWorkbook(undefined);
					}
					if (path) {
						setPath(path);
					} else {
						setPath(getFolderPath(selectFolder.id));
					}
					setLoadingNewItem(false);
				}
			},
			[selectWorkbook, workbooks, getFolderPath],
		);

		const addFolder = useCallback(
			(newFolder: SigmaFolder) => {
				setFolders((prevFolders) => {
					const updatedFolders = [...prevFolders, newFolder];

					const folderPath = getFolderPath(newFolder.id, updatedFolders);

					selectFolder(newFolder, folderPath);

					return updatedFolders;
				});
			},
			[selectFolder, getFolderPath],
		);

		const addWorkbook = useCallback(
			(newWorkbook: SigmaWorkbook) => {
				const currentWorkbooks = [...workbooks];
				currentWorkbooks.push(newWorkbook);
				setWorkbooks(currentWorkbooks);
				selectWorkbook(newWorkbook);
			},
			[selectWorkbook, workbooks],
		);

		const updateWorkbook = useCallback(
			(updatedWorkbook: SigmaWorkbook) => {
				const nextWorkbooks = workbooks.filter(
					(x) => x.id !== updatedWorkbook.id,
				);
				nextWorkbooks.push(updatedWorkbook);
				setWorkbooks(
					nextWorkbooks.sort((a, b) => a.name.localeCompare(b.name)),
				);
				selectWorkbook(updatedWorkbook);
			},
			[selectWorkbook, workbooks],
		);

		const deleteWorkbook = useCallback(
			(deletedWorkbook: SigmaWorkbook) => {
				updateWorkbook(deletedWorkbook);
				setWorkbook(undefined);
				var folder = folders.find((x) => x.id === path[path.length - 1]);
				if (folder) {
					selectFolder(folder, path);
				} else {
					selectFolder(folders[0]);
				}
			},
			[
				folder?.id,
				selectWorkbook,
				updateWorkbook,
				workbooks,
				path,
				folders,
				selectFolder,
			],
		);

		const removeWorkbooks = useCallback(
			(deletedWorkbooks: SigmaWorkbook[]) => {
				const nextWorkbooks = workbooks.filter(
					(currentWorkbook) =>
						!deletedWorkbooks.some((x) => x.id === currentWorkbook.id),
				);
				setWorkbooks(nextWorkbooks);
				selectWorkbook(workbooks.filter((x) => x.folderId === folder?.id)[0]);
			},
			[folder?.id, selectWorkbook, workbooks],
		);

		const removeFolder = useCallback(
			(folder: SigmaFolder) => {
				const nextFolders = folders.filter((x) => x.id !== folder.id);
				setFolders(nextFolders);

				if (nextFolders.length > 0) {
					const nextFolder =
						path.length > 1
							? nextFolders.find((x) => x.id === path[path.length - 2]) ??
							  nextFolders[0]
							: nextFolders[0];
					selectFolder(nextFolder, getFolderPath(nextFolder.id, nextFolders));
				} else {
					setFolder(undefined);
				}
			},
			[folders, selectFolder, path, getFolderPath],
		);

		const updateFolder = useCallback(
			(folder: SigmaFolder) => {
				const nextFolders = folders.filter((x) => x.id !== folder.id);
				nextFolders.push(folder);
				setFolders(nextFolders);
				selectFolder(folder, getFolderPath(folder.id, nextFolders));
			},
			[folders, selectFolder, getFolderPath],
		);

		const updateFolderOrder = useCallback(
			(folder: SigmaFolder, order: number) => {
				const nextFolders = folders.filter((x) => x.id !== folder.id);
				folder.order = order;
				nextFolders.push(folder);
				setFolders(nextFolders);
			},
			[folders],
		);

		const updateWorkbookOrder = useCallback(
			(workbook: SigmaWorkbook, order: number) => {
				const nextWorkbooks = workbooks.filter((x) => x.id !== workbook.id);
				workbook.order = order;
				nextWorkbooks.push(workbook);
				setWorkbooks(nextWorkbooks);
			},
			[workbooks],
		);

		const getFolderItems = useCallback(
			async (parentId: string | null = null) => {
				if (!loadedOrders) {
					try {
						customerApiClient.sigmaEmbed
							.getFolderItems({
								parentId: parentId,
							})
							.then((response) => {
								const items = JSON.stringify(response.data);
								const itemsParsed: SigmaFolderItem[] = JSON.parse(items).data;

								itemsParsed.forEach((item: SigmaFolderItem) => {
									const folderItem =
										workbooks.find((x) => x.id === item.itemId) ||
										folders.find((x) => x.id === item.itemId);

									if (folderItem) {
										item.itemType === 'folder'
											? updateFolderOrder(folderItem as SigmaFolder, item.order)
											: updateWorkbookOrder(
													folderItem as SigmaWorkbook,
													item.order,
											  );
									}
								});
								setLoadedOrders(true);
							});
					} catch (error) {
						enqueueSnackbar({
							message: 'Failed to get folder items.',
							variant: 'error',
						});
					}
				}
			},
			[
				customerApiClient.sigmaEmbed,
				enqueueSnackbar,
				workbooks,
				folders,
				updateFolderOrder,
				updateWorkbookOrder,
				loadedOrders,
			],
		);

		const createFolderItems = useCallback(
			async (
				item: SigmaFolder | SigmaWorkbook,
				order: number,
				workbook: boolean,
			) => {
				try {
					customerApiClient.sigmaEmbed.createFolderItems({
						parentId: 'parentId' in item ? item.parentId : item.folderId,
						itemId: item.id,
						order: order,
						itemType: workbook ? 'workbook' : 'folder',
					});
				} catch (error) {
					enqueueSnackbar({
						message: 'Failed to create folder items.',
						variant: 'error',
					});
				}
			},
			[customerApiClient.sigmaEmbed, enqueueSnackbar],
		);

		const updateFolderItems = useCallback(
			async (
				item: SigmaFolder | SigmaWorkbook,
				order: number,
				workbook: boolean,
			) => {
				if ('folderId' in item) {
					updateWorkbookOrder(item, order);
				} else if ('parentId' in item) {
					updateFolderOrder(item, order);
				}

				try {
					customerApiClient.sigmaEmbed.updateFolderItems({
						parentId: 'folderId' in item ? item.folderId : item.parentId,
						itemId: item.id,
						order: order,
						itemType: workbook ? 'workbook' : 'folder',
					});
				} catch (error) {
					enqueueSnackbar({
						message: 'Failed to create folder items.',
						variant: 'error',
					});
				}
			},
			[
				customerApiClient.sigmaEmbed,
				enqueueSnackbar,
				updateFolderOrder,
				updateWorkbookOrder,
			],
		);

		const initialize = useCallback(async () => {
			try {
				if (!loadingData && !userId) {
					setLoadingData(true);
					const query = customerApiClient.sigmaEmbed
						.loadAllThingsSigma()
						.then((response) => {
							if (response.data) {
								if (response.data) {
									const { memberId, sigmaWorkbooks, solutionFolders } =
										response.data;
									setUserId(memberId);
									setWorkbooks(
										sigmaWorkbooks.sort((a, b) => a.name.localeCompare(b.name)),
									);
									const recentlyDeletedFolderSearch = solutionFolders.filter(
										(x) => x.name === 'Recently Deleted',
									);
									setFolders(
										solutionFolders.filter(
											(x) => x.id !== recentlyDeletedFolderSearch[0].id,
										),
									);
									setRecentlyDeletedFolder(recentlyDeletedFolderSearch[0]);

									setLoadingData(false);
								}
							}
						});
					await Promise.all([query]);
				}
			} catch (error) {
				enqueueSnackbar({
					message: 'Failed to initialize Sigma.',
					variant: 'error',
				});
			}
		}, [customerApiClient.sigmaEmbed, enqueueSnackbar, loadingData, userId]);

		useEffect(() => {
			if (initializing) {
				initialize();
			}
			if (initializing && folder) {
				setInitalizing(false);
			}
		}, [initializing, initialize, folder]);

		useEffect(() => {
			if (folders.length > 0 && workbooks.length > 0 && !loadedOrders) {
				getFolderItems();
			}
		}, [folders, workbooks, getFolderItems, loadedOrders]);

		useEffect(() => {
			if (folders.length > 0 && !folder && loadedOrders) {
				selectFolder(folders.filter((x) => x.name === solution)[0]);
			}
		}, [folders, selectFolder, folder, solution, loadedOrders]);

		return (
			<SigmaContext.Provider
				value={{
					initializing: initializing,
					sigmaUrl: sigmaUrl,
					recentlyDeletedFolder: recentlyDeletedFolder,
					recentlyDeletedWorkbooks: recentlyDeletedWorkbooks,
					workbooks: workbooks,
					workbook: workbook,
					folders: folders,
					folder: folder,
					generateSigmaUrl: generateSigmaUrl,
					addFolder: addFolder,
					updateFolder: updateFolder,
					removeFolder: removeFolder,
					selectFolder: selectFolder,
					addWorkbook: addWorkbook,
					deleteWorkbook: deleteWorkbook,
					updateWorkbook: updateWorkbook,
					updateWorkbooks: updateWorkbooks,
					removeWorkbooks: removeWorkbooks,
					selectWorkbook: selectWorkbook,
					path: path,
					loadingNewItem: loadingNewItem,
					createFolderItems: createFolderItems,
					getFolderItems: getFolderItems,
					updateFolderItems: updateFolderItems,
				}}
			>
				{children}
			</SigmaContext.Provider>
		);
	},
);

//#endregion

//#region Hook

export type UseSigma = SigmaContextProps;

export function useSigma(): UseSigma {
	return useContext(SigmaContext);
}

//#endregion
