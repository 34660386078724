import { Box, Typography, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { formatFileSize } from 'utilities/fileUtilities';
import { FileUploadValidation } from './fileUploadValidation';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';

export interface FileUploadBoxProps {
	validation: FileUploadValidation;
	onClear: () => void;
	file: File | undefined;
	setFile: (file: File | undefined) => void;
	onChange?: () => void;
	disabled?: boolean;
	fileSizeText?: string;
}

export const FileUploadBox: FC<FileUploadBoxProps> = observer(
	({
		validation,
		disabled,
		onChange,
		onClear,
		file,
		setFile,
		fileSizeText,
	}) => {
		const theme = useTheme();
		const isValidFile = (item: DataTransferItem | null | undefined): boolean =>
			item !== undefined &&
			item !== null &&
			validation.isFileTypeValid(item.type);

		return (
			<Box
				sx={{
					padding: 2,
					border: '3px dashed ' + theme.palette.grey[400],
					borderRadius: 1,
					textAlign: 'center',
				}}
				onDragOver={(event) => {
					event.preventDefault();

					if (isValidFile(event.dataTransfer.items[0])) {
						(event.currentTarget as HTMLElement).style.backgroundColor = '#ccc';
					} else {
						event.dataTransfer.dropEffect = 'none';
					}
				}}
				onDragEnter={(event) => {
					event.preventDefault();

					if (isValidFile(event.dataTransfer.items[0])) {
						(event.currentTarget as HTMLElement).style.backgroundColor = '#ccc';
					} else {
						event.dataTransfer.dropEffect = 'none';
					}
				}}
				onDragLeave={(event) => {
					event.preventDefault();
					(event.currentTarget as HTMLElement).style.backgroundColor =
						'transparent';
				}}
				onDrop={(event) => {
					event.preventDefault();
					(event.currentTarget as HTMLElement).style.backgroundColor =
						'transparent';

					setFile(event.dataTransfer.files[0]);
				}}
			>
				<Box
					sx={{
						height: '160px',
						display: 'flex',
						minWidth: '400px',
						justifyContent: 'center',
						flexDirection: 'column',
					}}
				>
					{file !== undefined ? (
						<Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
							<Box sx={{ fontWeight: 800, overflow: 'auto' }}>
								{file?.name}
								<HighlightOffOutlinedIcon
									onClick={() => onClear()}
									sx={{
										marginLeft: '5px',
										marginBottom: '-5px',
										cursor: 'pointer',
									}}
								/>
							</Box>
							<Box sx={{ color: '#aaa' }}>
								File size: {formatFileSize(file?.size)}
							</Box>
						</Box>
					) : (
						<>
							<Typography
								sx={{
									textAlign: 'center',
								}}
							>
								<CloudUploadOutlinedIcon
									style={{
										color: theme.palette.grey[500],
										fontSize: '64px',
									}}
								/>
							</Typography>
							<label
								htmlFor="file-upload"
								style={{ pointerEvents: 'none', fontSize: '.8em' }}
							>
								<h2
									style={{
										color: theme.palette.grey[600],
										fontWeight: 400,
										fontSize: '1.5em',
										marginTop: '0',
									}}
								>
									<u
										style={{
											cursor: disabled ? 'not-allowed' : 'pointer',
											color: theme.palette.primary.main,
											pointerEvents: 'auto',
										}}
									>
										Select a file to upload
									</u>{' '}
									or drag and drop
								</h2>
								<Box
									sx={{
										m: 1,
										color: theme.palette.grey[600],
										fontSize: '1.25em',
									}}
								>
									{fileSizeText !== undefined ? (
										fileSizeText
									) : (
										<>
											Maximum file size{' '}
											{validation.getMaxFileSizeInMB().toString()}
											MB
										</>
									)}
								</Box>
							</label>
							<input
								id="file-upload"
								type="file"
								disabled={disabled}
								style={{ display: 'none' }}
								onChange={(event) => {
									if (onChange !== undefined) onChange();
									setFile(event?.target?.files![0]);
								}}
								accept={validation.getValidContentTypes().toString()}
							/>
						</>
					)}
				</Box>
				{!!file && !validation.isFileSizeValid(file) ? (
					<T4Alert severity="warning">
						<Typography>{`File is too large to upload. The maximum file size is ${validation.getMaxFileSizeInMB()} MB.`}</Typography>
					</T4Alert>
				) : null}
			</Box>
		);
	},
);
