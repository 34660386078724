import { CurrencyExchange } from '@mui/icons-material';
import { Grid, MenuItem, Tooltip, useTheme } from '@mui/material';
import {
	GridCellParams,
	GridColDef,
	GridRowSelectionModel,
	GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { useCash4ConfigurationsQuery } from 'features/_shared/_hooks/useCash4ConfigurationsQuery';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { LegalEntityGroupsFilter } from 'features/entity4/shared/components/legalEntityGroupsFilter';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { observer } from 'mobx-react-lite';
import { enqueueSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ConfirmationDialog } from 'shared/components/confirmationDialog';
import { UserPreferencesDataGrid } from 'shared/components/dataGrid/userPreferencesDataGrid';
import T4DateRangePicker from 'shared/components/dateRangePicker/t4DateRangePicker';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { useUser } from 'shared/hooks/useUser';
import { useLegalEntityGroups } from 'shared/providers/legalEntityGroupsProvider';
import {
	getCurrencyColumnDefinition,
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
	getIntegerColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { stonlyData } from 'stonly/functions';
import {
	CurrencySource,
	defaultCurrencyCode,
	formatCurrency,
} from 'utilities/currencyUtils';
import { useAccountIntegrationsContext } from '../accountIntegrations/providers/accountIntegrationsProviders';
import T4Drawer from '../shared/components/T4SideDrawer/T4DrawerShell';
import { Balance, useBalancesViewModel } from './balancesViewModel';
import { BalancesDrawer } from './components/BalancesDrawer';
import { useBalance } from './services';
import { stonlyIds } from './utilites/stonlyIds';
import { useQuery } from '@tanstack/react-query';
import {
	Action,
	Actions,
	ActionsEnum,
} from '../shared/components/T4ActionMenu';
import { useRowSelectionCalculations } from '../_shared/_utilities/useRowSelectionCalculations';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { C4AlertBold } from '../reconciliations/_components/c4AlertBold';
import AmountCurrencyTooltipGridCell from '../_shared/_components/AmountCurrencyTooltipGridCell';

export const testIds = {
	priorDayOpeningBalances: {
		card: 'prior-day-opening-balances-card',
		title: 'prior-day-opening-balances-title',
		amount: 'prior-day-opening-balances-amount',
		date: 'prior-day-opening-balances-date',
		loadingSpinner: 'prior-day-opening-balacnes-spinner',
	},
	priorDayClosingBalances: {
		card: 'prior-day-closing-balances-card',
		title: 'prior-day-closing-balances-title',
		amount: 'prior-day-closing-balances-amount',
		date: 'prior-day-closing-balances-date',
		loadingSpinner: 'prior-day-opening-closing-spinner',
	},
};

export type BalancesPageProps = {};

export const BalancesPage: FC<BalancesPageProps> = observer(() => {
	const {
		cash4: { isAuthor },
	} = useUser();
	const { legalEntityGroupIds } = useLegalEntityGroups();
	const { data: configurations } = useCash4ConfigurationsQuery();
	const theme = useTheme();
	const {
		balanceStartDate,
		balanceEndDate,
		setBalanceStartDate,
		setBalanceEndDate,
	} = useSessionStorage();
	const viewModel = useBalancesViewModel(balanceStartDate, balanceEndDate);
	const history = useHistory();
	const {
		updateCurrentDay,
		getCurrentDayRefreshStatus,
		getCurrentDayRefreshDate,
	} = useAccountIntegrationsContext();

	const { balanceId } = useParams<{ balanceId: string }>();

	const [open, setOpen] = useState<boolean>(false);
	const [balanceToDelete, setBalanceToDelete] = useState<string>('');
	const [confirmationModalLoading, setConfirmationModalLoading] =
		useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [balance, setBalance] = useState<Balance | null>(null);

	// This hook takes care of fetching and setting state for getBalance
	const { isFetching: loadingBalance } = useBalance({
		balanceId,
		setBalance,
		balance,
	});

	const handleOpen = useCallback(() => {
		setOpen(true);
	}, []);

	const handleClose = useCallback(() => {
		setOpen(false);
		history.push(paths.cash4.balances.href);
	}, [history]);

	const handleOpenDialog = useCallback(() => {
		setOpenDialog(true);
	}, []);

	const handleCloseDialog = useCallback(() => {
		setOpenDialog(false);
	}, []);

	const handleViewClick = useCallback(
		(params: Balance) => {
			setBalance(params);
			history.push(`${paths.cash4.balances.href}/${params.id}`);
			handleOpen();
		},
		[handleOpen, history],
	);

	const handleRefetch = useCallback(() => {
		viewModel.refetch(legalEntityGroupIds);
	}, [legalEntityGroupIds, viewModel]);

	useEffect(() => {
		if (balanceId) {
			handleOpen();
		}
	}, [balanceId, handleOpen]);

	const deleteBalance = useCallback(
		async (balanceId: string) => {
			try {
				setConfirmationModalLoading(true);
				await viewModel.deleteBalance(balanceId);
				handleCloseDialog();
				enqueueSnackbar('Balance deleted successfully.', {
					variant: 'success',
				});
				setOpen(false);
			} catch (error) {
				enqueueSnackbar('Error deleting balance.', {
					variant: 'error',
				});
			} finally {
				setConfirmationModalLoading(false);
			}
		},
		[handleCloseDialog, viewModel],
	);

	const handleDeleteClick = useCallback(
		(params: Balance) => {
			setBalanceToDelete(params.id);
			handleOpenDialog();
		},
		[handleOpenDialog],
	);

	const updateCurrentDayButton = useMemo(
		() => (
			<T4Button
				variant="outlined"
				startIcon={<CurrencyExchange />}
				onClick={() => {
					updateCurrentDay();
				}}
			>
				Update
			</T4Button>
		),
		[updateCurrentDay],
	);

	const { data: isCurrentDayRefreshEnabled } = useQuery(
		['currentDayRefreshEnabled'],
		() => getCurrentDayRefreshStatus(),
		{
			refetchOnWindowFocus: false,
			initialData: false,
		},
	);

	const { data: lastRefreshedDate } = useQuery(
		['lastRefreshedDate'],
		() => getCurrentDayRefreshDate(),
		{
			refetchOnWindowFocus: false,
			initialData: '',
		},
	);

	const columns = useMemo(() => {
		let columns: GridColDef<Balance>[] = [];

		columns.push({
			field: 'actions',
			headerName: '',
			width: 25,
			renderCell: (params) => {
				return (
					<Actions
						objecttype="Balance"
						stonlyId={stonlyIds.balancesRowContextMenu}
						id="balancesId-more-menu"
						actions={[
							{
								action: ActionsEnum.view,
								callback: () => handleViewClick(params.row),
								stonlyId: stonlyIds.balancesRowContextMenuView,
							},
							...(isAuthor
								? [
										{
											action: ActionsEnum.delete,
											isDisabled: false,
											callback: () => handleDeleteClick(params.row),
											stonlyId: stonlyIds.balancesRowContextMenuDelete,
										},
								  ]
								: []),
						]}
					/>
				);
			},
			resizable: false,
			sortable: false,
			disableColumnMenu: true,
			filterable: false,
			disableExport: true,
			hideable: false,
			disableReorder: true,
		});

		columns.push(
			{
				...getDateColumnDefinition(),
				field: 'statementDate',
				headerName: 'Date',
				description: 'Date',
			},
			{
				field: 'e4AccountName',
				headerName: 'Account Name',
				description: 'Account Name',
			},
			{
				field: 'e4AccountNumber',
				headerName: 'Account Number',
				description: 'Account Number',
			},
			{
				field: 'c4AccountNumber',
				headerName: 'C4 Account Number',
				description: 'C4 Account Number',
			},
			{
				field: 'bankCode',
				headerName: 'Counterparty Code',
				description: 'Counterparty Code',
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'openingLedgerBalance',
				headerName: 'Opening Ledger Balance',
				description: 'Opening Ledger Balance',
				valueGetter: (params) => viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.openingLedgerBalance.value,
						accountCurrencyCode: params.row.openingLedgerBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.openingLedgerBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode:
							viewModel.getSelectedDisplayCurrency() === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'openingAvailableBalance',
				headerName: 'Opening Available Balance',
				description: 'Opening Available Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.openingAvailableBalance.value,
						accountCurrencyCode:
							params.row.openingAvailableBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.openingAvailableBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode:
							viewModel.getSelectedDisplayCurrency() === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'closingLedgerBalance',
				headerName: 'Closing Ledger Balance',
				description: 'Closing Ledger Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.closingLedgerBalance.value,
						accountCurrencyCode: params.row.closingLedgerBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.closingLedgerBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode:
							viewModel.getSelectedDisplayCurrency() === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'closingAvailableBalance',
				headerName: 'Closing Available Balance',
				description: 'Closing Available Balance',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					return AmountCurrencyTooltipGridCell({
						accountCurrencyAmount: params.row.closingAvailableBalance.value,
						accountCurrencyCode:
							params.row.closingAvailableBalance.valueCurrency,
						reportingCurrencyAmount:
							params.row.closingAvailableBalance.reportingValue,
						reportingCurrencyCode: configurations?.reportingCurrencyCode,
						displayedCurrencyCode:
							viewModel.getSelectedDisplayCurrency() === 'reporting'
								? CurrencySource.Reporting
								: CurrencySource.Account,
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance0Day',
				headerName: 'Forward Available Balance (0Day)',
				description: 'Forward Available Balance (0Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (viewModel.getSelectedDisplayCurrency() === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance1Day',
				headerName: 'Forward Available Balance (1Day)',
				description: 'Forward Available Balance (1Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (viewModel.getSelectedDisplayCurrency() === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance2Day',
				headerName: 'Forward Available Balance (2Day)',
				description: 'Forward Available Balance (2Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				cellClassName: (params: GridCellParams<Balance, number>) =>
					params.row.forwardAvailableBalance2Day &&
					params.row.forwardAvailableBalance2Day.value &&
					params.row.forwardAvailableBalance2Day.value < 0
						? 'negative-balance'
						: '',
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (viewModel.getSelectedDisplayCurrency() === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'forwardAvailableBalance3Day',
				headerName: 'Forward Available Balance (3Day)',
				description: 'Forward Available Balance (3Day)',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				cellClassName: (params: GridCellParams<Balance, number>) =>
					params.row.forwardAvailableBalance3Day &&
					params.row.forwardAvailableBalance3Day.value &&
					params.row.forwardAvailableBalance3Day.value < 0
						? 'negative-balance'
						: '',
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (viewModel.getSelectedDisplayCurrency() === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'totalDebitTransactions',
				headerName: 'Total Debit Transactions',
				description: 'Total Debit Transactions',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (viewModel.getSelectedDisplayCurrency() === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getCurrencyColumnDefinition(),
				field: 'totalCreditTransactions',
				headerName: 'Total Credit Transactions',
				description: 'Total Credit Transactions',
				valueGetter: (params: GridValueGetterParams<Balance, any>) =>
					viewModel.currencyValueGetter(params.value),
				renderCell: (params) => {
					let currencyCode: string | undefined =
						params.row.currency ?? undefined;
					if (viewModel.getSelectedDisplayCurrency() === 'reporting') {
						currencyCode = configurations?.reportingCurrencyCode;
					}

					return formatCurrency(params.value, {
						currency: currencyCode || 'USD',
					});
				},
			},
			{
				...getIntegerColumnDefinition(),
				field: 'transactionCount',
				headerName: 'Transaction Count',
				description: 'Transaction Count',
			},
			{
				...getIntegerColumnDefinition(),
				field: 'debitTransactionsCount',
				headerName: 'Debit Transaction Count',
				description: 'Debit Transaction Count',
			},
			{
				...getIntegerColumnDefinition(),
				field: 'creditTransactionsCount',
				headerName: 'Credit Transaction Count',
				description: 'Credit Transaction Count',
			},
			{
				field: 'currency',
				headerName: 'Currency',
				description: 'Currency',
				valueGetter: (params: { value: number | string }) => {
					if (viewModel.isReportingCurrency()) {
						return configurations?.reportingCurrencyCode || defaultCurrencyCode;
					} else {
						return params.value;
					}
				},
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'balanceImportedDate',
				headerName: 'Balance Imported Date',
				description: 'Balance Imported Date',
			},
			{
				...getFormattedDateTimeColumnDefinition(),
				field: 'lastUpdatedDate',
				headerName: 'Last Updated Date',
				description: 'Last Updated Date',
			},
			{
				field: 'note',
				headerName: 'Note',
				description: 'Note',
				width: 100,
			},
		);

		return columns;
	}, [
		configurations?.reportingCurrencyCode,
		handleDeleteClick,
		handleViewClick,
		isAuthor,
		viewModel,
	]);

	const getBalanceActions = useMemo((): Action[] | undefined => {
		if (isAuthor) {
			return [
				{
					action: ActionsEnum.delete,
					callback: () => {
						handleDeleteClick(balance!);
					},
				},
			];
		}
		return undefined;
	}, [balance, handleDeleteClick, isAuthor]);

	const closingAvailableBalanceSelection = useRowSelectionCalculations(
		viewModel.getRows().map((b) => ({
			id: b.id,
			accountAmount: b.closingAvailableBalance.value || 0,
			accountCurrencyCode:
				b.closingAvailableBalance.valueCurrency || defaultCurrencyCode,
			reportingAmount: b.closingAvailableBalance.reportingValue || undefined,
			reportingCurrencyCode:
				configurations?.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	const closingLedgerBalanceSelection = useRowSelectionCalculations(
		viewModel.getRows().map((b) => ({
			id: b.id,
			accountAmount: b.closingLedgerBalance.value || 0,
			accountCurrencyCode:
				b.closingLedgerBalance.valueCurrency || defaultCurrencyCode,
			reportingAmount: b.closingLedgerBalance.reportingValue || undefined,
			reportingCurrencyCode:
				configurations?.reportingCurrencyCode || defaultCurrencyCode,
		})) ?? [],
	);

	//do not add closingAvailableBalanceSelection or closingLedgerBalanceSelection as a dependency
	useEffect(() => {
		if (viewModel.isListLoading()) {
			closingLedgerBalanceSelection.setRowSelectionHandler([]);
			closingAvailableBalanceSelection.setRowSelectionHandler([]);
		}
	}, [viewModel.isListLoading()]);

	return (
		<T4View
			header={
				<PageHeader id={pageHeaderStonlyIds.balancesPage} title="Balances" />
			}
		>
			<Grid
				container
				direction="column"
				wrap="nowrap"
				sx={{ height: '100%', rowGap: 1 }}
			>
				<Grid container item rowGap={2} xs="auto">
					<Grid container item xs={12}>
						<Grid item xs={12}>
							<T4AlertStack error={viewModel.getCardError()} />
						</Grid>
					</Grid>
					<Grid item xs={true}>
						<Grid
							item
							container
							sx={{
								gap: 2,
								height: '100%',
								flexDirection: 'column',
								flexWrap: 'nowrap',
							}}
						>
							<Grid
								container
								item
								xs="auto"
								sx={{ justifyContent: 'space-between' }}
							>
								<Grid
									container
									item
									xs="auto"
									sx={{ gap: 2, alignItems: 'flex-end' }}
								>
									<Grid item xs="auto">
										<T4DateRangePicker
											id="balances-date-filter"
											defaultValue={[
												viewModel.getStartDate(),
												viewModel.getEndDate(),
											]}
											onAccept={([startDate, endDate]) => {
												if (
													startDate &&
													startDate.isValid() &&
													endDate &&
													endDate.isValid() &&
													startDate.isSameOrBefore(endDate, 'day')
												) {
													setBalanceStartDate(startDate);
													setBalanceEndDate(endDate);
													if (
														!startDate.isSame(viewModel.getStartDate()) ||
														!endDate.isSame(viewModel.getEndDate())
													) {
														viewModel.onDateRangeChange([startDate, endDate]);
														viewModel.load(legalEntityGroupIds);
													}
												}
											}}
											disableFuture
											showShortcuts
											shortcutResetDefaults={viewModel.getDefaultDateRange()}
										/>
									</Grid>
									<Grid item xs="auto">
										<T4TextFieldV2
											label="Display Currency"
											select
											value={viewModel.getSelectedDisplayCurrency()}
											onChange={(value) =>
												viewModel.onDisplayCurrencyChange(value)
											}
											{...stonlyData({
												id: stonlyIds.balancesDisplayCurrencyDropdown,
											})}
										>
											{[
												{ id: 'original', value: 'Account Currency' },
												{
													id: 'reporting',
													value: `Reporting Currency (${
														configurations?.reportingCurrencyCode ?? 'USD'
													})`,
												},
											].map((x) => {
												return (
													<MenuItem key={x.id} value={x.id}>
														{x.value}
													</MenuItem>
												);
											})}
										</T4TextFieldV2>
									</Grid>
									<Grid item xs="auto">
										<LegalEntityGroupsFilter
											loading={viewModel.isListLoading()}
										/>
									</Grid>
								</Grid>

								<Grid
									container
									item
									xs="auto"
									sx={{ gap: 2, alignItems: 'flex-end', display: 'inline' }}
								>
									{isAuthor && isCurrentDayRefreshEnabled && (
										<Tooltip title={lastRefreshedDate}>
											<Grid item xs="auto">
												{updateCurrentDayButton}
											</Grid>
										</Tooltip>
									)}
								</Grid>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<T4AlertStack
								errors={viewModel.getListErrors()}
								error={viewModel.getListError()}
							/>
						</Grid>
					</Grid>
				</Grid>
				<Grid
					item
					xs={true}
					sx={{
						height: '50vh',
						'& .negative-balance': {
							backgroundColor: theme.cash4?.negativeCell?.background,
							color: theme.cash4?.negativeCell?.text,
						},
					}}
				>
					<UserPreferencesDataGrid
						tableKey="balancesPage"
						columns={columns}
						stonlyId={stonlyIds.balancesTable}
						rows={viewModel.getRows()}
						loading={viewModel.isListLoading()}
						calculateColumnWidths
						pinnedColumns={{
							left: ['actions'],
						}}
						columnVisibilityModel={{
							c4AccountNumber: false,
							openingLedgerBalance: false,
							openingAvailableBalance: false,
							closingAvailableBalance: false,
							forwardAvailableBalance0Day: false,
							forwardAvailableBalance1Day: false,
							forwardAvailableBalance2Day: false,
							forwardAvailableBalance3Day: false,
							totalDebitTransactions: false,
							totalCreditTransactions: false,
							transactionCount: false,
							debitTransactionsCount: false,
							creditTransactionsCount: false,
							balanceImportedDate: false,
							lastUpdatedDate: false,
						}}
						initialState={{
							sorting: {
								sortModel: [
									{ field: 'statementDate', sort: 'desc' },
									{ field: 'e4AccountNumber', sort: 'desc' },
								],
							},
						}}
						showToolbar
						showCustomViewButton
						pagination
						hideFooter={false}
						autoPageSize
						onRowSelectionModelChange={(model: GridRowSelectionModel) => {
							closingAvailableBalanceSelection.setRowSelectionHandler(model);
							closingLedgerBalanceSelection.setRowSelectionHandler(model);
						}}
					/>
					<T4Drawer
						open={open}
						onClose={handleClose}
						stonlyPrefix="balance-details"
					>
						<BalancesDrawer
							loading={loadingBalance}
							balance={balance}
							reportingCurrencyCode={
								configurations?.reportingCurrencyCode || 'USD'
							}
							refetch={handleRefetch}
							balanceActions={getBalanceActions}
						/>
					</T4Drawer>
				</Grid>
				{closingAvailableBalanceSelection.isSelectedTotalValid &&
					closingLedgerBalanceSelection.isSelectedTotalValid &&
					closingAvailableBalanceSelection.selectedCount >= 2 && (
						<Grid item xs="auto">
							<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
								{'The '}
								<C4AlertBold>{`${
									closingAvailableBalanceSelection.selectedCurrencySource ===
									CurrencySource.Account
										? 'account currency'
										: 'reporting currency'
								}`}</C4AlertBold>
								{' totals for the selected rows are '}
								<C4AlertBold>
									{`${formatCurrency(
										closingAvailableBalanceSelection.selectedTotal,
										{
											currency:
												closingAvailableBalanceSelection.selectedCurrency,
										},
									)} ${closingAvailableBalanceSelection.selectedCurrency}`}
								</C4AlertBold>
								{' for closing available balance and '}
								<C4AlertBold>
									{`${formatCurrency(
										closingLedgerBalanceSelection.selectedTotal,
										{
											currency: closingLedgerBalanceSelection.selectedCurrency,
										},
									)} ${closingLedgerBalanceSelection.selectedCurrency}`}
								</C4AlertBold>
								{' for closing ledger balance.'}
							</T4Alert>
						</Grid>
					)}
				{(!closingAvailableBalanceSelection.isSelectedTotalValid ||
					!closingLedgerBalanceSelection.isSelectedTotalValid) && (
					<Grid item xs="auto">
						<T4Alert severity="info" fullWidth sx={{ width: '100%' }}>
							{'The '}
							<C4AlertBold>{`${
								closingAvailableBalanceSelection.selectedCurrencySource ===
								CurrencySource.Account
									? 'account currency'
									: 'reporting currency'
							}`}</C4AlertBold>
							{
								' total of the selected rows is unavailable due to missing exchange rates. Select records with the same account currency.'
							}
						</T4Alert>
					</Grid>
				)}
			</Grid>
			<ConfirmationDialog
				open={openDialog}
				onClose={handleCloseDialog}
				title={'Delete balance record?'}
				text={
					'This balance record will be permanently deleted from the system. This action cannot be undone.'
				}
				primaryButtonText={'DELETE'}
				onPrimaryButtonClick={() => deleteBalance(balanceToDelete)}
				secondaryButtonText={'CANCEL'}
				onSecondaryButtonClick={handleCloseDialog}
				stonlyIds={{
					confirmationModal: stonlyIds.balancesDeleteConfirmationModal,
					secondaryButton: stonlyIds.balancesDeleteConfirmationCancelButton,
					primaryButton: stonlyIds.balancesDeleteConfirmationDeleteButton,
				}}
				loading={confirmationModalLoading}
			/>
		</T4View>
	);
});
