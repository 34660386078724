import { Transaction } from 'modules/clients/customer-api/src/transactions';
import { formatDateSimpleUpperCase } from 'shared/utilities/dateUtilities';
import { defaultCurrencyCode, formatCurrency } from 'utilities/currencyUtils';
import { Balance } from '../../balances/balancesViewModel';
import { TransactionListItem } from '../models';
import { GridColDef } from '@mui/x-data-grid-pro';
import { ProjectedTransaction } from 'modules/clients/customer-api/src/api/cash4';
import { Tooltip, Typography } from '@mui/material';
import {
	getDateColumnDefinition,
	getFormattedDateTimeColumnDefinition,
} from 'shared/utilities/dataGrid/columnDefinitions';
import { DataGridColumnWidths } from 'shared/utilities/dataGrid/dataGridUtils';

export const formattedExchangeRate = (number: number | null | undefined) => {
	if (number === null) return null;
	if (number === undefined) return null;
	return number.toFixed(8);
};

export const indentSubsequentLines = (
	indent: string,
	content: string | undefined,
): string => {
	if (!content) return '';
	return content?.replace(/^(?!\s*$)/gm, (match, index) => {
		return `${index > 0 ? indent : ''}${match}`;
	});
};

export const replaceEmpty = (content: string | null): string => {
	if (!content || content.trim() === '') {
		return '-';
	} else {
		return content;
	}
};

export const getTransactionCopyContentValue = (
	transaction: TransactionListItem,
	reportingCurrency: string | null,
) => {
	return (
		`Reported Transaction Details\n\n` +
		`Account Name:               ${replaceEmpty(transaction.e4AccountName)}\n` +
		`Counterparty Code:          ${replaceEmpty(transaction.bankCode)}\n\n` +
		`Account:                    ${replaceEmpty(
			transaction.e4AccountNumber,
		)}\n` +
		`Cash4 Account:              ${replaceEmpty(
			transaction.c4AccountNumber,
		)}\n` +
		`Counterparty:               ${replaceEmpty(transaction.bankName)}\n` +
		`Transaction Date:           ${replaceEmpty(
			formatDateSimpleUpperCase(transaction.date),
		)}\n\n` +
		`Transaction Code:           ${replaceEmpty(
			transaction.transactionCode,
		)}\n` +
		`Customer Reference:         ${replaceEmpty(
			transaction.customerReference,
		)}\n` +
		`Bank Reference:             ${replaceEmpty(transaction.bankReference)}\n` +
		`Check Number:               ${replaceEmpty(transaction.checkNumber)}\n` +
		`Transaction Detail:         ${replaceEmpty(
			indentSubsequentLines('                      ', transaction.detail),
		)}\n\n` +
		`Amount:                     ${
			transaction.number.accountCurrencyAmount !== undefined
				? `${formatCurrency(transaction.number.accountCurrencyAmount, {
						currency: transaction.currency!,
				  })} ${transaction.currency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:       ${
			transaction.number.reportingCurrencyAmount !== undefined
				? `${formatCurrency(transaction.number.reportingCurrencyAmount, {
						currency: reportingCurrency!,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Rate: ${
			transaction.number.reportingCurrencyRate !== undefined
				? `${formattedExchangeRate(transaction.number.reportingCurrencyRate)}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Date: ${
			transaction.number.reportingCurrencyEffectiveDate !== undefined
				? `${formatDateSimpleUpperCase(
						transaction.number.reportingCurrencyEffectiveDate,
				  )}`
				: `-`
		}\n\n` +
		`Notes:\n${replaceEmpty(transaction.noteContent)}`
	);
};

export const getBalanceCopyContentValue = (
	balance: Balance,
	reportingCurrency: string,
) => {
	if (!balance) {
		return '';
	}

	return (
		`Balance Record\n\n` +
		`Account Code:                 ${replaceEmpty(balance.e4AccountName)}\n` +
		`Counterparty Code:            ${replaceEmpty(balance.bankCode)}\n\n` +
		`Account:                      ${replaceEmpty(balance.e4AccountNumber)}\n` +
		`Cash4 Account:                ${replaceEmpty(balance.c4AccountNumber)}\n` +
		`Counterparty:                 ${replaceEmpty(balance.bankName)}\n` +
		`Balance Date:                 ${replaceEmpty(
			formatDateSimpleUpperCase(balance.statementDate),
		)}\n` +
		'\n' +
		`Opening Ledger Balance:       ${
			balance.openingLedgerBalance.value !== undefined
				? `${formatCurrency(balance.openingLedgerBalance.value, {
						currency: balance.openingLedgerBalance.valueCurrency!,
				  })} ${balance.openingLedgerBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.openingLedgerBalance.reportingValue !== undefined
				? `${formatCurrency(balance.openingLedgerBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Opening Available Balance:    ${
			balance.openingAvailableBalance.value !== undefined
				? `${formatCurrency(balance.openingAvailableBalance.value, {
						currency: balance.openingAvailableBalance.valueCurrency!,
				  })} ${balance.openingAvailableBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.openingAvailableBalance.reportingValue !== undefined
				? `${formatCurrency(balance.openingAvailableBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Closing Ledger Balance:       ${
			balance.closingLedgerBalance.value !== undefined
				? `${formatCurrency(balance.closingLedgerBalance.value, {
						currency: balance.closingLedgerBalance.valueCurrency!,
				  })} ${balance.closingLedgerBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.closingLedgerBalance.reportingValue !== undefined
				? `${formatCurrency(balance.closingLedgerBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Closing Available Balance:    ${
			balance.closingAvailableBalance.value !== undefined
				? `${formatCurrency(balance.closingAvailableBalance.value, {
						currency: balance.closingAvailableBalance.valueCurrency!,
				  })} ${balance.closingAvailableBalance.valueCurrency}`
				: `-`
		}\n` +
		`Reporting Ccy Amount:         ${
			balance.closingAvailableBalance.reportingValue !== undefined
				? `${formatCurrency(balance.closingAvailableBalance.reportingValue, {
						currency: reportingCurrency,
				  })} ${reportingCurrency}`
				: `-`
		}\n` +
		'\n' +
		`Foreign Exchange (FX) Rate:   ${
			balance.foreignExchangeRate
				? `${formattedExchangeRate(balance.foreignExchangeRate)}`
				: `-`
		}\n` +
		`Foreign Exchange (FX) Date:   ${
			balance.foreignExchangeDate
				? `${formatDateSimpleUpperCase(balance.foreignExchangeDate)}`
				: `-`
		}\n` +
		'\n' +
		`Notes:\n${replaceEmpty(balance.note)}`
	);
};

export const getTransactionListItem = (
	transaction: Transaction,
): TransactionListItem => ({
	id: transaction.id,
	date: transaction.date,
	c4AccountNumber: transaction.c4AccountNumber,
	e4AccountNumber: transaction.e4AccountNumber,
	e4AccountName: transaction.e4AccountName,
	e4AccountId: transaction.e4AccountId,
	transactionCode: transaction.transactionCode,
	transactionCodeDescription: transaction.transactionCodeDescription,
	bankReference: transaction.bankReference,
	customerReference: transaction.customerReference,
	checkNumber: transaction.checkNumber,
	detail: transaction.detail,
	cfc: transaction.cfc,
	cfcName: transaction.cfcName,
	cft: transaction.cft,
	cftName: transaction.cftName,
	cfst: transaction.cfst,
	cfstName: transaction.cfstName,
	glNumber: transaction.glNumber,
	number: {
		accountCurrencyAmount: transaction.number,
		accountCurrencyCode: transaction.currency,
		reportingCurrencyAmount: transaction.number_ReportingCurrency,
		reportingCurrencyCode: defaultCurrencyCode,
		reportingCurrencyRate: transaction.reportingCurrency_Rate,
		reportingCurrencyEffectiveDate: transaction.reportingCurrency_EffectiveDate,
	},
	noteContent: transaction.noteContent,
	currency: transaction.currency,
	bankName: transaction.bankName,
	bankCode: transaction.bankCode,
	debitCredit: transaction.debitCredit,
	valueDate: transaction.valueDate,
	transactionDateTime: transaction.transactionDateTime,
	lastUpdatedDate: transaction.lastUpdatedDate,
	transactionImportedDate: transaction.transactionImportedDate,
	transactionRuleId: transaction.transactionRuleId,
	transactionRuleName: transaction.transactionRuleName,
	bankTransactionId: transaction.fI_TransactionId,
	reconciliationRecordId: transaction.reconciliationRecordId,
	reconciliationStatus: transaction.reconciliationStatus,
	isForecastModelExcluded: transaction.isForecastModelExcluded,
	reconciliationSummary: transaction.reconciliationSummary,
});

export function normalizeDebitCredit(indicator: string): string {
	return indicator.toLowerCase() === 'debit'
		? 'Debit'
		: indicator.toLowerCase() === 'credit'
		? 'Credit'
		: indicator;
}

export const stonlyIds = {
	// Reported Transactions
	transactionsDrawer: 'cash4-transactions-drawer',
	transactionsRowContextMenu: 'cash4-transactions-row-context-menu',
	transactionsRowContextMenuView: 'cash4-transactions-row-context-menu-view',
	transactionsRowContextMenuDelete:
		'cash4-transactions-row-context-menu-delete',
	transactionsDeleteConfirmationModal:
		'cash4-transactions-delete-confirmation-modal',
	transactionsDeleteConfirmationCancelButton:
		'cash4-transactions-delete-confirmation-cancel',
	transactionsDeleteConfirmationDeleteButton:
		'cash4-transactions-delete-confirmation-delete',

	// Projected Transactions
	projectedTransactionsGrid: 'cash4-projected-transactions-grid',
	projectedTransactionsDrawer: 'cash4-projected-transactions-drawer',
	projectedTransactionsRowContextMenu:
		'cash4-projected-transactions-row-context-menu',
	projectedTransactionsRowContextMenuView:
		'cash4-projected-transactions-row-context-menu-view',
	projectedTransactionsRowContextMenuDelete:
		'cash4-projected-transactions-row-context-menu-delete',
	projectedTransactionsDeleteConfirmationModal:
		'cash4-projected-transactions-delete-confirmation-modal',
	projectedTransactionsDeleteConfirmationCancelButton:
		'cash4-projected-transactions-delete-confirmation-cancel',
	projectedTransactionsDeleteConfirmationDeleteButton:
		'cash4-projected-transactions-delete-confirmation-delete',
	projectedTransactionsCreateButton:
		'cash4-transactions-create-projected-item-button',
};

export const projectedTransactionsColumnVisibility = {
	isForecastModelExcluded: false,
	_cfcName: false,
	_cftName: false,
	_cfstName: false,
};

export const reportedTransactionColumnVisibility = {
	c4AccountNumber: false,
	bankName: false,
	bankCode: false,
	bankReference: false,
	checkNumber: false,
	glNumber: false,
	customerReference: false,
	transactionImportedDate: false,
	transactionRuleName: false,
	fiTransactionId: false,
	noteContent: false,
	cfcName: false,
	cftName: false,
	cfstName: false,
	isForecastModelExcluded: false,
	debitCredit: false,
	valueDate: false,
	transactionDateTime: false,
	lastUpdatedDate: false,
	transactionCodeDescription: false,
};

export function getProjectedTransactionsColumnDefs(
	isAuthorRole: boolean,
	amountColumn: GridColDef<ProjectedTransaction>,
	currencyColumn: GridColDef<ProjectedTransaction>,
	actionColumn?: GridColDef<ProjectedTransaction>,
	reconciliationStatusColumn?: GridColDef<ProjectedTransaction>,
): GridColDef<ProjectedTransaction>[] {
	const columns: GridColDef<ProjectedTransaction>[] = [];

	if (actionColumn) {
		columns.push(actionColumn);
	}

	columns.push({
		field: 'label',
		headerName: 'Label',
	});

	if (reconciliationStatusColumn) {
		columns.push(reconciliationStatusColumn);
	}

	columns.push(
		{
			field: 'description',
			headerName: 'Notes',
		},
		{
			field: 'flowDirection',
			headerName: 'Fund Direction',
		},
		amountColumn,
		currencyColumn,
		{
			...getDateColumnDefinition(),
			field: 'expectedValueDate',
			headerName: 'Expected Value Date',
		},
		{
			field: '_cfc',
			headerName: 'CFC',
			description: 'Cash Flow Class',
			width: DataGridColumnWidths.threeChar,
			valueGetter: (params) => params.row?.categorization?.class?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.class?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cfcName',
			headerName: 'CFC Description',
			description: 'Cash Flow Class',
			valueGetter: (params) => params.row?.categorization?.class?.name,
		},
		{
			field: '_cft',
			headerName: 'CFT',
			description: 'Cash Flow Type',
			width: DataGridColumnWidths.threeChar,
			valueGetter: (params) => params.row?.categorization?.type?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.type?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cftName',
			headerName: 'CFT Description',
			description: 'Cash Flow Type',
			valueGetter: (params) => params.row?.categorization?.type?.name,
		},
		{
			field: '_cfst',
			headerName: 'CFST',
			description: 'Cash Flow Subtype',
			width: DataGridColumnWidths.fourChar,
			valueGetter: (params) => params.row?.categorization?.subtype?.code,
			renderCell: (params) => {
				return (
					<Tooltip title={params.row.categorization?.subtype?.name}>
						<Typography variant="body2" noWrap>
							{params.value}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: '_cfstName',
			headerName: 'CFST Description',
			description: 'Cash Flow Subtype',
			valueGetter: (params) => params.row?.categorization?.subtype?.name,
		},
		{
			field: '_glCode',
			headerName: 'GL Code',
			valueGetter: (params) => params.row?.categorization?.glCode?.code,
		},
		{
			field: '_primaryParty_name',
			headerName: 'Entity',
			valueGetter: (params) => params.row.primaryParty.object?.name,
		},
		{
			field: '_primaryParty__account_name',
			headerName: 'Account',
			valueGetter: (params) => params.row.primaryParty.account?.name,
		},
		{
			field: '_primaryParty__account_number',
			headerName: 'Account Number',
			valueGetter: (params) => params.row.primaryParty.account?.number,
		},
		{
			field: '_secondaryParty_type',
			headerName: 'Type',
			valueGetter: (params) => params.row.secondaryParty?.type,
		},
		{
			field: '_secondaryParty_name',
			headerName: 'Secondary Entity',
			valueGetter: (params) => params.row.secondaryParty?.object?.name,
		},
		{
			field: '_secondaryParty__account_name',
			headerName: 'Secondary Account',
			valueGetter: (params) => params.row.secondaryParty?.account?.name,
		},
		{
			field: '_secondaryParty__account_number',
			headerName: 'Secondary Account Number',
			valueGetter: (params) => params.row.secondaryParty?.account?.number,
		},
		{
			field: '_counterpartyCode',
			headerName: 'Counterparty Code',
			valueGetter: (params) => params.row.bankCode,
		},
		{
			field: '_counterpartyName',
			headerName: 'Counterparty Name',
			valueGetter: (params) => params.row.bankName,
		},
	);

	if (isAuthorRole) {
		columns.push({
			field: 'isForecastModelExcluded',
			headerName: 'Forecast Reports',
			valueGetter: (params) => params.row.isForecastModelExcluded,
			valueFormatter: (params) => (params.value ? 'Excluded' : 'Included'),
		});
	}

	return columns;
};

export function getReportedTransactionsColumnDefs(
	isAuthorRole: boolean,
	amountColumn: GridColDef<TransactionListItem>,
	currencyColumn: GridColDef<TransactionListItem>,
	actionColumn?: GridColDef<TransactionListItem>,
	reconciliationStatusColumn?: GridColDef<TransactionListItem>,
): GridColDef<TransactionListItem>[] {
	const columns: GridColDef<TransactionListItem>[] = [];

	if (actionColumn) {
		columns.push(actionColumn);
	}

	columns.push(
		{
			...getDateColumnDefinition(),
			field: 'date',
			headerName: 'Post Date',
		},
		{
			field: 'e4AccountName',
			headerName: 'Account Name',
			headerClassName: 'wrap-column-header',
		},
		{
			field: 'e4AccountNumber',
			headerName: 'Account Number',
		},
		{
			field: 'c4AccountNumber',
			headerName: 'C4 Account Number',
		},
		{
			field: 'transactionCode',
			headerName: 'Bank Transaction Code',
		},
		{
			field: 'transactionCodeDescription',
			headerName: 'Transaction Code Description',
		},
		{
			field: 'bankReference',
			headerName: 'Bank Reference',
		},
		{
			field: 'customerReference',
			headerName: 'Customer Reference',
		},
		{
			field: 'checkNumber',
			headerName: 'Check Number',
		},
		{
			field: 'detail',
			headerName: 'Transaction Details',
		},
		{
			field: 'noteContent',
			headerName: 'Notes',
		},
		{
			field: 'bankTransactionId',
			headerName: 'Bank Transaction Id',
		},
		{
			field: 'transactionRuleName',
			headerName: 'Rule Name',
			valueGetter: (params) => {
				return params.row.id === params.row.transactionRuleName
					? 'Manual Categorization'
					: params.row.transactionRuleName || 'No Rule Matched';
			},
			valueFormatter: (params) => params.value,
		},
		{
			field: 'cfc',
			headerName: 'CFC',
			description: 'Cash Flow Class',
			width: DataGridColumnWidths.threeChar,
			renderCell: (value) => {
				return (
					<Tooltip title={value.row.cfcName}>
						<Typography variant="body2" noWrap>
							{value.row.cfc}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cfcName',
			headerName: 'CFC Description',
			description: 'Cash Flow Class',
		},
		{
			field: 'cft',
			headerName: 'CFT',
			description: 'Cash Flow Type',
			width: DataGridColumnWidths.threeChar,
			renderCell: (value) => {
				return (
					<Tooltip title={value.row.cftName}>
						<Typography variant="body2" noWrap>
							{value.row.cft}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cftName',
			headerName: 'CFT Description',
			description: 'Cash Flow Type',
		},
		{
			field: 'cfst',
			headerName: 'CFST',
			description: 'Cash Flow Subtype',
			width: DataGridColumnWidths.fourChar,
			renderCell: (value) => {
				return (
					<Tooltip title={value.row.cfstName}>
						<Typography variant="body2" noWrap>
							{value.row.cfst}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: 'cfstName',
			headerName: 'CFST Description',
			description: 'Cash Flow Subtype',
		},
		{
			field: 'glNumber',
			headerName: 'GL Code',
		},
		amountColumn,
		currencyColumn,
	);

	if (reconciliationStatusColumn) {
		columns.push(reconciliationStatusColumn);
	}

	columns.push(
		{
			field: 'bankName',
			headerName: 'Counterparty Name',
		},
		{
			field: 'bankCode',
			headerName: 'Counterparty Code',
		},
		{
			...getFormattedDateTimeColumnDefinition(),
			field: 'transactionImportedDate',
			headerName: 'Imported Date',
		},
		{
			...getDateColumnDefinition(),
			field: 'valueDate',
			headerName: 'Value Date',
		},
		{
			...getFormattedDateTimeColumnDefinition(),
			field: 'lastUpdatedDate',
			headerName: 'Last Updated Date',
		},
		{
			...getFormattedDateTimeColumnDefinition(),
			field: 'transactionDateTime',
			headerName: 'Transaction Date',
		},
		{
			field: 'debitCredit',
			headerName: 'Debit/Credit',
			valueGetter: (params) => normalizeDebitCredit(params.value),
		},
	);

	if (isAuthorRole) {
		columns.push({
			field: 'isForecastModelExcluded',
			headerName: 'Forecast Reports',
			valueGetter: (params) => params.row.isForecastModelExcluded,
			valueFormatter: (params) => (params.value ? 'Excluded' : 'Included'),
		});
	}

	return columns;
};
