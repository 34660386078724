import { yupResolver } from '@hookform/resolvers/yup';
import {
	Box,
	Collapse,
	Divider,
	Grid,
	Tooltip,
	Typography,
} from '@mui/material';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { Account } from 'modules/clients/apiGateway/entity4/accounts';
import { Counterparty } from 'modules/clients/apiGateway/entity4/counterparties';
import { LegalEntity } from 'modules/clients/apiGateway/entity4/legalentities';
import { Partner } from 'modules/clients/apiGateway/entity4/partners';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	PaymentTemplate,
	UpdatePaymentTemplateRequest,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { T4DataResponse2, T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { CancellationModal } from 'shared/components/CancellationModal';
import { useClients } from 'shared/hooks/useClients';
import { stonlyData } from 'stonly/functions';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { trimStringsInObject } from 'utilities/objectUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { PaymentBankAndAccountInformationBox } from '../paymentPartyInformationBox';
import {
	TemplatePartyAlert,
	TemplatePartyErrorTooltip,
} from './templatePartyErrorComponents';
import {
	defaultEditPaymentTemplateForm,
	EditPaymentTemplateForm,
	useGetEditPaymentTemplateForm,
} from './useGetEditPaymentTemplateForm';
import { usePaymentTemplateFormDataFetchers } from './usePaymentTemplateFormDataFetchers';
import {
	CounterpartyWithAccounts,
	diffAccount,
	diffCounterparty,
	diffEntity,
	diffPartner,
	groupAccountsByCounterparties,
	isCounterpartyWirePaymentEnabled,
	PartyValueChange,
	PayeeTypes,
} from './utilities';
import {
	AccountValidator,
	CounterpartyValidator,
	CounterpartyWithAccountsValidator,
	EditPaymentTemplateFormValidator,
	InitiatorAccountValidator,
	LegalEntityValidator,
	PartnerValidator,
	PayeeAccountValidator,
	TemplateValidatorOptions,
} from './validators';

const stonlyIds = {
	submitButton: 'edit-payment-template-submit-button',
	cancelButton: 'edit-payment-template-cancel-button',
	detailsSection: 'edit-payment-template-details-section',
	initiatorSection: 'edit-payment-template-initiator-section',
	payeeSection: 'edit-payment-template-payee-section',
	currencyAndReferenceDataSection:
		'edit-payment-template-currency-referencedata-section',
};

type EditTemplateDrawerProps = {
	templateId: string | null;
	onClose: () => void;
	refetchTemplatesList: () => Promise<
		QueryObserverResult<PaymentTemplate[], Error>
	>;
};

export const EditTemplateDrawer: FC<EditTemplateDrawerProps> = ({
	templateId,
	onClose,
	refetchTemplatesList,
}) => {
	const { applicationApiClient } = useClients();
	const { getObjectsAsync, getValidAccountsOwnedByObjectAsync } =
		usePaymentTemplateFormDataFetchers();
	const { enqueueSnackbar } = useSnackbar();

	const [isEditLoading, setIsEditLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const { handleSubmit, control, reset, formState, watch, setValue } =
		useForm<EditPaymentTemplateForm>({
			defaultValues: defaultEditPaymentTemplateForm,
			resolver: yupResolver(EditPaymentTemplateFormValidator),
		});

	const selectedInitiator = watch('initiator');
	const selectedInitiatorBank = watch('initiatorBank');
	const selectedInitiatorAccount = watch('initiatorAccount');
	const payeeType = watch('payeeType');
	const selectedPayee = watch('payee');
	const selectedPayeeBank = watch('payeeBank');
	const selectedPayeeAccount = watch('payeeAccount');

	const [isCancellationModalOpen, setIsCancellationModalOpen] =
		useState<boolean>(false);

	const resetDrawer = useCallback(() => {
		setIsEditLoading(false);
		setErrors([]);
		reset(defaultEditPaymentTemplateForm);
	}, [reset]);

	// #region Fetching Data

	const {
		isLoading: isTemplateLoading,
		error: templateLoadingError,
		template,
		originalTemplate,
	} = useGetEditPaymentTemplateForm(templateId);
	useEffect(() => {
		if (!!templateLoadingError) {
			onClose();
			resetDrawer();
			enqueueSnackbar(templateLoadingError.message, {
				variant: 'error',
			});
		}
	}, [templateLoadingError, resetDrawer, onClose, enqueueSnackbar]);
	useEffect(() => {
		if (template) reset(template);
	}, [reset, template]);

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useQuery<FinancialInstitution[], Error>(
		['payees', 'FinancialInstitution'],
		async () => {
			const response =
				await applicationApiClient.financialInstitutions.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<FinancialInstitution[]>).data;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled: !!template,
		},
	);
	useEffect(() => {
		if (loadingFinancialInstitutionsError) {
			onClose();
			resetDrawer();
			enqueueSnackbar(
				'Unable to load financial institutions to validate template. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [
		loadingFinancialInstitutionsError,
		resetDrawer,
		onClose,
		enqueueSnackbar,
	]);

	const {
		isLoading: areInitiatorsLoading,
		isFetching: areInitiatorsFetching,
		data: initiators,
		error: loadingInitiatorsError,
	} = useQuery<LegalEntity[], Error>(
		['Entity'],
		async () => await getObjectsAsync('Entity'),
		{
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
			enabled: !!template,
		},
	);
	useEffect(() => {
		if (loadingInitiatorsError) {
			enqueueSnackbar(
				'Unable to load payment initiators. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [loadingInitiatorsError, enqueueSnackbar]);

	const {
		isLoading: arePayeesLoading,
		isFetching: arePayeesFetching,
		data: payees,
		error: loadingPayeesError,
	} = useQuery<LegalEntity[] | Partner[] | Counterparty[], Error>(
		[payeeType],
		async () => await getObjectsAsync(payeeType),
		{
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
			enabled: !!template,
		},
	);
	useEffect(() => {
		if (loadingPayeesError) {
			enqueueSnackbar(
				'Unable to load payment payees. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [loadingPayeesError, enqueueSnackbar]);

	const {
		isFetching: areInitiatorCounterpartiesFetching,
		data: initiatorCounterparties,
		error: loadingInitiatorCounterpartiesError,
	} = useQuery<CounterpartyWithAccounts[], Error>(
		['initiatorCounterparties', selectedInitiator?.id],
		async () => {
			const response = await getValidAccountsOwnedByObjectAsync(
				selectedInitiator!,
			);
			return groupAccountsByCounterparties(response);
		},
		{
			enabled: !!template && selectedInitiator !== null,
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
		},
	);
	useEffect(() => {
		if (loadingInitiatorCounterpartiesError) {
			enqueueSnackbar(
				`Unable to load bank information for ${
					selectedInitiator?.anglicizedLegalName ??
					selectedInitiator?.code ??
					'Initiator'
				}. Please try again later.`,
				{
					variant: 'error',
				},
			);
		}
	}, [loadingInitiatorCounterpartiesError, selectedInitiator, enqueueSnackbar]);

	const {
		isFetching: arePayeeCounterpartiesFetching,
		data: payeeCounterparties,
		error: loadingPayeeCounterpartiesError,
	} = useQuery<CounterpartyWithAccounts[], Error>(
		['payeeCounterparties', selectedPayee?.id],
		async () => {
			const response = await getValidAccountsOwnedByObjectAsync(selectedPayee!);
			return groupAccountsByCounterparties(response);
		},
		{
			enabled: !!template && selectedPayee !== null,
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
		},
	);
	useEffect(() => {
		if (loadingPayeeCounterpartiesError) {
			enqueueSnackbar(
				`Unable to load bank information for ${
					selectedPayee?.displayName ?? 'Payee'
				}. Please try again later.`,
				{
					variant: 'error',
				},
			);
		}
	}, [loadingPayeeCounterpartiesError, selectedPayee, enqueueSnackbar]);

	// #endregion

	// #region Submit Handler

	const onSubmit = useCallback(
		async (data: EditPaymentTemplateForm) => {
			if (templateId === null || originalTemplate?.hasDraftVersion) return;
			try {
				setErrors([]);
				setIsEditLoading(true);

				const convertToEntityTypeId = (type: PayeeTypes) => {
					if (type === 'Entity') return EntityTypeId.InternalEntity;
					else if (type === 'Partner') return EntityTypeId.Partner;
					else return EntityTypeId.Counterparty;
				};

				const request = {
					name: data.name,
					initiator: {
						entityId: data.initiator!.id,
						partyType: EntityTypeId.InternalEntity,
						name: data.initiator!.anglicizedLegalName,
						displayName: data.initiator!.displayName,
						countryCode: data.initiator!.operatingAddress!.countryCode,
						accountId: data.initiatorAccount!.id,
						accountNumber: data.initiatorAccount!.accountNumbers.pain001,
						accountName: isStringUndefinedOrNullOrWhitespace(
							data.initiatorAccount!.shortName,
						)
							? null
							: data.initiatorAccount!.shortName,
						accountDisplayName: data.initiatorAccount!.displayName,
						bank: {
							entityId: data.initiatorBank!.id,
							name: data.initiatorBank!.anglicizedLegalName,
							displayName: data.initiatorBank!.displayName,
							bankCode: data.initiatorBank!.bankCode,
							countryCode: data.initiatorBank!.operatingAddress!.countryCode,
							aba: data.initiatorBank!.routingCodes.highValueClearing,
							bic: null,
						},
					},
					payee: {
						entityId: data.payee!.id,
						partyType: convertToEntityTypeId(data.payeeType),
						name: data.payee!.anglicizedLegalName,
						displayName: data.payee!.displayName,
						countryCode: data.payee!.operatingAddress!.countryCode,
						accountId: data.payeeAccount!.id,
						accountNumber:
							data.payeeAccount!.accountNumbers.naturalAccountNumber,
						accountName: isStringUndefinedOrNullOrWhitespace(
							data.payeeAccount!.shortName,
						)
							? null
							: data.payeeAccount!.shortName,
						accountDisplayName: data.payeeAccount!.displayName,
						bank: {
							entityId: data.payeeBank!.id,
							name: data.payeeBank!.anglicizedLegalName,
							displayName: data.payeeBank!.displayName,
							bankCode: data.payeeBank!.bankCode,
							countryCode: data.payeeBank!.operatingAddress?.countryCode,
							aba: data.payeeBank!.routingCodes.highValueClearing,
							bic: null,
						},
					},
					referenceData: data.referenceData,
				} as UpdatePaymentTemplateRequest;

				const response =
					await applicationApiClient.payments4.paymentTemplates.update({
						id: templateId,
						data: trimStringsInObject(request) as UpdatePaymentTemplateRequest,
					});
				if (response.status === 200 && response.data) {
					onClose();
					resetDrawer();
					refetchTemplatesList();
					enqueueSnackbar('Payment template updated successfully.', {
						variant: 'success',
					});
				} else if (response.status === 400 && response.data) {
					setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
					errorsRef?.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				} else throw new Error();
			} catch {
				enqueueSnackbar(
					'An unexpected error occurred and we were unable to update the payment template. Please try again later.',
					{
						variant: 'error',
					},
				);
			} finally {
				setIsEditLoading(false);
			}
		},
		[
			templateId,
			originalTemplate,
			applicationApiClient,
			errorsRef,
			onClose,
			resetDrawer,
			refetchTemplatesList,
			enqueueSnackbar,
		],
	);

	// #endregion

	// #region Memoized Values

	const hasOutOfDateData = useMemo(() => {
		let diffs: PartyValueChange[] = [];
		if (
			!!originalTemplate &&
			!!selectedInitiator &&
			!!selectedInitiatorBank &&
			!!selectedInitiatorAccount &&
			!!selectedPayee &&
			!!selectedPayeeBank &&
			!!selectedPayeeAccount
		) {
			if (originalTemplate.initiator.entityId === selectedInitiator.id)
				diffs.push(
					...diffEntity(originalTemplate.initiator, selectedInitiator),
				);

			if (originalTemplate.initiator.bank.entityId === selectedInitiatorBank.id)
				diffs.push(
					...diffCounterparty(
						originalTemplate.initiator.bank,
						selectedInitiatorBank,
					),
				);

			if (originalTemplate.initiator.accountId === selectedInitiatorAccount.id)
				diffs.push(
					...diffAccount(
						'Initiator',
						originalTemplate.initiator,
						selectedInitiatorAccount,
					),
				);

			if (originalTemplate.payee.entityId === selectedPayee.id) {
				if (payeeType === 'Entity')
					diffs.push(...diffEntity(originalTemplate.payee, selectedPayee));
				else if (payeeType === 'Partner')
					diffs.push(...diffPartner(originalTemplate.payee, selectedPayee));
				// if counterparty
				else
					diffs.push(
						...diffCounterparty(
							originalTemplate.payee.bank,
							selectedPayee as Counterparty,
						),
					);
			}

			if (originalTemplate.payee.bank.entityId === selectedPayeeBank.id)
				diffs.push(
					...diffCounterparty(originalTemplate.payee.bank, selectedPayeeBank),
				);

			if (originalTemplate.payee.accountId === selectedPayeeAccount.id)
				diffs.push(
					...diffAccount('Payee', originalTemplate.payee, selectedPayeeAccount),
				);
		}

		return diffs.length > 0;
	}, [
		originalTemplate,
		selectedInitiator,
		selectedInitiatorBank,
		selectedInitiatorAccount,
		payeeType,
		selectedPayee,
		selectedPayeeBank,
		selectedPayeeAccount,
	]);

	// #region Out of Date Data Alerts

	const InitiatorAlert = useMemo(() => {
		if (
			!!originalTemplate?.initiator &&
			!!selectedInitiator &&
			originalTemplate.initiator.entityId === selectedInitiator.id
		) {
			return (
				<TemplatePartyAlert
					id={selectedInitiator.id}
					errorMessage="This initiator cannot be used due to the following missing or invalid data:"
					warningMessage="The initiator has been updated in Entity4. The most up-to-date information is displayed above."
					partyDiff={diffEntity(originalTemplate.initiator, selectedInitiator)}
					validate={() =>
						LegalEntityValidator.validateSync(
							selectedInitiator,
							TemplateValidatorOptions,
						)
					}
				/>
			);
		}

		return null;
	}, [originalTemplate, selectedInitiator]);

	const InitiatorCounterpartyAlert = useMemo(() => {
		if (
			!!originalTemplate?.initiator.bank &&
			!!selectedInitiatorBank &&
			originalTemplate.initiator.bank.entityId === selectedInitiatorBank.id
		) {
			return (
				<TemplatePartyAlert
					id={selectedInitiatorBank.id}
					errorMessage="This counterparty cannot be used due to the following missing or invalid data:"
					warningMessage="The counterparty has been updated in Entity4. The most up-to-date information is displayed above."
					partyDiff={diffCounterparty(
						originalTemplate.initiator.bank,
						selectedInitiatorBank,
					)}
					validate={() =>
						CounterpartyWithAccountsValidator.validateSync(
							selectedInitiatorBank,
							TemplateValidatorOptions,
						)
					}
				/>
			);
		}

		return null;
	}, [originalTemplate, selectedInitiatorBank]);

	const InitiatorAccountAlert = useMemo(() => {
		if (
			!!originalTemplate?.initiator.accountId &&
			!!selectedInitiatorAccount &&
			originalTemplate.initiator.accountId === selectedInitiatorAccount.id
		) {
			return (
				<TemplatePartyAlert
					id={selectedInitiatorAccount.id}
					errorMessage="This account cannot be used due to the following missing or invalid data:"
					warningMessage="The account has been updated in Entity4. The most up-to-date information is displayed above."
					partyDiff={diffAccount(
						'Initiator',
						originalTemplate.initiator,
						selectedInitiatorAccount,
					)}
					validate={() =>
						AccountValidator.validateSync(
							selectedInitiatorAccount,
							TemplateValidatorOptions,
						)
					}
				/>
			);
		}

		return null;
	}, [originalTemplate, selectedInitiatorAccount]);

	const PayeeAlert = useMemo(() => {
		if (
			!!originalTemplate?.payee &&
			!!selectedPayee &&
			originalTemplate.payee.entityId === selectedPayee.id
		) {
			let diff: PartyValueChange[] = [];
			let validate = () => {};
			if (payeeType === 'Entity') {
				diff = diffEntity(originalTemplate.payee, selectedPayee);
				validate = () =>
					LegalEntityValidator.validateSync(
						selectedPayee,
						TemplateValidatorOptions,
					);
			} else if (payeeType === 'Partner') {
				diff = diffPartner(originalTemplate.payee, selectedPayee);
				validate = () =>
					PartnerValidator.validateSync(
						selectedPayee,
						TemplateValidatorOptions,
					);
			} else {
				// it's counterparty
				diff = diffCounterparty(
					originalTemplate.payee.bank,
					selectedPayee as Counterparty,
				);
				validate = () =>
					CounterpartyWithAccountsValidator.validateSync(
						selectedPayee,
						TemplateValidatorOptions,
					);
			}
			return (
				<TemplatePartyAlert
					id={selectedPayee.id}
					errorMessage="This payee cannot be used due to the following missing or invalid data:"
					warningMessage="The payee has been updated in Entity4. The most up-to-date information is displayed above."
					partyDiff={diff}
					validate={validate}
				/>
			);
		}

		return null;
	}, [originalTemplate, payeeType, selectedPayee]);

	const PayeeCounterpartyAlert = useMemo(() => {
		if (
			!!originalTemplate?.payee.bank &&
			!!selectedPayeeBank &&
			originalTemplate.payee.bank.entityId === selectedPayeeBank.id
		) {
			return (
				<TemplatePartyAlert
					id={selectedPayeeBank.id}
					errorMessage="This counterparty cannot be used due to the following missing or invalid data:"
					warningMessage="The counterparty has been updated in Entity4. The most up-to-date information is displayed above."
					partyDiff={diffCounterparty(
						originalTemplate.payee.bank,
						selectedPayeeBank,
					)}
					validate={() =>
						CounterpartyWithAccountsValidator.validateSync(
							selectedPayeeBank,
							TemplateValidatorOptions,
						)
					}
				/>
			);
		}

		return null;
	}, [originalTemplate, selectedPayeeBank]);

	const PayeeAccountAlert = useMemo(() => {
		if (
			!!originalTemplate?.payee.accountId &&
			!!selectedPayeeAccount &&
			originalTemplate.payee.accountId === selectedPayeeAccount.id
		) {
			return (
				<TemplatePartyAlert
					id={selectedPayeeAccount.id}
					errorMessage="This account cannot be used due to the following missing or invalid data:"
					warningMessage="The account has been updated in Entity4. The most up-to-date information is displayed above."
					partyDiff={diffAccount(
						'Payee',
						originalTemplate.payee,
						selectedPayeeAccount,
					)}
					validate={() =>
						AccountValidator.validateSync(
							selectedPayeeAccount,
							TemplateValidatorOptions,
						)
					}
				/>
			);
		}

		return null;
	}, [originalTemplate, selectedPayeeAccount]);
	// #endregion

	const InitiatorInfoBox = useMemo(
		() => (
			<PaymentBankAndAccountInformationBox
				bankCode={selectedInitiatorBank?.bankCode}
				operatingCountryCode={
					selectedInitiatorBank?.operatingAddress?.countryCode
				}
				routingCode={selectedInitiatorBank?.routingCodes.highValueClearing}
				paymentNumber={selectedInitiatorAccount?.accountNumbers.pain001}
			/>
		),
		[selectedInitiatorBank, selectedInitiatorAccount],
	);

	const PayeeInfoBox = useMemo(
		() => (
			<PaymentBankAndAccountInformationBox
				bankCode={selectedPayeeBank?.bankCode}
				operatingCountryCode={selectedPayeeBank?.operatingAddress?.countryCode}
				routingCode={selectedPayeeBank?.routingCodes.highValueClearing}
				paymentNumber={
					selectedPayeeAccount?.accountNumbers.naturalAccountNumber
				}
			/>
		),
		[selectedPayeeBank, selectedPayeeAccount],
	);

	const DrawerActions = useMemo(
		() => [
			<DrawerCancelButton
				stonlyId={stonlyIds.cancelButton}
				onCancel={() => {
					if (formState.isDirty) setIsCancellationModalOpen(true);
					else {
						onClose();
						resetDrawer();
					}
				}}
			/>,
			<Tooltip
				title={
					originalTemplate?.hasDraftVersion
						? 'This template is currently pending approval.'
						: ''
				}
			>
				<span>
					<DrawerSubmitButton
						stonlyId={stonlyIds.submitButton}
						label="Submit"
						onSubmit={handleSubmit(onSubmit)}
						disabled={
							(!formState.isDirty && !hasOutOfDateData) ||
							isEditLoading ||
							originalTemplate?.hasDraftVersion
						}
					/>
				</span>
			</Tooltip>,
		],
		[
			formState,
			originalTemplate,
			hasOutOfDateData,
			isEditLoading,
			handleSubmit,
			onSubmit,
			onClose,
			resetDrawer,
		],
	);

	// #endregion

	return (
		<T4DrawerBase
			title="Edit Template"
			open={!!templateId}
			initializing={
				isTemplateLoading ||
				areFinancialInstitutionsLoading ||
				areFinancialInstitutionsFetching
			}
			loading={isEditLoading}
			onClose={() => {
				if (formState.isDirty) setIsCancellationModalOpen(true);
				else {
					onClose();
					resetDrawer();
				}
			}}
			actions={DrawerActions}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.detailsSection })}
				>
					<Grid item xs={12}>
						<Controller
							name="name"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4TextFieldV2
									label="Template Name"
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error && error.message}
									required
									InputProps={{ readOnly: originalTemplate?.hasDraftVersion }}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<T4TextFieldV2
							id="payment-type"
							label="Payment Type"
							value={originalTemplate?.paymentType ?? ''}
							required
							InputProps={{ readOnly: true }}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.initiatorSection })}
				>
					<Grid item xs={12}>
						<Typography variant="h4">Initiator</Typography>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Controller
							name="initiator"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<LegalEntity>
									id="initiator"
									label="Initiator Entity Name"
									options={initiators ?? []}
									value={value}
									onChange={(_: any, newValue: LegalEntity | null) => {
										onChange(newValue);
										if (value?.id !== newValue?.id) {
											setValue('initiatorBank', null);
											setValue('initiatorAccount', null);
										}
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										!LegalEntityValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										try {
											LegalEntityValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This initiator cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									readOnly={originalTemplate?.hasDraftVersion}
									loading={areInitiatorsLoading || areInitiatorsFetching}
								/>
							)}
						/>
						{InitiatorAlert}
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Controller
							name="initiatorBank"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<CounterpartyWithAccounts>
									id="initiator-bank"
									label="Initiator Counterparty"
									options={initiatorCounterparties ?? []}
									value={value}
									onChange={(
										_: any,
										newValue: CounterpartyWithAccounts | null,
									) => {
										onChange(newValue);
										if (value?.id !== newValue?.id)
											setValue('initiatorAccount', null);
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										!CounterpartyWithAccountsValidator.isValidSync(option) ||
										(!isStringUndefinedOrNullOrWhitespace(option.bankCode)
											? !isCounterpartyWirePaymentEnabled(
													option,
													financialInstitutions ?? [],
											  )
											: false)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										if (
											!isStringUndefinedOrNullOrWhitespace(option.bankCode) &&
											!isCounterpartyWirePaymentEnabled(
												option,
												financialInstitutions ?? [],
											)
										)
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This counterparty is not enabled for wire payments yet. Please contact your administrator to enable wire payments for this counterparty."
														errors={[]}
														labelBox={labelBox}
													/>
												</Box>
											);

										try {
											CounterpartyWithAccountsValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This counterparty cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									loading={areInitiatorCounterpartiesFetching}
									readOnly={
										selectedInitiator === null ||
										originalTemplate?.hasDraftVersion
									}
								/>
							)}
						/>
						{InitiatorCounterpartyAlert}
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Controller
							name="initiatorAccount"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<Account>
									id="initiator-account"
									label="Initiator Account"
									options={selectedInitiatorBank?.accounts ?? []}
									value={value}
									onChange={(_: any, value: Account | null) => onChange(value)}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										selectedPayeeAccount?.id === option.id ||
										!InitiatorAccountValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										if (selectedPayeeAccount?.id === option.id)
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="Account selected as the payee account"
														errors={[]}
														labelBox={labelBox}
													/>
												</Box>
											);

										try {
											InitiatorAccountValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This account cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									readOnly={
										selectedInitiatorBank === null ||
										originalTemplate?.hasDraftVersion
									}
								/>
							)}
						/>
						{InitiatorAccountAlert}
					</Grid>

					<Grid item xs={12}>
						<Collapse in={selectedInitiatorBank !== null}>
							{InitiatorInfoBox}
						</Collapse>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.payeeSection })}
				>
					<Grid item xs={12}>
						<Typography variant="h4">Payee</Typography>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="payeeType"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<PayeeTypes, false, true, false>
									id="payee-type"
									label="Payee Type"
									options={['Entity', 'Partner', 'Counterparty']}
									value={value}
									onChange={(_: any, newValue: PayeeTypes) => {
										if (newValue !== value) {
											setValue('payee', null);
											setValue('payeeBank', null);
											setValue('payeeAccount', null);
										}
										onChange(newValue);
									}}
									error={!!error}
									helperText={error && error.message}
									required
									disableClearable
									readOnly={originalTemplate?.hasDraftVersion}
								/>
							)}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Controller
							name="payee"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<LegalEntity | Partner | Counterparty>
									id="payee"
									label="Payee Name"
									options={payees ?? []}
									value={value}
									onChange={(
										_: any,
										newValue: LegalEntity | Partner | Counterparty | null,
									) => {
										onChange(newValue);
										if (value?.id !== newValue?.id) {
											setValue('payeeBank', null);
											setValue('payeeAccount', null);
										}
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) => {
										switch (option.objectType) {
											case 'Entity':
												return !LegalEntityValidator.isValidSync(option);
											case 'Partner':
												return !PartnerValidator.isValidSync(option);
											case 'Counterparty':
												return !CounterpartyValidator.isValidSync(option);
											default:
												return true;
										}
									}}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										try {
											switch (option.objectType) {
												case 'Entity':
													LegalEntityValidator.validateSync(
														option,
														TemplateValidatorOptions,
													);
													break;
												case 'Partner':
													PartnerValidator.validateSync(
														option,
														TemplateValidatorOptions,
													);
													break;
												case 'Counterparty':
													CounterpartyValidator.validateSync(
														option,
														TemplateValidatorOptions,
													);
													break;
												default:
													if (ownerState.getOptionDisabled?.(option) === true)
														throw new Error();
											}
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This payee cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									readOnly={originalTemplate?.hasDraftVersion}
									loading={arePayeesLoading || arePayeesFetching}
								/>
							)}
						/>
						{PayeeAlert}
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Controller
							name="payeeBank"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<CounterpartyWithAccounts>
									id="payee-bank"
									label="Payee Counterparty"
									options={payeeCounterparties ?? []}
									value={value}
									onChange={(
										_: any,
										newValue: CounterpartyWithAccounts | null,
									) => {
										onChange(newValue);
										if (value?.id !== newValue?.id)
											setValue('payeeAccount', null);
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										!CounterpartyWithAccountsValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										try {
											CounterpartyWithAccountsValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This counterparty cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									loading={arePayeeCounterpartiesFetching}
									readOnly={
										selectedPayee === null || originalTemplate?.hasDraftVersion
									}
								/>
							)}
						/>
						{PayeeCounterpartyAlert}
					</Grid>
					<Grid
						item
						xs={12}
						sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
					>
						<Controller
							name="payeeAccount"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<Account>
									id="payee-account"
									label="Payee Account"
									options={selectedPayeeBank?.accounts ?? []}
									value={value}
									onChange={(_: any, value: Account | null) => onChange(value)}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										selectedInitiatorAccount?.id === option.id ||
										!PayeeAccountValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										if (selectedInitiatorAccount?.id === option.id)
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="Account selected as the initiator account"
														errors={[]}
														labelBox={labelBox}
													/>
												</Box>
											);

										try {
											PayeeAccountValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This account cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									readOnly={
										selectedPayeeBank === null ||
										originalTemplate?.hasDraftVersion
									}
								/>
							)}
						/>
						{PayeeAccountAlert}
					</Grid>

					<Grid item xs={12}>
						<Collapse in={selectedPayeeBank !== null}>{PayeeInfoBox}</Collapse>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid container item xs={12} spacing={1}>
					<Grid item xs={12}>
						<Typography variant="h4">Currency and Details</Typography>
					</Grid>
					<Grid item xs={6}>
						<T4TextFieldV2
							id="currency-code"
							label="Payment CCY"
							value={originalTemplate?.currencyCode ?? ''}
							required
							InputProps={{ readOnly: true }}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="referenceData"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4TextFieldV2
									id="reference-data"
									label="Payment Information"
									value={value ?? ''}
									onChange={(value: string) => {
										if (isStringUndefinedOrNullOrWhitespace(value))
											onChange(null);
										else onChange(value);
									}}
									minRows={4}
									maxRows={4}
									multiline
									error={!!error}
									helperText={`${value?.length ?? 0}/140${
										error?.message ? ' ' + error.message : ''
									}`}
									inputProps={{
										maxLength: 140,
									}}
									InputProps={{ readOnly: originalTemplate?.hasDraftVersion }}
								/>
							)}
						/>
					</Grid>
				</Grid>

				{Object.values(errors).length > 0 && (
					<Grid item xs={12} ref={errorsRef}>
						<T4AlertStack errors={errors} />
					</Grid>
				)}
			</Grid>

			<CancellationModal
				isOpen={isCancellationModalOpen}
				resourceType="payment template"
				variant="edit"
				onClose={() => setIsCancellationModalOpen(false)}
				onSubmit={() => {
					onClose();
					resetDrawer();
				}}
			/>
		</T4DrawerBase>
	);
};
