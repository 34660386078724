import {
	Add,
	Edit,
	FilterList,
	ResetTv,
	Save,
	VerticalAlignTop,
} from '@mui/icons-material';
import { Alert, Card, Grid, IconButton, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { WidgetControl } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { Layout, default as ReactGridLayout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { T4View } from 'shared/components/t4View';
import { useSigmaDashboard } from '../_providers/dashboardProvider';
import { DashboardCard } from '../components/dashboardCard';
import { FilterPane } from '../components/filterPane';
import { ResetDashboardModal } from '../components/resetDashboardModal';
import { WidgetPicker } from '../components/widgetPicker';
import { useUser } from 'shared/hooks/useUser';

export const DashboardPageV2: FC = observer(() => {
	const {
		initializingDash,
		initializingWidgetPicker,
		visuals,
		dashboard,
		saveWidgets,
		resetDashboard,
		getControls,
	} = useSigmaDashboard();
	const [workbooks, setWorkbooks] = useState<string[]>([]);
	const ref = useRef<HTMLDivElement>(null);
	const [layout, setLayout] = useState<ReactGridLayout.Layout[]>(
		dashboard?.widgets.map((widget) => ({
			i: `${widget.workbookId}-${widget.elementId}`,
			x: widget.x,
			y: widget.y,
			w: widget.width,
			h: widget.height,
		})) ?? [],
	);

	const dashboardVisuals = useMemo(() => {
		return (
			visuals?.filter((x) =>
				layout.map((x) => x.i).includes(`${x.workbookId}-${x.elementId}`),
			) ?? []
		);
	}, [layout, visuals]);

	const [isAddingVisuals, setIsAddingVisuals] = useState(false);
	const [isEditMode, setIsEditMode] = useState<boolean>(false);
	const [width, setWidth] = useState<number>(0);
	const [openResetModal, setOpenResetModal] = useState(false);
	const [controls, setControls] = useState<WidgetControl[]>([]);

	useEffect(() => {
		if (dashboardVisuals.length) {
			var workbookIds = dashboard?.widgets.map((x) => x.workbookId);
			var setOfWokbookIds = new Set(workbookIds);
			setWorkbooks(Array.from(setOfWokbookIds));
		}
	}, [dashboardVisuals, dashboard, visuals]);

	useEffect(() => {
		if (workbooks.length && getControls) {
			getControls(workbooks[0]).then((res) => {
				setControls(res);
			});
		}
	}, [workbooks, getControls]);

	useEffect(() => {
		if (!ref.current) return;

		const resizeObserver = new ResizeObserver(() => {
			setWidth(ref.current?.clientWidth || 0);
		});
		resizeObserver.observe(ref.current);

		return () => {
			resizeObserver.disconnect();
		};
	}, []);

	useEffect(() => {
		if (dashboard) {
			setLayout(
				dashboard.widgets.map((widget) => ({
					i: `${widget.workbookId}-${widget.elementId}`,
					x: widget.x,
					y: widget.y,
					w: widget.width,
					h: widget.height,
				})),
			);
		}
	}, [dashboard]);

	const trimEmptyRows = () => {
		const filledRows = new Set<number>();
		layout.forEach((item) => {
			for (let y = item.y; y < item.y + item.h; y++) {
				filledRows.add(y);
			}
		});

		const sortedFilledRows = Array.from(filledRows).sort((a, b) => a - b);
		const rowMapping: Record<number, number> = {};

		sortedFilledRows.forEach((row, index) => {
			rowMapping[row] = index;
		});

		const newLayout = layout.map((item) => ({
			...item,
			y: rowMapping[item.y],
		}));

		setLayout(newLayout);
	};

	const [showWarning, setShowWarning] = useState(true);
	const [showFilterPane, setShowFilterPane] = useState(false);

	return (
		<T4View loading={initializingDash} containerRef={ref} disablePadding>
			<ResetDashboardModal
				open={openResetModal}
				onClose={function (): void {
					setOpenResetModal(false);
				}}
				onSubmit={() => {
					if (resetDashboard) {
						resetDashboard();
						setLayout([]);
					}
					setOpenResetModal(false);
				}}
				loading={false}
			/>
			<FilterPane
				open={showFilterPane}
				setOpen={setShowFilterPane}
				layout={layout}
				controls={controls}
			/>
			<Grid
				container
				sx={{
					position: 'absolute',
					width: 'auto',
					right: 24,
					top: 64,
					zIndex: 24,
					gap: 1,
				}}
			>
				<Grid item>
					{showWarning && !isEditMode && (
						<Alert
							severity="info"
							onClose={() => {
								setShowWarning(false);
							}}
						>
							<b>Previewing a beta feature. </b>This feature is currently under
							development, and unexpected changes may occur.
						</Alert>
					)}
				</Grid>
				{isEditMode && (
					<Tooltip title="Add Widget">
						<IconButton
							onClick={() => {
								setIsAddingVisuals(true);
							}}
							sx={(theme) => ({
								color: 'white',
								backgroundColor: theme.palette.equityGreen[500],

								'&:hover': {
									backgroundColor: theme.palette.equityGreen[400],
								},
							})}
						>
							<Add />
						</IconButton>
					</Tooltip>
				)}
				{isEditMode && (
					<Tooltip title="Align Widgets">
						<IconButton
							onClick={trimEmptyRows}
							sx={(theme) => ({
								color: 'white',
								backgroundColor: theme.palette.equityGreen[500],

								'&:hover': {
									backgroundColor: theme.palette.equityGreen[400],
								},
							})}
						>
							<VerticalAlignTop />
						</IconButton>
					</Tooltip>
				)}
				{isEditMode && dashboard && !dashboard.isDefault && (
					<Tooltip title="Reset Layout">
						<IconButton
							onClick={() => {
								setOpenResetModal(true);
							}}
							sx={(theme) => ({
								color: 'white',
								backgroundColor: theme.palette.equityGreen[500],

								'&:hover': {
									backgroundColor: theme.palette.equityGreen[400],
								},
							})}
						>
							<ResetTv />
						</IconButton>
					</Tooltip>
				)}

				<Grid item xs="auto">
					<Tooltip title={isEditMode ? 'Save Layout' : 'Edit Layout'}>
						<IconButton
							onClick={() => {
								if (isEditMode) {
									setIsEditMode(false);
									saveWidgets(layout);
								} else {
									setIsEditMode(!isEditMode);
								}
							}}
							sx={(theme) => ({
								color: 'white',
								backgroundColor: theme.palette.equityGreen[500],

								'&:hover': {
									backgroundColor: theme.palette.equityGreen[400],
								},
							})}
						>
							{isEditMode ? <Save /> : <Edit />}
						</IconButton>
					</Tooltip>
				</Grid>
				<Grid item xs="auto">
					<Tooltip title={'Filter Widget Data'}>
						<IconButton
							onClick={() => {
								setShowFilterPane(!showFilterPane);
							}}
							sx={(theme) => ({
								color: 'white',
								backgroundColor: theme.palette.equityGreen[500],

								'&:hover': {
									backgroundColor: theme.palette.equityGreen[400],
								},
							})}
						>
							<FilterList />
						</IconButton>
					</Tooltip>
				</Grid>
			</Grid>

			<Grid
				container
				sx={{
					flexDirection: 'column',
					height: '100%',
				}}
			>
				<Grid
					item
					xs={12}
					sx={(theme) => ({
						overflowY: 'auto',
						'.react-grid-item.react-grid-placeholder': {
							background: theme.palette.equityGreen[500],
						},
						'.react-resizable-handle': {
							zIndex: 6,
						},
						paddingTop: 5,
					})}
				>
					<ReactGridLayout
						className="layout"
						cols={24}
						autoSize={false}
						rowHeight={16}
						margin={[16, 16]}
						containerPadding={[24, 24]}
						width={width}
						layout={layout}
						onResizeStop={(
							layout,
							oldItem,
							newItem,
							placeholder,
							e,
							element,
						) => {
							const newLayout = layout.map((item) => {
								if (item.i !== newItem.i && item.y >= newItem.y + newItem.h) {
									return {
										...item,
										y: item.y + item.h,
									};
								}
								return item;
							});
							setLayout(newLayout);
						}}
						compactType={'vertical'}
						resizeHandles={['se', 'sw']}
						isResizable={isEditMode}
						isDraggable={isEditMode}
						style={{
							height: '100%',
							width: '100%',
							position: 'relative',
						}}
					>
						{layout
							.filter((item) =>
								dashboardVisuals
									.map((x) => `${x.workbookId}-${x.elementId}`)
									.includes(item.i),
							)
							.map((item) => {
								const sigmaElement = visuals.find(
									(x) => `${x.workbookId}-${x.elementId}` === item.i,
								)!;

								return (
									<Card
										key={item.i}
										elevation={
											isEditMode
												? 3
												: sigmaElement.vizualizationType === 'kpi'
												? 0
												: 2
										}
										sx={{
											backgroundColor: 'white',
											borderRadius: 1,
											boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.1)',
											transition: 'box-shadow 0.3s',
										}}
										square
									>
										<DashboardCard
											key={item.i}
											isEditMode={isEditMode}
											sigmaElement={sigmaElement}
											onDelete={(event, item) => {
												event.stopPropagation();
												setLayout(
													layout.filter(
														(x) =>
															x.i !== `${item.workbookId}-${item.elementId}`,
													),
												);
											}}
										/>
									</Card>
								);
							})}
					</ReactGridLayout>
				</Grid>
			</Grid>

			<WidgetPicker
				open={isAddingVisuals}
				loading={initializingWidgetPicker}
				onClose={() => setIsAddingVisuals(false)}
				onSubmit={(visuals) => {
					let nextLayout: Layout[] = [
						...layout,
						...visuals.map((v) => ({
							i: `${v.workbookId}-${v.elementId}`,
							x: 0,
							y: 0,
							w: 2,
							h: 4,
						})),
					];
					setLayout(nextLayout);
					setIsAddingVisuals(false);
				}}
			/>
		</T4View>
	);
});
