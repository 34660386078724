import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export type C4AlertBoldProps = {};

export const C4AlertBold: FC<C4AlertBoldProps> = observer(({ children }) => {
	return (
		<Typography
			component="span"
			sx={(theme) => ({
				color: theme.palette.denim[500],
				fontWeight: '800',
				lineHeight: 1.43,
				fontSize: '0.875rem',
			})}
		>
			{children}
		</Typography>
	);
});
