import { Add, Delete } from '@mui/icons-material';
import { Divider, Grid, MenuItem, Typography } from '@mui/material';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { T4Button } from 'features/entity4/shared/components/atoms/t4Button';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { observer } from 'mobx-react-lite';
import {
	Field,
	OptionList,
	Tab,
} from 'modules/clients/customer-api/src/api/common';
import { FC, useCallback, useMemo, useState } from 'react';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { Collection } from '../object/models/collection';
import { T4Object } from '../object/models/t4Object';
import {
	contactCollectionIdentifier,
	phoneNumberTypeIdentifier,
} from './contactsView';
import { CreatePhoneNumberModal } from './createPhoneNumberModal';
import { DeletePhoneNumberModal } from './deletePhoneNumberModal';
import { getFields } from './functions/v2EntityFieldRenderer';

export type ContactDrawerProps = {
	object: T4Object;
	tab: Tab;
	collectionId: string | undefined;
	open: boolean;
	onClose: () => void;
	deleteCollection: () => Promise<void>;
	setDeleteCollectionId: (id: string) => void;
	handleContactDrawerOpen: (collectionId: string, mode: FieldViews) => void;
};

const stonlyIds = {
	cancelButton: 'contact-drawer-cancel-button',
};

export const ContactDrawer: FC<ContactDrawerProps> = observer(
	({
		object,
		tab,
		collectionId,
		open,
		onClose,
		deleteCollection,
		setDeleteCollectionId,
		handleContactDrawerOpen,
	}) => {
		const { viewType } = useProfileView();
		const { entity4StaffPii } = useT4FeatureFlags();
		const { isAuthor } = useUser();

		const [isCreatePhoneNumberModalOpen, setIsCreatePhoneNumberModalOpen] =
			useState<boolean>(false);
		const [isDeletePhoneNumberModalOpen, setIsDeletePhoneNumberModalOpen] =
			useState<boolean>(false);
		const [deletingNumberTitle, setDeletingNumberTitle] = useState<string>();

		const isReadOnly = useMemo(() => viewType !== FieldViews.edit, [viewType]);
		const collection = useMemo(() => {
			if (collectionId) {
				return object.collections.find((x) => x.id === collectionId);
			}
		}, [object, collectionId]);
		const phoneNumbers = useMemo(
			() =>
				object.collections.filter(
					(x) =>
						x.parentCollectionId === collectionId &&
						x.identifier === 't4_fieldset_contact_phone_number',
				),
			[
				collectionId,
				object.collections,

				// Please leave this line
				// eslint-disable-next-line react-hooks/exhaustive-deps
				object?.collections?.filter(
					(x) =>
						x.parentCollectionId === collectionId &&
						x.identifier === 't4_fieldset_contact_phone_number',
				),
			],
		);
		const fields = useMemo(
			() =>
				getFields(
					object,
					tab.fieldGroups.find(
						(x) => x.identifier === contactCollectionIdentifier,
					)!,
					entity4StaffPii,
					collection,
				),
			[collection, entity4StaffPii, object, tab.fieldGroups],
		);
		const phoneNumberFields = useMemo((): [
			Collection,
			[Field, JSX.Element][],
		][] => {
			if (collectionId) {
				return object.collections
					.filter((x) => x.parentCollectionId === collectionId)
					.map((x) => [
						x,
						getFields(
							object,
							tab.fieldGroups
								.find((x) => x.identifier === contactCollectionIdentifier)!
								.childFieldGroups.find(
									(x) => x.identifier === 't4_fieldset_contact_phone_number',
								)!,
							entity4StaffPii,
							x,
						),
					]);
			}

			return [];
		}, [
			collectionId,
			entity4StaffPii,
			object,
			tab.fieldGroups,

			// Please leave this line
			// eslint-disable-next-line react-hooks/exhaustive-deps
			object?.collections?.filter(
				(x) =>
					x.parentCollectionId === collectionId &&
					x.identifier === 't4_fieldset_contact_phone_number',
			),
		]);

		const phoneNumberTypeList = useMemo<OptionList | undefined>(
			() =>
				tab.fieldGroups
					.filter((x) => x.identifier === contactCollectionIdentifier)?.[0]
					?.childFieldGroups?.[0]?.fields.find(
						(x) => x.identifier === phoneNumberTypeIdentifier,
					)?.optionList,
			[tab.fieldGroups],
		);

		const getContactField = useCallback(
			(identifier: string) =>
				fields?.find((x) => x[0]?.identifier === identifier)?.[1],
			[fields],
		);
		const getPhoneNumberField = useCallback(
			(collection: Collection, identifier: string) => {
				return phoneNumberFields.length
					? phoneNumberFields
							.find((x) => x[0].id === collection.id)![1]
							.find((x) => x[0].identifier === identifier)![1]
					: [];
			},
			[phoneNumberFields],
		);

		const handleDeletePhoneNumber = useCallback(async (): Promise<void> => {
			try {
				await deleteCollection();
				setDeleteCollectionId('');
				setDeletingNumberTitle('');
			} catch {}
		}, [deleteCollection, setDeleteCollectionId]);

		return (
			<T4DrawerBase
				open={open}
				title={`${viewType === FieldViews.edit ? 'Edit' : 'View'} Contact`}
				onClose={onClose}
				utilityActions={[
					viewType === FieldViews.default && isAuthor && collectionId ? (
						<OverflowMenu id="drawer-overflow-menu">
							<MenuItem
								onClick={() =>
									handleContactDrawerOpen(collectionId, FieldViews.edit)
								}
							>
								Edit
							</MenuItem>
						</OverflowMenu>
					) : null,
				]}
				actions={[
					<DrawerCancelButton
						stonlyId={stonlyIds.cancelButton}
						label="Close"
						onCancel={onClose}
					/>,
				]}
			>
				<Grid container sx={{ gap: 2 }}>
					<Grid item xs={12}>
						{getContactField('t4_field_contact_type')}
					</Grid>
					<Grid item xs={12}>
						{getContactField('t4_field_contact_name')}
					</Grid>
					<Grid item xs={12}>
						{getContactField('t4_field_contact_title_description')}
					</Grid>
					<Grid item xs={12} sx={{ paddingTop: '1rem' }}>
						<Divider />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h6">Email</Typography>
					</Grid>
					<Grid item xs={12}>
						{getContactField('t4_field_contact_primary_email')}
					</Grid>
					<Grid item xs={12}>
						{getContactField('t4_field_contact_secondary_email')}
					</Grid>
					<Grid item xs={12} sx={{ paddingTop: '1rem' }}>
						<Divider />
					</Grid>
					<Grid container item xs={12} sx={{ justifyContent: 'space-between' }}>
						<Grid item xs="auto">
							<Typography variant="h6">Phone</Typography>
						</Grid>
						{!isReadOnly && (
							<Grid item xs="auto">
								<T4Button
									variant="outlined"
									startIcon={<Add />}
									onClick={() => setIsCreatePhoneNumberModalOpen(true)}
								>
									Add Phone
								</T4Button>
							</Grid>
						)}
					</Grid>
					<Grid container xs={12} item sx={{ gap: 2 }}>
						{phoneNumbers.map((collection, index) => (
							<Grid
								key={`contact-phone-number-${index}`}
								container
								item
								xs={12}
								sx={{ gap: 2 }}
							>
								<Grid item xs={12}>
									{getPhoneNumberField(
										collection,
										't4_field_contact_phone_number_type',
									)}
								</Grid>
								<Grid
									item
									xs={12}
									sx={{ justifyContent: 'space-between', gap: 2 }}
								>
									{getPhoneNumberField(
										collection,
										't4_field_contact_phone_number',
									)}
								</Grid>
								<Grid
									container
									item
									xs={12}
									sx={{ justifyContent: 'flex-end' }}
								>
									<T4Button
										variant="outlined"
										color="error"
										startIcon={<Delete />}
										onClick={() => {
											const number = collection.fields.find(
												(x) => x.identifier === 't4_field_contact_phone_number',
											)?.approvedPhoneNumber;

											let deletingName = '';
											if (number?.number) {
												deletingName = `${number?.countryCode} ${number?.number} ${
													number?.extension?.length
														? 'x' + number.extension
														: ''
												}`;
											} else {
												const numberTypeId = collection.fields.find(
													(x) =>
														x.identifier ===
														't4_field_contact_phone_number_type',
												)?.approvedOptionSourceId;

												deletingName =
													phoneNumberTypeList?.options.find(
														(x) => x.id === numberTypeId,
													)?.displayName ?? '';
											}
											setIsDeletePhoneNumberModalOpen(true);
											setDeleteCollectionId(collection.id);
											setDeletingNumberTitle(deletingName);
										}}
										sx={{ justifySelf: 'end' }}
									>
										Delete
									</T4Button>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
				<DeletePhoneNumberModal
					open={isDeletePhoneNumberModalOpen}
					title={deletingNumberTitle ?? ''}
					onClose={() => setIsDeletePhoneNumberModalOpen(false)}
					onDelete={handleDeletePhoneNumber}
				/>
				<CreatePhoneNumberModal
					object={object}
					tab={tab}
					parentCollectionId={collectionId}
					open={isCreatePhoneNumberModalOpen}
					onClose={() => setIsCreatePhoneNumberModalOpen(false)}
				/>
			</T4DrawerBase>
		);
	},
);
