import { ArrowDropDown, Cached, Clear } from '@mui/icons-material';
import {
	Alert,
	Box,
	Breadcrumbs,
	Button,
	Grid,
	IconButton,
	Tooltip,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { CannotDisplay } from 'shared/components/cannotDisplay';
import { T4View } from 'shared/components/t4View';
import { NOT_FOUND_MESSAGING } from 'shared/constants/cannotDisplayMessaging';
import { AddFolderButton } from 'shared/dashboardPage/addFolderButton';
import { AddReportButton } from 'shared/dashboardPage/addReportButton';
import { DashboardOptions } from 'shared/dashboardPage/dashboardOptions';
import { useClients } from 'shared/hooks/useClients';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { dateTimeReadFormat } from 'shared/utilities/dateUtilities';
import { useDataRefreshesQuery } from '../_hooks/useDataRefreshesQuery';
import { useSigma } from '../_providers/sigmaProvider';
import { SigmaButton } from './sigmaButton';
import { SigmaNavMenu } from './sigmaNavMenu';

export const SigmaEmbed: FC = observer(() => {
	const { customerApiClient } = useClients();
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const { data: sigmaDataRefreshes, refetch: refetchDataRefreshes } =
		useDataRefreshesQuery();
	const {
		initializing,
		sigmaUrl,
		workbooks,
		workbook,
		folders,
		folder,
		path,
		loadingNewItem,
	} = useSigma();

	const pollingRef = useRef<NodeJS.Timer>();
	const [refreshingData, setRefreshingData] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [openFolderAdd, setOpenFolderAdd] = useState(false);
	const [openReportAdd, setOpenReportAdd] = useState(false);
	const [showWarning, setShowWarning] = useState(true);

	const [menuId, setMenuId] = useState<string | null>(null);
	const [lastOpened, setLastOpened] = useState<string | null>(null);
	const { hideBetaMessaging } = useT4FeatureFlags();

	const dataSyncing = useMemo(() => {
		return (
			sigmaDataRefreshes?.some(
				(x) => x.status === 'Syncing' && x.completedAt === undefined,
			) ?? false
		);
	}, [sigmaDataRefreshes]);

	const latestRefresh = useMemo(() => {
		const orderedList = sigmaDataRefreshes?.sort(
			(a, b) =>
				moment(b.completedAt).valueOf() - moment(a.completedAt).valueOf(),
		);

		if (orderedList && orderedList.length > 0) {
			return orderedList[0];
		}
	}, [sigmaDataRefreshes]);
	const lastSuccessfulRefresh = useMemo(() => {
		const orderedList = sigmaDataRefreshes
			?.filter((x) => x.status === 'Completed')
			.sort(
				(a, b) =>
					moment(b.completedAt).valueOf() - moment(a.completedAt).valueOf(),
			);

		if (orderedList && orderedList.length > 0) {
			return orderedList[0];
		}
	}, [sigmaDataRefreshes]);

	const tooltipText = useMemo(() => {
		if (dataSyncing) {
			return 'Manual refresh in progress.';
		} else if (lastSuccessfulRefresh && lastSuccessfulRefresh.completedAt) {
			return `Data last manually refreshed on ${moment
				.utc(lastSuccessfulRefresh.completedAt)
				.local()
				.format(dateTimeReadFormat)
				.toUpperCase()}`;
		} else {
			return 'No manual refreshes have occured.';
		}
	}, [dataSyncing, lastSuccessfulRefresh]);

	const onRefreshDataClickHandler = useCallback(async () => {
		try {
			if (dataSyncing) {
				throw new Error('Data is already syncing.');
			}

			const response = await customerApiClient.sigmaEmbed.createDataRefresh();

			if (response.status === 201) {
				refetchDataRefreshes();
			} else {
				throw new Error('Failed to start data refresh.');
			}
		} catch (error) {
			if (
				lastSuccessfulRefresh &&
				moment
					.utc(lastSuccessfulRefresh.completedAt)
					.local()
					.add(10, 'minutes')
					.isAfter(moment())
			) {
				enqueueSnackbar(
					'Data can only be manually refreshed every 10 minutes. Please try again later.',
					{
						variant: 'info',
					},
				);
			} else {
				enqueueSnackbar('Failed to start data refresh.', {
					variant: 'error',
				});
			}
		}
	}, [
		customerApiClient.sigmaEmbed,
		dataSyncing,
		enqueueSnackbar,
		lastSuccessfulRefresh,
		refetchDataRefreshes,
	]);

	useEffect(() => {
		if (dataSyncing) {
			setRefreshingData(true);
			pollingRef.current = setInterval(() => {
				refetchDataRefreshes();
			}, 10000);
		} else {
			if (refreshingData) {
				setRefreshingData(false);

				if (latestRefresh?.status === 'Completed') {
					enqueueSnackbar('Data refresh complete.', {
						variant: 'success',
					});
				} else {
					enqueueSnackbar('Data refresh failed. Please try again.', {
						key: 'data-refresh-failed',
						persist: true,
						variant: 'error',
						hideIconVariant: true,
						action: (
							<Grid container>
								<Grid item xs="auto">
									<Button
										onClick={() => {
											onRefreshDataClickHandler();
											closeSnackbar('data-refresh-failed');
										}}
										sx={{
											color: 'white',
										}}
									>
										Retry
									</Button>
								</Grid>
								<Grid item xs="auto">
									<IconButton
										size="small"
										onClick={() => {
											closeSnackbar('data-refresh-failed');
										}}
									>
										<Clear sx={{ color: 'white' }} />
									</IconButton>
								</Grid>
							</Grid>
						),
					});
				}
			}
			clearInterval(pollingRef.current);
		}

		return () => {
			clearInterval(pollingRef?.current);
		};
	}, [
		closeSnackbar,
		dataSyncing,
		enqueueSnackbar,
		latestRefresh?.status,
		onRefreshDataClickHandler,
		refetchDataRefreshes,
		refreshingData,
	]);

	useEffect(() => {
		if (lastOpened !== menuId && menuId) {
			setLastOpened(menuId);
		}
	}, [menuId, lastOpened]);

	const handleClickBreadcrumb = useCallback(
		(event: React.MouseEvent<HTMLElement>, id: string) => {
			if (!anchorEl) {
				setMenuId(id);
				setAnchorEl(event.currentTarget);
			} else {
				setAnchorEl(null);
				setMenuId(null);
			}
		},
		[anchorEl],
	);

	const handleOpenFolderAdd = useCallback(() => {
		setAnchorEl(null);
		setMenuId(null);
		setOpenFolderAdd(true);
	}, []);

	const handleOpenReportAdd = useCallback(() => {
		setAnchorEl(null);
		setMenuId(null);
		setOpenReportAdd(true);
	}, []);

	const breadcrumbs = useMemo(() => {
		return (
			<Breadcrumbs aria-label="breadcrumb" separator="/">
				{path?.map((id) => {
					const folder = folders.find((f) => f.id === id);
					const workbook = workbooks.find((w) => w.id === id);
					return (
						<SigmaButton
							key={id}
							onClick={(event) =>
								handleClickBreadcrumb(
									event,
									folder?.parentId ?? workbook?.folderId ?? '',
								)
							}
							style={{ cursor: 'pointer' }}
						>
							{(folder && folder.name) || (workbook && workbook.name)}{' '}
							<ArrowDropDown />
						</SigmaButton>
					);
				})}
				<SigmaButton
					key={workbook?.id}
					onClick={(event) => {
						if (!loadingNewItem) {
							handleClickBreadcrumb(
								event,
								workbook?.folderId ?? folder?.id ?? '',
							);
						}
					}}
					style={{ cursor: 'pointer' }}
				>
					{workbook && workbook.name}
					{loadingNewItem && 'Loading...'}
					{!workbook && !loadingNewItem && 'Select'}
					<ArrowDropDown />
				</SigmaButton>
			</Breadcrumbs>
		);
	}, [
		path,
		folders,
		workbooks,
		workbook,
		loadingNewItem,
		folder,
		handleClickBreadcrumb,
	]);

	return (
		<T4View disablePadding={true} loading={initializing}>
			<AddFolderButton
				open={openFolderAdd}
				setOpen={setOpenFolderAdd}
				parentId={lastOpened ?? ''}
			/>
			<AddReportButton open={openReportAdd} setOpen={setOpenReportAdd} />
			<Grid container direction="column" sx={{ height: '100%' }}>
				{showWarning && !hideBetaMessaging && (
					<Alert
						severity="info"
						onClose={() => {
							setShowWarning(false);
						}}
						sx={{ margin: 2, marginBottom: 0 }}
					>
						<b>Previewing Analytics Studio Beta. </b>This feature is currently
						under development, and unexpected changes may occur.
					</Alert>
				)}

				{folders.length > 0 && (
					<Grid
						container
						item
						sx={{
							padding: '0.5rem',
						}}
					>
						<Grid item xs={true}>
							{breadcrumbs}
						</Grid>
						<Grid item container xs="auto" justifyContent="flex-end">
							<Tooltip title={tooltipText}>
								<Box sx={{ alignContent: 'center' }}>
									<SigmaButton
										loading={dataSyncing}
										startIcon={<Cached />}
										onClick={() => onRefreshDataClickHandler()}
									/>
								</Box>
							</Tooltip>
							<DashboardOptions />
						</Grid>
					</Grid>
				)}

				{anchorEl && (
					<SigmaNavMenu
						folderId={folders.find((f) => f.id === menuId)?.id ?? 'root'}
						anchorEl={anchorEl}
						setAnchorEl={setAnchorEl}
						menuId={menuId}
						setMenuId={setMenuId}
						handleOpenFolderAdd={handleOpenFolderAdd}
						handleOpenReportAdd={handleOpenReportAdd}
					/>
				)}

				{!loadingNewItem && (
					<Grid item sx={{ flex: 2, display: 'flex' }}>
						{workbooks.filter((x) => x.folderId === folder?.id).length !== 0 ? (
							<iframe
								title="Report"
								width="100%"
								style={{ flex: 1, border: 'none' }}
								src={sigmaUrl}
							/>
						) : (
							<CannotDisplay
								headingText="Whoops! No reports here yet..."
								bodyText="Let's change that!"
								imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							/>
						)}
					</Grid>
				)}
			</Grid>
		</T4View>
	);
});
