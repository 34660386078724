import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { NETWORK_ERROR } from 'features/entity4/globalDocuments/pages/models/globalDocumentViewModel';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ObjectPathParams } from '../../../../../shared/constants/paths';
import { T4TextFieldV2 } from '../../components/atoms/t4TextField';
import { InputAdornmentWithModal } from '../../components/molecules/inputAdornmentWithModal';
import { FieldDataType } from '../fieldTypes';
import { FieldError } from './common/fieldError';
import { FieldViewFieldLayout } from './common/fieldLayout';
import { FieldViewProps, FieldViews } from './fieldViewTypes';

export const FieldViewString: FC<FieldViewProps> = observer(
	({ fieldDefinition, fieldData, customError, ...props }) => {
		const { objectId } = useParams<ObjectPathParams>();
		const { viewType } = useProfileView();

		const [value, setValue] = useState<string | undefined>(
			fieldData?.initialValue,
		);
		const [error, setError] = useState<string>();

		const [isDirty, setIsDirty] = useState(false);

		const regularExpression = useMemo(
			() =>
				fieldDefinition.validationCriterion?.validationRegex
					? new RegExp(fieldDefinition.validationCriterion.validationRegex)
					: undefined,
			[fieldDefinition?.validationCriterion?.validationRegex],
		);

		const isReadOnly = useMemo(() => viewType !== FieldViews.edit, [viewType]);

		const helperText = useMemo(() => {
			return viewType === FieldViews.edit
				? fieldDefinition.identifier === 't4_field_description'
					? `${value?.length ?? 0}/2048`
					: ''
				: '';
		}, [fieldDefinition.identifier, value?.length, viewType]);

		const shouldDisplayWarning = useCallback(
			() =>
				fieldData?.id &&
				fieldData.initialValue &&
				value &&
				fieldDefinition.validationCriterion &&
				regularExpression &&
				!regularExpression.test(value) &&
				viewType === FieldViews.edit,
			[
				fieldData?.id,
				fieldData?.initialValue,
				fieldDefinition.validationCriterion,
				regularExpression,
				value,
				viewType,
			],
		);

		const saveValue = useCallback(() => {
			if (isDirty) {
				const valueToSave = value?.trim();
				if (fieldData) {
					fieldData
						.save(valueToSave)
						.then(() => {
							setIsDirty(false);
							setValue(valueToSave);
						})
						.catch(() => setError(NETWORK_ERROR));
				} else {
					props.createField(valueToSave);
				}
			}
		}, [fieldData, isDirty, props, value]);

		return (
			<FieldViewFieldLayout
				entityId={objectId}
				fieldDefinition={fieldDefinition}
				fieldData={fieldData}
				approvedValueToDisplay={fieldData?.approvedValue}
				value={value}
				{...props.fieldLayoutProps}
			>
				<T4TextFieldV2
					InputProps={{
						readOnly: isReadOnly,
						startAdornment: fieldDefinition.description ? (
							<InputAdornmentWithModal
								title={fieldDefinition.name}
								description={fieldDefinition.description}
								adornmentType={fieldData?.loadingState}
								iconButtonProps={{
									...(viewType === FieldViews.default
										? {
												sx: {
													marginLeft: '-5px',
												},
										  }
										: {}),
								}}
							/>
						) : undefined,
						sx: {
							...(viewType === FieldViews.default
								? {
										color: 'transparent',
										borderColor: 'transparent',
										borderBottomColor: 'transparent',
										'&:before, :after, :hover': {
											borderBottomColor: 'transparent !important',
										},
										padding: '0rem !important',
								  }
								: {}),
						},
					}}
					variant={viewType === FieldViews.default ? 'standard' : 'outlined'}
					InputLabelProps={{ shrink: true }}
					required={fieldDefinition.metadata.isRequired}
					label={fieldDefinition.name}
					value={value ?? ''}
					onChange={(value) => {
						if (!isDirty) setIsDirty(true);
						setValue(value);
					}}
					onBlur={saveValue}
					multiline={fieldDefinition.fieldType === FieldDataType.longText}
					minRows={3}
					maxRows={6}
					helperText={helperText}
				/>

				<FieldError
					error={fieldData?.loadingErrorMessage}
					customError={customError}
					warning={
						shouldDisplayWarning()
							? fieldDefinition.validationCriterion?.errorDescription
							: undefined
					}
					onTryAgainClick={saveValue}
				/>
			</FieldViewFieldLayout>
		);
	},
);
