import {
	Auth0Provider,
	useAuth0,
	withAuthenticationRequired,
} from '@auth0/auth0-react';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import { FC, ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useConfigurations } from 'shared/hooks/useConfigurations';
import { useOrgId } from 'shared/hooks/useOrgId';
import { useSessionStorage } from 'shared/hooks/useSessionStorage';
import { paths } from '../constants/paths';

export type T4Auth0ProviderProps = {
	children: ReactNode;
};

export const T4Auth0Provider: FC<T4Auth0ProviderProps> = observer(
	({ children }) => {
		const { auth0Domain, auth0Client, auth0Audience } = useConfigurations();
		const { organizationId } = useSessionStorage();
		const history = useHistory();

		return (
			<Auth0Provider
				domain={auth0Domain}
				clientId={auth0Client}
				onRedirectCallback={(appState) =>
					history.push(appState?.returnTo ?? window.location.pathname)
				}
				authorizationParams={{
					audience: auth0Audience,
					redirect_uri: window.location.origin + paths.root.href,
					organization: organizationId,
					invitation:
						(queryString.parse(window.location.search).invitation as string) ??
						undefined,
				}}
			>
				<T4Auth0Setup>{children}</T4Auth0Setup>
			</Auth0Provider>
		);
	},
);

type T4Auth0SetupProps = {
	children: ReactNode;
};
const T4Auth0Setup: FC<T4Auth0SetupProps> = withAuthenticationRequired(
	({ children }) => {
		const { isLoading, isAuthenticated, error, loginWithRedirect } = useAuth0();
		const organizationId = useOrgId();
		const { setOrganizationId } = useSessionStorage();

		useEffect(() => {
			if (!isLoading && !isAuthenticated && !error) {
				const query = queryString.parse(window.location.search);

				loginWithRedirect({
					authorizationParams: {
						invitation: query.invitation as string | undefined,
					},
					appState: {
						returnTo: window.location.pathname + window.location.search,
					},
				});
			}
		}, [loginWithRedirect, isLoading, isAuthenticated, error]);

		useEffect(() => {
			setOrganizationId(organizationId);
		}, [setOrganizationId, organizationId]);

		return <>{children}</>;
	},
);
