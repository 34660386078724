import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterMoment as DateAdapter } from '@mui/x-date-pickers-pro/AdapterMoment';
import { LoginError } from 'features/entity4/shared/loginError';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import LoadingScreen from 'shared/components/loading-screen';
import { useApplication } from 'shared/hooks/useApplication';
import { T4StonlyWidgetProvider } from 'shared/providers/t4StonlyWidgetProvider';
import { T4LanchDarklyProvider } from './t4LaunchDarklyProvider';

export type ApplicationGateProps = {
	children: ReactNode;
};

export const ApplicationGate: FC<ApplicationGateProps> = observer(
	({ children }) => {
		const { isLoading, isAuthenticated, error } = useApplication();

		if (isLoading) {
			return <LoadingScreen />;
		}

		if (error || !isAuthenticated) {
			return <LoginError />;
		}

		return (
			<T4LanchDarklyProvider>
				<ApplicationInnerGate>
					<LocalizationProvider dateAdapter={DateAdapter}>
						<T4StonlyWidgetProvider />
						{children}
					</LocalizationProvider>
				</ApplicationInnerGate>
			</T4LanchDarklyProvider>
		);
	},
);

type ApplicationInnerGateProps = {
	children: ReactNode;
};

const ApplicationInnerGate: FC<ApplicationInnerGateProps> = observer(
	({ children }) => {
		const flags = useFlags();

		if (Object.keys(flags).length === 0) {
			return <LoadingScreen />;
		}

		return <>{children}</>;
	},
);
