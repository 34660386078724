import { Box } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import {
	T4TextFieldV2,
	T4TextFieldV2Props,
} from '../shared/components/atoms/t4TextField';
import {
	T4AlertStack,
	T4AlertStackProps,
} from '../shared/components/molecules/t4AlertStack';
import {
	T4FieldAdornment,
	T4FieldAdornmentProps,
} from '../shared/components/molecules/t4FieldAdornment';

export type StringFieldProps = Pick<
	T4TextFieldV2Props,
	'id' | 'label' | 'value' | 'required' | 'autoFocus'
> & {
	onChange: (value: string) => void;
	adornmentProps: Pick<T4FieldAdornmentProps, 'title' | 'description'>;
	warning?: T4AlertStackProps['warning'];
};

export const StringField: FC<StringFieldProps> = observer(
	({
		id,
		label,
		value,
		onChange,
		required,
		adornmentProps,
		warning,
		...rest
	}) => {
		return (
			<Box>
				<T4TextFieldV2
					{...rest}
					id={id}
					label={label}
					value={value}
					onChange={(value) => onChange(value)}
					required={required}
					startAdornment={
						<T4FieldAdornment
							id={id}
							t4AdornmentType="info"
							title={adornmentProps?.title}
							description={adornmentProps?.description}
						/>
					}
				/>
				<T4AlertStack warning={warning} />
			</Box>
		);
	},
);
