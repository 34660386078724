import { AxiosInstance } from 'axios';
import { Read, T4DataResponse2, Write } from 'modules/clients/types';

//#region Read Api Models

export type SigmaDashboard = {
	id: string;
	code: string;
	isDefault: boolean;
	isOrgDefault: boolean;
	createdBy: string;
	createdOn: Date;
	updatedBy: string;
	updatedOn: Date;
	widgets: SigmaWidget[];
	deletedDateUtc?: Date;
};

export type SigmaWidget = {
	id: string;
	workbookId: string;
	elementId: string;
	workbookUrlId: string;
	vizualizationType: string;
	embedId: string;
	width: number;
	height: number;
	x: number;
	y: number;
};

export type SigmaFolderItem = {
	itemId: string;
	parentId: string;
	order: number;
	itemType: string;
};

export type SigmaElement = {
	elementId: string;
	name: string;
	type: string;
	vizualizationType: string;
	report: string;
	page: string;
	workbookName: string;
	workbookPageName: string;
	workbookUrlId: string;
	workbookId: string;
	workbookPageId: string;
	workbookFolderId: string;
	workbookPermission: string;
	embedId: string;
	status: string;
	columns: string[];
};

export type SigmaWorkbook = {
	id: string;
	folderId: string;
	urlId: string;
	name: string;
	description: string;
	permission: string;
	updatedAt: string;
	order?: number;
};

export type SigmaFolder = {
	id: string;
	name: string;
	permission: string;
	parentId: string;
	order?: number;
};

export type SigmaFileEntry = {
	id: string;
	urlId: string;
	name: string;
	description: string;
	type: string;
	parentId: string;
	parentUrlId: string;
	permission: string;
	path: string;
	badge: string;
	createdBy: string;
	updatedBy: string;
	createdAt: string;
	updatedAt: string;
	isArchived: boolean;
	embeds: SigmaEmbedEntry[];
};

export type SigmaEmbedEntry = {
	embedId: string;
	embedUrl: string;
	public: boolean;
};

export type WidgetControl = {
	name: string;
	valueType: string;
};

export type SigmaDataRefresh = {
	id: string;
	status: string;
	startedAt: string;
	startedBy: string;
	completedAt?: string;
};

//#endregion

//#region Write Api Models

export type GetSigmaVizV2EmbedReq = {
	elementId: string;
	workbookId: string;
	workbookUrlId: string;
	embedId: string;
};

export type SigmaDashboardReq = {
	code: string;
	isOrgDefault: boolean;
	widgets: SigmaWidgetReq[];
	delete: boolean;
};

export type SigmaFolderItemsReq = {
	parentId: string;
	itemId: string;
	order: number;
	itemType: string;
};

export type SigmaWidgetReq = {
	workbookId: string;
	elementId: string;
	workbookUrlId: string;
	vizualizationType: string;
	width: number;
	height: number;
	x: number;
	y: number;
};

export type SigmaWorkbookReq = {
	folderId: string;
	name: string;
	description: string;
	folderName: string;
};

export type GetSigmaEmbedReq = {
	workbookId: string;
	workbookUrlId: string;
	embedMode: string;
};

export type SigmaFolderReq = {
	name: string;
	parentId: string;
};

export type DeleteSigmaFileReq = {
	id: string;
};

export type ShareSigmaWorkbookReq = {
	id: string;
	copy: boolean;
	permissions: string;
	name: string;
	folder: string;
};

export type EditSigmaFileReq = {
	fileId: string;
	name: string;
	description: string;
	parentId: string;
};

export type EditSigmaFolderReq = {
	fileId: string;
	name: string;
};

export type MoveSigmaWorkbookReq = {
	fileId: string;
	folderId: string;
};

export type SigmaUserReq = {
	userId: string;
};

export type SigmaVisualReq = {
	solution?: string | null;
};

//#endregion

export type SigmaEmbedEndpoints = {
	visualEmbeds: Read<SigmaElement[], SigmaVisualReq>;
	defaultDashboard: Read<SigmaElement[], SigmaVisualReq>;
	sigmaVizUrlv2: Write<string, GetSigmaVizV2EmbedReq>;
	loadAllThingsSigma: Read<{
		memberId: string;
		sigmaWorkbooks: SigmaWorkbook[];
		solutionFolders: SigmaFolder[];
	}>;
	workbookEmbeds: Write<SigmaWorkbook[], SigmaUserReq>;
	createReport: Write<SigmaWorkbook, SigmaWorkbookReq>;
	sigmaUrl: Write<string, GetSigmaEmbedReq>;
	getFolders: Write<SigmaFolder[], SigmaUserReq>;
	createFolder: Write<SigmaFolder, SigmaFolderReq>;
	deleteFile: Write<boolean, DeleteSigmaFileReq>;
	shareFile: Write<SigmaWorkbook, ShareSigmaWorkbookReq>;
	editFile: Write<SigmaFileEntry, EditSigmaFileReq>;
	recentlyDeleted: Write<string, SigmaUserReq>;
	editFolder: Write<SigmaFileEntry, EditSigmaFolderReq>;
	moveWorkbook: Write<SigmaWorkbook, MoveSigmaWorkbookReq>;
	getUser: Read<string>;
	getDashboards: Read<T4DataResponse2<SigmaDashboard[]>, { code: string }>;
	createDashboard: Write<T4DataResponse2<SigmaDashboard>, SigmaDashboardReq>;
	updateDashboard: Write<
		T4DataResponse2<SigmaDashboard>,
		{
			id: string;
			req: SigmaDashboardReq;
		},
		SigmaDashboardReq
	>;
	getFolderItems: Read<SigmaFolderItem[], { parentId: string | null }>;
	createFolderItems: Write<SigmaFolderItem[], SigmaFolderItemsReq>;
	updateFolderItems: Write<SigmaFolderItem[], SigmaFolderItemsReq>;
	getControls: Read<WidgetControl[], { workbookId: string }>;
	getDataRefreshes: Read<T4DataResponse2<SigmaDataRefresh[]>>;
	createDataRefresh: Write<T4DataResponse2<SigmaDataRefresh>, void>;
	getDataRefresh: Read<T4DataResponse2<SigmaDataRefresh>, { id: string }>;
};

export function sigmaEmbed(axiosInstance: AxiosInstance): SigmaEmbedEndpoints {
	return {
		visualEmbeds: async (req) =>
			await axiosInstance.post<SigmaElement[]>('/sigmaembed/visualembeds', req),
		defaultDashboard: async (req) =>
			await axiosInstance.post<SigmaElement[]>(
				'/sigmaembed/defaultdashboard',
				req,
			),
		sigmaVizUrlv2: async (req) =>
			await axiosInstance.post<string>('/sigmaembed/sigmavizurlv2', req),
		loadAllThingsSigma: async () =>
			await axiosInstance.get<{
				memberId: string;
				sigmaWorkbooks: SigmaWorkbook[];
				solutionFolders: SigmaFolder[];
			}>('/sigmaembed/loadallthingssigma'),
		getUser: async () =>
			await axiosInstance.get<string>('/sigmaembed/sigmaUser'),
		workbookEmbeds: async (req) =>
			await axiosInstance.post<SigmaWorkbook[]>(
				'/sigmaembed/workbookembeds',
				req,
			),
		createReport: async (req) =>
			await axiosInstance.post<SigmaWorkbook>('/sigmaembed/createreport', req),
		sigmaUrl: async (req) =>
			await axiosInstance.post<string>('/sigmaembed/sigmaurl', req),
		getFolders: async (req) =>
			await axiosInstance.post<SigmaFolder[]>('/sigmaembed/getfolders', req),
		createFolder: async (req) =>
			await axiosInstance.post<SigmaFolder>('/sigmaembed/createfolder', req),
		deleteFile: async (req) =>
			await axiosInstance.post<boolean>('/sigmaembed/deletefile', req),
		shareFile: async (req) =>
			await axiosInstance.post<SigmaWorkbook>('/sigmaembed/sharefile', req),
		editFile: async (req) =>
			await axiosInstance.post<SigmaFileEntry>('/sigmaembed/editfile', req),
		recentlyDeleted: async (req) =>
			await axiosInstance.post<string>('/sigmaembed/recentlydeleted', req),
		editFolder: async (req) =>
			await axiosInstance.post<SigmaFileEntry>('/sigmaembed/editfolder', req),
		moveWorkbook: async (req) =>
			await axiosInstance.post<SigmaWorkbook>('/sigmaembed/moveworkbook', req),
		getDashboards: async (req) =>
			await axiosInstance.get<T4DataResponse2<SigmaDashboard[]>>(
				'/sigmaembed/dashboards',
				{
					params: req,
				},
			),
		createDashboard: async (req) =>
			await axiosInstance.post<T4DataResponse2<SigmaDashboard>>(
				'/sigmaembed/dashboards',
				req,
			),
		updateDashboard: async (input) =>
			await axiosInstance.post<T4DataResponse2<SigmaDashboard>>(
				`/sigmaembed/dashboards/${input.id}`,
				input.req,
			),
		getFolderItems: async (req) =>
			await axiosInstance.get<SigmaFolderItem[]>('/sigmaembed/folderItems', {
				params: req,
			}),
		createFolderItems: async (req) =>
			await axiosInstance.post<SigmaFolderItem[]>(
				'/sigmaembed/folderItems',
				req,
			),
		updateFolderItems: async (req) =>
			await axiosInstance.post<SigmaFolderItem[]>(
				'/sigmaembed/updateFolderItems',
				req,
			),
		getControls: async (req) =>
			await axiosInstance.get<WidgetControl[]>('/sigmaembed/workbookControls', {
				params: req,
			}),
		getDataRefreshes: async () =>
			await axiosInstance.get<T4DataResponse2<SigmaDataRefresh[]>>(
				'/sigmaembed/data/refreshes',
			),
		createDataRefresh: async () =>
			await axiosInstance.post<T4DataResponse2<SigmaDataRefresh>>(
				'/sigmaembed/data/refreshes',
			),
		getDataRefresh: async (req) =>
			await axiosInstance.get<T4DataResponse2<SigmaDataRefresh>>(
				`/sigmaembed/data/refreshes/${req.id}`,
			),
	};
}
