import { ExpandMore } from '@mui/icons-material';
import {
	Box,
	Button,
	CircularProgress,
	Collapse,
	Divider,
	Grid,
	Typography,
} from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import {
	PaymentTemplate,
	PaymentTemplateChange,
	PaymentTemplateStatusTypes,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import { T4DataResponse2, T4ProblemDetails } from 'modules/clients/types';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { dateTimeReadFormat, formatDate } from 'shared/utilities/dateUtilities';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';

export const TemplateHistoryTab: FC<{
	template: PaymentTemplate | undefined;
}> = ({ template }) => {
	const { enqueueSnackbar } = useSnackbar();
	const { applicationApiClient } = useClients();

	const history = useMemo(
		() =>
			template?.statusHistory
				.filter(
					(x) =>
						x.paymentTemplateStatusType !==
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Archived],
				)
				.sort((a, b) => {
					return moment(b.createdOn).diff(moment(a.createdOn));
				}) ?? [],
		[template],
	);

	const [isComparisonLoading, setIsComparisonLoading] = useState<{
		[key: string]: boolean;
	}>({});
	const [isComparisonOpen, setIsComparisonOpen] = useState<{
		[key: string]: boolean;
	}>({});
	const [versionDiffs, setVersionDiffs] = useState<{
		[key: string]: PaymentTemplateChange[];
	}>({});

	const getKey = useCallback(
		(version1: number, version2: number) =>
			`${template?.id}-${version1}-${version2}`,
		[template],
	);

	const getTemplateVersionComparison = useCallback(
		async (version1: number, version2: number) => {
			if (template === undefined) return false;
			const key = getKey(version1, version2);

			try {
				setIsComparisonLoading((prev) => ({
					...prev,
					[key]: true,
				}));
				const response =
					await applicationApiClient.payments4.paymentTemplates.getVersionComparison(
						{ id: template.id, version1, version2 },
					);
				if (response.status === 200 && response.data) {
					setVersionDiffs((prev) => ({
						...prev,
						[key]: (response.data as T4DataResponse2<PaymentTemplateChange[]>)
							.data,
					}));
					return true;
				} else if (response.status === 404) {
					const errors = flattenProblemDetails(
						response.data as T4ProblemDetails,
					);
					if (!errors[0]) throw new Error();
					enqueueSnackbar(errors[0], {
						variant: 'error',
					});
				}
				throw new Error();
			} catch (error: any) {
				enqueueSnackbar(
					'Unable to retrieve template version comparison. Please try again later.',
					{
						variant: 'error',
					},
				);
			} finally {
				setIsComparisonLoading((prev) => ({
					...prev,
					[key]: false,
				}));
			}

			return false;
		},
		[template, getKey, enqueueSnackbar, applicationApiClient],
	);

	const openComparisonAlert = useCallback(
		async (oldVersion: number, newVersion: number) => {
			if (versionDiffs[getKey(oldVersion, newVersion)] === undefined) {
				if (await getTemplateVersionComparison(oldVersion, newVersion))
					setIsComparisonOpen((prev) => ({
						...prev,
						[getKey(oldVersion, newVersion)]: true,
					}));
			} else
				setIsComparisonOpen((prev) => ({
					...prev,
					[getKey(oldVersion, newVersion)]: true,
				}));
		},
		[versionDiffs, getKey, getTemplateVersionComparison],
	);

	const closeComparisonAlert = useCallback(
		(oldVersion: number, newVersion: number) => {
			setIsComparisonOpen((prev) => ({
				...prev,
				[getKey(oldVersion, newVersion)]: false,
			}));
		},
		[getKey],
	);

	return (
		<div role="tabpanel">
			<Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
				{history.map((value, index) => {
					const oldVersion =
						value.paymentTemplateStatusType ===
						PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Approved]
							? history.find(
									(x, indx) =>
										indx > index &&
										x.paymentTemplateStatusType ===
											PaymentTemplateStatusTypes[
												PaymentTemplateStatusTypes.Approved
											],
							  )?.templateVersion
							: undefined;
					const versionKey = !!oldVersion
						? getKey(oldVersion, value.templateVersion)
						: undefined;

					return (
						<Grid
							key={index}
							item
							xs={12}
							sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
						>
							<Typography variant="body1">
								{formatDate(
									moment(value.createdOn),
									dateTimeReadFormat,
								)?.toUpperCase()}
							</Typography>
							<Typography variant="body1" fontWeight={500}>
								{value.paymentTemplateStatusType ===
								PaymentTemplateStatusTypes[PaymentTemplateStatusTypes.Draft]
									? 'Updated - Pending Approval'
									: value.paymentTemplateStatusType}
							</Typography>
							<Typography variant="body1">
								{value.createdBy?.email ?? value.createdBy?.userId}
							</Typography>
							{value.paymentTemplateStatusType ===
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Approved
								] &&
								!!oldVersion &&
								!!versionKey && (
									<Grid container item xs={12}>
										<TemplateVersionDiffs
											isOpen={isComparisonOpen[versionKey] ?? false}
											isLoading={isComparisonLoading[versionKey] ?? false}
											open={openComparisonAlert}
											close={closeComparisonAlert}
											versionKey={versionKey}
											oldVersion={oldVersion}
											newVersion={value.templateVersion}
											diffs={versionDiffs[versionKey]}
										/>
									</Grid>
								)}
							{value?.paymentTemplateStatusType ===
								PaymentTemplateStatusTypes[
									PaymentTemplateStatusTypes.Rejected
								] && (
								<Grid container item xs={12}>
									<T4Alert
										severity="error"
										sx={{
											'&.MuiPaper-root': {
												height: '100%',
												width: '100%!important',
											},
										}}
									>
										<Box
											sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}
										>
											<Typography variant="body2" fontWeight={500}>
												Rejection Reason
											</Typography>
											<Typography variant="caption">{value.reason}</Typography>
										</Box>
									</T4Alert>
								</Grid>
							)}

							{index !== history.length - 1 && (
								<Divider sx={{ paddingTop: '0.5rem' }} />
							)}
						</Grid>
					);
				})}
			</Grid>
		</div>
	);
};

const TemplateVersionDiffs: FC<{
	isOpen: boolean;
	isLoading: boolean;
	open: (version1: number, version2: number) => Promise<void>;
	close: (version1: number, version2: number) => void;
	versionKey: string;
	oldVersion: number;
	newVersion: number;
	diffs: PaymentTemplateChange[] | undefined;
}> = ({
	isOpen,
	isLoading,
	open,
	close,
	versionKey,
	oldVersion,
	newVersion,
	diffs,
}) => {
	return (
		<Box>
			<Button
				onClick={() => {
					if (!isOpen) open(oldVersion, newVersion);
					else close(oldVersion, newVersion);
				}}
				endIcon={
					isLoading ? (
						<CircularProgress size="18px" color="secondary" />
					) : (
						<ExpandMore
							sx={{
								transition: 'rotate .25s',
								rotate: isOpen ? '180deg' : '0deg',
							}}
						/>
					)
				}
				variant="text"
				color="secondary"
				sx={{ display: 'flex', justifyContent: 'center' }}
			>
				{isOpen ? 'Hide Changes' : 'View Changes'}
			</Button>
			<Collapse in={isOpen}>
				{!oldVersion || !newVersion || !diffs ? (
					<Typography variant="body1">
						Unable to view template changes.
					</Typography>
				) : (
					<Grid container sx={{ gap: 1, paddingX: '1rem', paddingY: '0.5rem' }}>
						{diffs?.map((value, index) => (
							<Grid container item xs={12} key={index} columnSpacing={1}>
								<Grid item xs={12}>
									<Typography variant="body2" sx={{ fontStyle: 'italic' }}>
										{value.propertyName}:
									</Typography>
								</Grid>
								<ul style={{ margin: 0 }}>
									<li>
										<Typography variant="body2">
											Before: {value.oldValue}
										</Typography>
									</li>
									<li>
										<Typography variant="body2">
											After: {value.newValue}
										</Typography>
									</li>
								</ul>
							</Grid>
						))}
					</Grid>
				)}
			</Collapse>
		</Box>
	);
};
