import {
	GetProjectedTransactionsRequest,
	ProjectedTransaction,
} from 'modules/clients/customer-api/src/api/cash4';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useT4Parameter } from '../_shared/_utilities/useT4Parameter';
import { useT4Query, UseT4QueryProps } from '../_shared/_utilities/useT4Query';

export type UseProjectedTransactionsQueryProps = Pick<
	UseT4QueryProps<ProjectedTransaction[]>,
	'loading' | 'refetch'
> & {
	data: ProjectedTransaction[];
};

export function useProjectedTransactionsQuery(
	request?: GetProjectedTransactionsRequest,
	configuredReportingCurrencyCode?: string,
): UseProjectedTransactionsQueryProps {
	const { customerApiClient } = useClients();
	const { property: requestActual } = useT4Parameter(request);

	const query = useCallback(async () => {
		const response = await customerApiClient.api.cash4.projected(requestActual);

		const projectedTransactions = response?.data?.data || [];

		projectedTransactions.forEach(
			(pt) =>
				(pt.amount.reportingCurrencyCode = configuredReportingCurrencyCode),
		);

		return projectedTransactions;
	}, [
		customerApiClient.api.cash4,
		requestActual,
		configuredReportingCurrencyCode,
	]);

	const { loading, data, refetch } = useT4Query(query);

	return {
		loading: loading,
		data: data ?? [],
		refetch: refetch,
	};
}
