import { useAuth0 } from '@auth0/auth0-react';
import { useMemo } from 'react';
import { useApplicationConfiguration } from './useApplicationConfigurations';

export type UseApplicationProps = {
	isLoading: boolean;
	isAuthenticated: boolean;
	error: Error | undefined;
};

export function useApplication(): UseApplicationProps {
	const { loading: loadingApplicationConfiguration } =
		useApplicationConfiguration();
	const authContext = useAuth0();

	const isLoading = useMemo(
		() =>
			authContext.isLoading ||
			!authContext.user ||
			loadingApplicationConfiguration,
		[authContext.isLoading, authContext.user, loadingApplicationConfiguration],
	);

	return {
		isLoading,
		isAuthenticated: authContext.isAuthenticated,
		error: authContext.error,
	};
}
