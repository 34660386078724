import { DateRange } from '@mui/x-date-pickers-pro';
import { format } from 'date-fns';
import moment, { Moment } from 'moment';
import 'moment-timezone';

export type DateInput = Moment | string | null | undefined;

// Date Formats
export const ISO8601Format = 'YYYY-MM-DDTHH:mm:ss.sssZ';

// READ OPS
export const dateReadFormat = 'DD-MMM-YYYY';
export const dateTimeReadFormat = 'DD-MMM-YYYY h:mm:ss A';
export const formatReadDate = (
	date: Moment | null | undefined,
	format: string = dateReadFormat,
): string | undefined => {
	if (date === null || date === undefined) {
		return undefined;
	}

	return date.format(format).toUpperCase();
};

export const areDatesEqual = (
	date1: Moment | null | undefined,
	date2: Moment | null | undefined,
): boolean => {
	if (
		(date1 === null || date1 === undefined) &&
		(date2 === null || date2 === undefined)
	) {
		return true;
	}

	if (date1 && date2) {
		return formatReadDate(date1) === formatReadDate(date2);
	}

	return date1 === date2;
};

// WRITE OPS
export const dateWriteFormat = 'YYYY-MM-DD';
export const formatDate = (
	date: Moment | null | undefined,
	format: string = dateWriteFormat,
): string | undefined => {
	if (date === null || date === undefined) {
		return undefined;
	}

	return date.format(format);
};
export function toUtc(
	date: Moment | null | undefined,
	format: string = dateWriteFormat,
) {
	if (date === null || date === undefined) {
		return undefined;
	}

	return formatDate(moment.utc(date), format);
}

export const convertDate = (date: DateInput) => {
	return date ? moment(date) : null;
};

export const convertTime = (time: DateInput): Moment | null => {
	return time ? moment(time, [moment.HTML5_FMT.TIME_SECONDS]) : null;
};

/**
 * @see {@link DateInput}
 */
export const shouldSaveDate = (
	previousDate: DateInput,
	newDate: DateInput,
): boolean => {
	const convertedPreviousDate = convertDate(previousDate);
	const convertedNewDate = convertDate(newDate);

	if (convertedNewDate !== null && !convertedNewDate.isValid()) {
		return false;
	}

	return (
		(convertedPreviousDate !== null &&
			convertedNewDate !== null &&
			!convertedPreviousDate.isSame(convertedNewDate)) ||
		(convertedPreviousDate === null &&
			convertedNewDate !== null &&
			convertedNewDate.isValid()) ||
		(convertedPreviousDate !== null && convertedNewDate === null)
	);
};

export const formatDateSimpleUpperCase = (dateString: string): string => {
	const date = new Date(dateString);
	return format(date, 'dd-MMM-yyyy').toUpperCase();
};

export const dateTimeWriteFormat = 'DD-MMM-YYYY h:mm:ss A';
export const formatDateTime = (
	date: Moment | string | null | undefined,
	format: string = dateTimeWriteFormat,
): string | undefined => {
	var value = convertDate(date);

	if (value === null || value === undefined) return undefined;
	if (value.year() >= 9999) return '';

	return moment
		.utc(value)
		.tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
		.format(format)
		.toUpperCase();
};

export function sortDates(
	a: Moment | string | null | undefined,
	b: Moment | string | null | undefined,
) {
	const dateA = convertDate(a);
	const dateB = convertDate(b);
	return dateA?.diff(dateB) ?? 1;
}

export function dateRangeToQueryParam(
	queryParams: URLSearchParams,
	dateRange: DateRange<Moment>,
): URLSearchParams {
	if (isValidDateRange(dateRange)) {
		const formattedStartDate = formatDate(dateRange[0]);
		if (formattedStartDate) {
			queryParams.append('startDate', formattedStartDate);
		}

		const formattedEndDate = formatDate(dateRange[1]);
		if (formattedEndDate) {
			queryParams.append('endDate', formattedEndDate);
		}
	}

	return queryParams;
}

export const isValidDateRange = ([startDate, endDate]: DateRange<Moment>) => {
	const isValid =
		moment(startDate) &&
		moment(endDate) &&
		moment(startDate).isSameOrBefore(endDate)
			? true
			: false;

	return isValid;
};
