import { Error } from '@mui/icons-material';
import { CircularProgress, Grid, Tooltip } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { SigmaElement } from 'modules/clients/customer-api/src/sigmaEmbed';
import { FC, useEffect, useRef, useState } from 'react';
import { useEmbedUrl } from '../_hooks/UseEmbedUrl';

export type SigmaEmbedProps = {
	sigmaVisual: SigmaElement;
};

export const SigmaEmbed: FC<SigmaEmbedProps> = observer(({ sigmaVisual }) => {
	const {
		isErrored: urlHasErrored,
		url,
		getEmbedUrl,
	} = useEmbedUrl(sigmaVisual);

	const ref = useRef<HTMLIFrameElement>(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isErrored, setIsErrored] = useState(false);
	const [isGettingUrl, setIsGettingUrl] = useState(false);

	useEffect(() => {
		if (urlHasErrored) {
			setIsLoading(false);
			setIsErrored(true);
		}
	}, [urlHasErrored]);

	useEffect(() => {
		if (!isGettingUrl && !url) {
			setIsGettingUrl(true);
			getEmbedUrl().finally(() => {
				setIsGettingUrl(false);
			});
		}
	}, [getEmbedUrl, isGettingUrl, url]);

	useEffect(() => {
		const messageHandler = (event: MessageEvent<any>) => {
			if (event.source === ref.current?.contentWindow) {
				switch (event.data.type) {
					case 'workbook:error': {
						if (!isGettingUrl) {
							setIsGettingUrl(true);
							getEmbedUrl().finally(() => {
								setIsGettingUrl(false);
							});
						}
						break;
					}

					case 'workbook:chart:error': {
						setIsErrored(true);
						break;
					}

					case 'workbook:loaded': {
						setIsLoading(false);
					}
				}
			}
		};
		window.addEventListener('message', messageHandler);

		return () => {
			window.removeEventListener('message', messageHandler);
		};
	}, [getEmbedUrl, isGettingUrl]);

	return (
		<>
			{isLoading && (
				<Grid
					container
					item
					xs="auto"
					sx={{
						height: '100%',
						width: '100%',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<CircularProgress />
				</Grid>
			)}
			{isErrored && (
				<Grid
					container
					item
					xs="auto"
					sx={{
						height: '100%',
						width: '100%',
						justifyContent: 'center',
						alignContent: 'center',
					}}
				>
					<Tooltip title={`Visual: ${sigmaVisual.name}, cannot be loaded.`}>
						<Error
							color="error"
							sx={{
								height: '5rem',
								width: '5rem',
							}}
						/>
					</Tooltip>
				</Grid>
			)}

			<iframe
				ref={ref}
				src={url}
				loading="eager"
				scrolling="no"
				title={sigmaVisual.name}
				id={`${sigmaVisual.workbookId}-${sigmaVisual.elementId}`}
				style={{
					height: '100%',
					width: '100%',
					border: 'none',
				}}
			/>
		</>
	);
});
