import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Collapse, Divider, Grid, Typography } from '@mui/material';
import { QueryObserverResult, useQuery } from '@tanstack/react-query';
import { EntityTypeId } from 'features/entity4/entity4Constants';
import { T4Autocomplete } from 'features/entity4/shared/components/atoms/t4Autocomplete';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { T4AlertStack } from 'features/entity4/shared/components/molecules/t4AlertStack';
import { Account } from 'modules/clients/apiGateway/entity4/accounts';
import { Counterparty } from 'modules/clients/apiGateway/entity4/counterparties';
import { LegalEntity } from 'modules/clients/apiGateway/entity4/legalentities';
import { Partner } from 'modules/clients/apiGateway/entity4/partners';
import { FinancialInstitution } from 'modules/clients/apiGateway/financialInstitutions';
import {
	CreatePaymentTemplateRequest,
	PaymentTemplate,
} from 'modules/clients/apiGateway/payments4/paymentTemplates';
import {
	PaymentPriorityTypes,
	PaymentTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { T4DataResponse2, T4ProblemDetails } from 'modules/clients/types';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import {
	DrawerCancelButton,
	DrawerSubmitButton,
} from 'shared/components/drawer/drawerButtons';
import { CancellationModal } from 'shared/components/CancellationModal';
import { useClients } from 'shared/hooks/useClients';
import { stonlyData } from 'stonly/functions';
import { flattenProblemDetails } from 'utilities/errors/errorUtils';
import { trimStringsInObject } from 'utilities/objectUtils';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';
import { PaymentBankAndAccountInformationBox } from '../paymentPartyInformationBox';
import { TemplatePartyErrorTooltip } from './templatePartyErrorComponents';
import { usePaymentTemplateFormDataFetchers } from './usePaymentTemplateFormDataFetchers';
import {
	CounterpartyValidator,
	CounterpartyWithAccountsValidator,
	CreatePaymentTemplateFormValidator,
	InitiatorAccountValidator,
	LegalEntityValidator,
	PartnerValidator,
	PayeeAccountValidator,
	TemplateValidatorOptions,
} from './validators';
import {
	CounterpartyWithAccounts,
	groupAccountsByCounterparties,
	isCounterpartyWirePaymentEnabled,
	PayeeTypes,
} from './utilities';

const stonlyIds = {
	submitButton: 'create-payment-template-submit-button',
	cancelButton: 'create-payment-template-cancel-button',
	detailsSection: 'create-payment-template-details-section',
	initiatorSection: 'create-payment-template-initiator-section',
	payeeSection: 'create-payment-template-payee-section',
	currencyAndReferenceDataSection:
		'create-payment-template-currency-referencedata-section',
};

export type CreatePaymentTemplateForm = {
	name: string;
	paymentType: string;
	priorityType: string;
	currencyCode: string;
	referenceData: string | null;
	initiator: LegalEntity | null;
	initiatorBank: CounterpartyWithAccounts | null;
	initiatorAccount: Account | null;
	payeeType: PayeeTypes;
	payee: LegalEntity | Partner | Counterparty | null;
	payeeBank: CounterpartyWithAccounts | null;
	payeeAccount: Account | null;
};

const defaultCreatePaymentTemplateRequest: CreatePaymentTemplateForm = {
	name: '',
	paymentType: PaymentTypes[PaymentTypes.Wire],
	priorityType: PaymentPriorityTypes[PaymentPriorityTypes.Urgp],
	currencyCode: 'USD',
	referenceData: null,
	initiator: null,
	initiatorBank: null,
	initiatorAccount: null,
	payeeType: 'Entity',
	payee: null,
	payeeBank: null,
	payeeAccount: null,
};

type CreateTemplateDrawerProps = {
	isOpen: boolean;
	onClose: () => void;
	refetch: () => Promise<QueryObserverResult<PaymentTemplate[], Error>>;
	currencyCodes: string[];
};

export const CreateTemplateDrawer: FC<CreateTemplateDrawerProps> = ({
	isOpen,
	onClose,
	refetch,
	currencyCodes,
}) => {
	// #region State

	const { applicationApiClient } = useClients();
	const { getObjectsAsync, getValidAccountsOwnedByObjectAsync } =
		usePaymentTemplateFormDataFetchers();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [errors, setErrors] = useState<string[]>([]);
	const errorsRef = useRef<HTMLDivElement>(null);

	const { handleSubmit, control, reset, formState, watch, setValue } =
		useForm<CreatePaymentTemplateForm>({
			defaultValues: defaultCreatePaymentTemplateRequest,
			resolver: yupResolver(CreatePaymentTemplateFormValidator),
		});

	const [isCancellationModalOpen, setIsCancellationModalOpen] =
		useState<boolean>(false);

	const resetDrawer = useCallback(() => {
		setIsLoading(false);
		setErrors([]);
		reset(defaultCreatePaymentTemplateRequest);
	}, [reset]);

	const selectedInitiator = watch('initiator');
	const selectedInitiatorBank = watch('initiatorBank');
	const selectedInitiatorAccount = watch('initiatorAccount');
	const payeeType = watch('payeeType');
	const selectedPayee = watch('payee');
	const selectedPayeeBank = watch('payeeBank');
	const selectedPayeeAccount = watch('payeeAccount');

	// #region Supporting Data

	const {
		isLoading: areFinancialInstitutionsLoading,
		isFetching: areFinancialInstitutionsFetching,
		data: financialInstitutions,
		error: loadingFinancialInstitutionsError,
	} = useQuery<FinancialInstitution[], Error>(
		['payees', 'FinancialInstitution'],
		async () => {
			const response =
				await applicationApiClient.financialInstitutions.getAll();
			if (response.status === 200 && response.data)
				return (response.data as T4DataResponse2<FinancialInstitution[]>).data;
			else throw new Error();
		},
		{
			refetchOnWindowFocus: false,
			enabled: isOpen,
		},
	);
	useEffect(() => {
		if (loadingFinancialInstitutionsError) {
			onClose();
			resetDrawer();
			enqueueSnackbar(
				'Unable to load financial institutions to validate template. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [
		loadingFinancialInstitutionsError,
		resetDrawer,
		onClose,
		enqueueSnackbar,
	]);

	const {
		isLoading: areInitiatorsLoading,
		isFetching: areInitiatorsFetching,
		data: initiators,
		error: loadingInitiatorsError,
	} = useQuery<LegalEntity[], Error>(
		['Entity'],
		async () => await getObjectsAsync('Entity'),
		{
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
			enabled: isOpen,
		},
	);
	useEffect(() => {
		if (loadingInitiatorsError) {
			enqueueSnackbar(
				'Unable to load payment initiators. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [loadingInitiatorsError, enqueueSnackbar]);

	const {
		isLoading: arePayeesLoading,
		isFetching: arePayeesFetching,
		data: payees,
		error: loadingPayeesError,
	} = useQuery<LegalEntity[] | Partner[] | Counterparty[], Error>(
		[payeeType],
		async () => await getObjectsAsync(payeeType),
		{
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
			enabled: isOpen,
		},
	);
	useEffect(() => {
		if (loadingPayeesError) {
			enqueueSnackbar(
				'Unable to load payment payees. Please try again later.',
				{
					variant: 'error',
				},
			);
		}
	}, [loadingPayeesError, enqueueSnackbar]);

	const {
		isFetching: areInitiatorCounterpartiesFetching,
		data: initiatorCounterparties,
		error: loadingInitiatorCounterpartiesError,
	} = useQuery<CounterpartyWithAccounts[], Error>(
		['initiatorCounterparties', selectedInitiator?.id],
		async () => {
			const response = await getValidAccountsOwnedByObjectAsync(
				selectedInitiator!,
			);
			return groupAccountsByCounterparties(response);
		},
		{
			enabled: isOpen && selectedInitiator !== null,
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
		},
	);
	useEffect(() => {
		if (loadingInitiatorCounterpartiesError) {
			enqueueSnackbar(
				`Unable to load bank information for ${
					selectedInitiator?.anglicizedLegalName ??
					selectedInitiator?.code ??
					'Initiator'
				}. Please try again later.`,
				{
					variant: 'error',
				},
			);
		}
	}, [loadingInitiatorCounterpartiesError, selectedInitiator, enqueueSnackbar]);

	const {
		isFetching: arePayeeCounterpartiesFetching,
		data: payeeCounterparties,
		error: loadingPayeeCounterpartiesError,
	} = useQuery<CounterpartyWithAccounts[], Error>(
		['payeeCounterparties', selectedPayee?.id],
		async () => {
			const response = await getValidAccountsOwnedByObjectAsync(selectedPayee!);
			return groupAccountsByCounterparties(response);
		},
		{
			enabled: isOpen && selectedPayee !== null,
			staleTime: 60000, // 1 min
			refetchOnWindowFocus: false,
		},
	);
	useEffect(() => {
		if (loadingPayeeCounterpartiesError) {
			enqueueSnackbar(
				`Unable to load bank information for ${
					selectedPayee?.displayName ?? 'Payee'
				}. Please try again later.`,
				{
					variant: 'error',
				},
			);
		}
	}, [loadingPayeeCounterpartiesError, selectedPayee, enqueueSnackbar]);

	// #endregion

	// #region Submit Handler

	const onSubmit = useCallback(
		async (data: CreatePaymentTemplateForm) => {
			try {
				setErrors([]);
				setIsLoading(true);

				const getEntityTypeId = (type: PayeeTypes) => {
					if (type === 'Entity') return EntityTypeId.InternalEntity;
					else if (type === 'Partner') return EntityTypeId.Partner;
					else return EntityTypeId.Counterparty;
				};

				const request = {
					name: data.name,
					paymentType: data.paymentType,
					priorityType: data.priorityType,
					initiator: {
						entityId: data.initiator!.id,
						partyType: EntityTypeId.InternalEntity,
						name: data.initiator!.anglicizedLegalName,
						displayName: data.initiator!.displayName,
						countryCode: data.initiator!.operatingAddress!.countryCode,
						accountId: data.initiatorAccount!.id,
						accountNumber: data.initiatorAccount!.accountNumbers.pain001,
						accountName: isStringUndefinedOrNullOrWhitespace(
							data.initiatorAccount!.shortName,
						)
							? null
							: data.initiatorAccount!.shortName,
						accountDisplayName: data.initiatorAccount!.displayName,
						bank: {
							entityId: data.initiatorBank!.id,
							name: data.initiatorBank!.anglicizedLegalName,
							displayName: data.initiatorBank!.displayName,
							bankCode: data.initiatorBank!.bankCode,
							countryCode: data.initiatorBank!.operatingAddress!.countryCode,
							aba: data.initiatorBank!.routingCodes.highValueClearing,
							bic: null,
						},
					},
					payee: {
						entityId: data.payee!.id,
						partyType: getEntityTypeId(data.payeeType),
						name: data.payee!.anglicizedLegalName,
						displayName: data.payee!.displayName,
						countryCode: data.payee!.operatingAddress!.countryCode,
						accountId: data.payeeAccount!.id,
						accountNumber:
							data.payeeAccount!.accountNumbers.naturalAccountNumber,
						accountName: isStringUndefinedOrNullOrWhitespace(
							data.payeeAccount!.shortName,
						)
							? null
							: data.payeeAccount!.shortName,
						accountDisplayName: data.payeeAccount!.displayName,
						bank: {
							entityId: data.payeeBank!.id,
							name: data.payeeBank!.anglicizedLegalName,
							displayName: data.payeeBank!.displayName,
							bankCode: data.payeeBank!.bankCode,
							countryCode: data.payeeBank!.operatingAddress?.countryCode,
							aba: data.payeeBank!.routingCodes.highValueClearing,
							bic: null,
						},
					},
					currencyCode: data.currencyCode,
					referenceData: data.referenceData,
				} as CreatePaymentTemplateRequest;

				const response =
					await applicationApiClient.payments4.paymentTemplates.create(
						trimStringsInObject(request) as CreatePaymentTemplateRequest,
					);
				if (response.status === 201 && response.data) {
					onClose();
					resetDrawer();
					refetch();
					enqueueSnackbar('Payment template created successfully.', {
						variant: 'success',
					});
				} else if (response.status === 400 && response.data) {
					setErrors(flattenProblemDetails(response.data as T4ProblemDetails));
					errorsRef?.current?.scrollIntoView({
						behavior: 'smooth',
						block: 'start',
					});
				} else throw new Error();
			} catch {
				enqueueSnackbar(
					'An unexpected error occurred and we were unable to create payment template. Please try again later.',
					{
						variant: 'error',
					},
				);
			} finally {
				setIsLoading(false);
			}
		},
		[
			applicationApiClient,
			errorsRef,
			onClose,
			resetDrawer,
			refetch,
			enqueueSnackbar,
		],
	);

	// #endregion

	// #region Memoized Values

	const paymentTypeOptions = useMemo(() => {
		return Object.keys(PaymentTypes).filter((x) => !(parseInt(x) >= 0));
	}, []);

	const InitiatorInfoBox = useMemo(
		() => (
			<PaymentBankAndAccountInformationBox
				bankCode={selectedInitiatorBank?.bankCode}
				operatingCountryCode={
					selectedInitiatorBank?.operatingAddress?.countryCode
				}
				routingCode={selectedInitiatorBank?.routingCodes.highValueClearing}
				paymentNumber={selectedInitiatorAccount?.accountNumbers.pain001}
			/>
		),
		[selectedInitiatorBank, selectedInitiatorAccount],
	);

	const PayeeInfoBox = useMemo(
		() => (
			<PaymentBankAndAccountInformationBox
				bankCode={selectedPayeeBank?.bankCode}
				operatingCountryCode={selectedPayeeBank?.operatingAddress?.countryCode}
				routingCode={selectedPayeeBank?.routingCodes.highValueClearing}
				paymentNumber={
					selectedPayeeAccount?.accountNumbers.naturalAccountNumber
				}
			/>
		),
		[selectedPayeeBank, selectedPayeeAccount],
	);

	const CancellationModalMemo = useMemo(
		() => (
			<CancellationModal
				isOpen={isCancellationModalOpen}
				resourceType="payment template"
				variant="create"
				onClose={() => setIsCancellationModalOpen(false)}
				onSubmit={() => {
					onClose();
					resetDrawer();
				}}
			/>
		),
		[isCancellationModalOpen, onClose, resetDrawer],
	);

	// #endregion

	return (
		<T4DrawerBase
			title="Create Template"
			open={isOpen}
			initializing={
				areFinancialInstitutionsLoading || areFinancialInstitutionsFetching
			}
			loading={isLoading}
			onClose={() => {
				if (formState.isDirty) setIsCancellationModalOpen(true);
				else {
					onClose();
					resetDrawer();
				}
			}}
			actions={[
				<DrawerCancelButton
					stonlyId={stonlyIds.cancelButton}
					onCancel={() => {
						if (formState.isDirty) setIsCancellationModalOpen(true);
						else {
							onClose();
							resetDrawer();
						}
					}}
				/>,
				<DrawerSubmitButton
					stonlyId={stonlyIds.submitButton}
					label="Create"
					onSubmit={handleSubmit(onSubmit)}
					disabled={!formState.isDirty || isLoading}
				/>,
			]}
			disableNavigationCollapse
		>
			<Grid container sx={{ gap: 2 }}>
				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.detailsSection })}
				>
					<Grid item xs={12}>
						<Controller
							name="name"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4TextFieldV2
									label="Template Name"
									value={value}
									onChange={onChange}
									error={!!error}
									helperText={error && error.message}
									required
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="paymentType"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<string, false, true>
									id="payment-type"
									label="Payment Type"
									options={paymentTypeOptions}
									value={value}
									onChange={(_: any, value: string) => onChange(value)}
									error={!!error}
									helperText={error && error.message}
									disableClearable
									required
									readOnly
								/>
							)}
						/>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.initiatorSection })}
				>
					<Grid item xs={12}>
						<Typography variant="h4">Initiator</Typography>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="initiator"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<LegalEntity>
									id="initiator"
									label="Initiator Entity Name"
									options={initiators ?? []}
									value={value}
									onChange={(_: any, newValue: LegalEntity | null) => {
										onChange(newValue);
										if (value?.id !== newValue?.id) {
											setValue('initiatorBank', null);
											setValue('initiatorAccount', null);
										}
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										!LegalEntityValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										try {
											LegalEntityValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This initiator cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									loading={areInitiatorsLoading || areInitiatorsFetching}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="initiatorBank"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<CounterpartyWithAccounts>
									id="initiator-bank"
									label="Initiator Counterparty"
									options={initiatorCounterparties ?? []}
									value={value}
									onChange={(
										_: any,
										newValue: CounterpartyWithAccounts | null,
									) => {
										onChange(newValue);
										if (value?.id !== newValue?.id)
											setValue('initiatorAccount', null);
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										!CounterpartyWithAccountsValidator.isValidSync(option) ||
										(!isStringUndefinedOrNullOrWhitespace(option.bankCode)
											? !isCounterpartyWirePaymentEnabled(
													option,
													financialInstitutions ?? [],
											  )
											: false)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										if (
											!isStringUndefinedOrNullOrWhitespace(option.bankCode) &&
											!isCounterpartyWirePaymentEnabled(
												option,
												financialInstitutions ?? [],
											)
										)
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This counterparty is not enabled for wire payments yet. Please contact your administrator to enable wire payments for this counterparty."
														errors={[]}
														labelBox={labelBox}
													/>
												</Box>
											);

										try {
											CounterpartyWithAccountsValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This counterparty cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									loading={areInitiatorCounterpartiesFetching}
									readOnly={selectedInitiator === null}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="initiatorAccount"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<Account>
									id="initiator-account"
									label="Initiator Account"
									options={selectedInitiatorBank?.accounts ?? []}
									value={value}
									onChange={(_: any, value: Account | null) => onChange(value)}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										selectedPayeeAccount?.id === option.id ||
										!InitiatorAccountValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										if (selectedPayeeAccount?.id === option.id)
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="Account selected as the payee account"
														errors={[]}
														labelBox={labelBox}
													/>
												</Box>
											);

										try {
											InitiatorAccountValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This account cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									readOnly={selectedInitiatorBank === null}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Collapse in={selectedInitiatorBank !== null}>
							{InitiatorInfoBox}
						</Collapse>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.payeeSection })}
				>
					<Grid item xs={12}>
						<Typography variant="h4">Payee</Typography>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="payeeType"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<PayeeTypes, false, true, false>
									id="payee-type"
									label="Payee Type"
									options={['Entity', 'Partner', 'Counterparty']}
									value={value}
									onChange={(_: any, newValue: PayeeTypes) => {
										if (newValue !== value) {
											setValue('payee', null);
											setValue('payeeBank', null);
											setValue('payeeAccount', null);
										}
										onChange(newValue);
									}}
									error={!!error}
									helperText={error && error.message}
									required
									disableClearable
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="payee"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<LegalEntity | Partner | Counterparty>
									id="payee"
									label="Payee Name"
									options={payees ?? []}
									value={value}
									onChange={(
										_: any,
										newValue: LegalEntity | Partner | Counterparty | null,
									) => {
										onChange(newValue);
										if (value?.id !== newValue?.id) {
											setValue('payeeBank', null);
											setValue('payeeAccount', null);
										}
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) => {
										switch (option.objectType) {
											case 'Entity':
												return !LegalEntityValidator.isValidSync(option);
											case 'Partner':
												return !PartnerValidator.isValidSync(option);
											case 'Counterparty':
												return !CounterpartyValidator.isValidSync(option);
											default:
												return true;
										}
									}}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										try {
											switch (option.objectType) {
												case 'Entity':
													LegalEntityValidator.validateSync(
														option,
														TemplateValidatorOptions,
													);
													break;
												case 'Partner':
													PartnerValidator.validateSync(
														option,
														TemplateValidatorOptions,
													);
													break;
												case 'Counterparty':
													CounterpartyValidator.validateSync(
														option,
														TemplateValidatorOptions,
													);
													break;
												default:
													if (ownerState.getOptionDisabled?.(option) === true)
														throw new Error();
											}
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This payee cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									loading={arePayeesLoading || arePayeesFetching}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="payeeBank"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<CounterpartyWithAccounts>
									id="payee-bank"
									label="Payee Counterparty"
									options={payeeCounterparties ?? []}
									value={value}
									onChange={(
										_: any,
										newValue: CounterpartyWithAccounts | null,
									) => {
										onChange(newValue);
										if (value?.id !== newValue?.id)
											setValue('payeeAccount', null);
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										!CounterpartyWithAccountsValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										try {
											CounterpartyWithAccountsValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This counterparty cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									loading={arePayeeCounterpartiesFetching}
									readOnly={selectedPayee === null}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="payeeAccount"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<Account>
									id="payee-account"
									label="Payee Account"
									options={selectedPayeeBank?.accounts ?? []}
									value={value}
									onChange={(_: any, value: Account | null) => onChange(value)}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									getOptionLabel={(option) => option.displayName}
									getOptionDisabled={(option) =>
										selectedInitiatorAccount?.id === option.id ||
										!PayeeAccountValidator.isValidSync(option)
									}
									renderOption={(props, option, _, ownerState) => {
										const labelBox = (
											<Box
												component="li"
												{...props}
												key={props.id ?? option.id}
											>
												{ownerState.getOptionLabel(option)}
											</Box>
										);

										if (selectedInitiatorAccount?.id === option.id)
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="Account selected as the initiator account"
														errors={[]}
														labelBox={labelBox}
													/>
												</Box>
											);

										try {
											PayeeAccountValidator.validateSync(
												option,
												TemplateValidatorOptions,
											);
											return labelBox;
										} catch (error: any) {
											return (
												<Box key={option.id}>
													<TemplatePartyErrorTooltip
														id={option.id}
														header="This account cannot be selected due to the following missing or invalid data:"
														errors={error?.errors ?? []}
														labelBox={labelBox}
													/>
												</Box>
											);
										}
									}}
									error={!!error}
									helperText={error && error.message}
									required
									readOnly={selectedPayeeBank === null}
								/>
							)}
						/>
					</Grid>

					<Grid item xs={12}>
						<Collapse in={selectedPayeeBank !== null}>{PayeeInfoBox}</Collapse>
					</Grid>
				</Grid>

				<Grid item xs={12}>
					<Divider />
				</Grid>

				<Grid
					container
					item
					xs={12}
					spacing={1}
					{...stonlyData({ id: stonlyIds.currencyAndReferenceDataSection })}
				>
					<Grid item xs={12}>
						<Typography variant="h4">Currency and Details</Typography>
					</Grid>
					<Grid item xs={6}>
						<Controller
							name="currencyCode"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4Autocomplete<string, false, true>
									id="currency-code"
									label="Payment CCY"
									options={currencyCodes.length ? currencyCodes : ['USD']}
									value={value}
									onChange={(_: any, value: string) => onChange(value)}
									error={!!error}
									helperText={error && error.message}
									disableClearable
									required
									readOnly
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="referenceData"
							control={control}
							render={({
								field: { onChange, value },
								fieldState: { error },
							}) => (
								<T4TextFieldV2
									id="reference-data"
									label="Payment Information"
									value={value ?? ''}
									onChange={(value: string) => {
										if (isStringUndefinedOrNullOrWhitespace(value))
											onChange(null);
										else onChange(value);
									}}
									minRows={4}
									maxRows={4}
									multiline
									error={!!error}
									helperText={`${value?.length ?? 0}/140${
										error?.message ? ' ' + error.message : ''
									}`}
									inputProps={{
										maxLength: 140,
									}}
								/>
							)}
						/>
					</Grid>
				</Grid>

				{Object.values(errors).length > 0 && (
					<Grid item xs={12} ref={errorsRef}>
						<T4AlertStack errors={errors} />
					</Grid>
				)}
			</Grid>

			{CancellationModalMemo}
		</T4DrawerBase>
	);
};
