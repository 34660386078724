/* eslint-disable mobx/missing-observer */
import { Tooltip, Typography } from '@mui/material';
import { FC } from 'react';
import {
	formatCurrency,
	CurrencySource,
	defaultCurrencyCode,
} from 'utilities/currencyUtils';

export type AmountCurrencyTooltipGridCellProps = {
	accountCurrencyAmount: number | null;
	accountCurrencyCode: string | null;
	reportingCurrencyAmount: number | null;
	reportingCurrencyCode: string | undefined;
	displayedCurrencyCode: CurrencySource;
};

const AmountCurrencyTooltipGridCell: FC<AmountCurrencyTooltipGridCellProps> = ({
	accountCurrencyAmount,
	accountCurrencyCode,
	reportingCurrencyAmount,
	reportingCurrencyCode,
	displayedCurrencyCode,
}) => {
	let tooltipAmount: number | null = NaN;
	let tooltipCurrency: string | null = defaultCurrencyCode;

	if (displayedCurrencyCode === CurrencySource.Account) {
		tooltipCurrency = reportingCurrencyCode || defaultCurrencyCode;
		if (
			reportingCurrencyAmount === null ||
			reportingCurrencyAmount === undefined
		) {
			if (
				accountCurrencyAmount === 0 ||
				accountCurrencyAmount === null ||
				accountCurrencyAmount === undefined
			) {
				tooltipAmount = 0;
			}
		} else {
			tooltipAmount = reportingCurrencyAmount;
		}
	} else {
		tooltipCurrency = accountCurrencyCode;
		tooltipAmount = accountCurrencyAmount || 0;
	}

	const formattedTooltipAmount = `${formatCurrency(tooltipAmount, {
		currency: tooltipCurrency || defaultCurrencyCode,
	})} ${tooltipCurrency?.toUpperCase()}`;

	const formattedTooltipTitle = isNaN(tooltipAmount)
		? 'The reporting currency is unavailable due to missing exchange rates.'
		: `${
				displayedCurrencyCode === CurrencySource.Account
					? 'Reporting'
					: 'Account'
		  } Ccy: ${formattedTooltipAmount}`;

	const formattedLabelAmount =
		displayedCurrencyCode === CurrencySource.Account
			? formatCurrency(accountCurrencyAmount, {
					currency: accountCurrencyCode || defaultCurrencyCode,
			  })
			: formatCurrency(reportingCurrencyAmount, {
					currency: reportingCurrencyCode || defaultCurrencyCode,
			  });

	return (
		<Tooltip title={formattedTooltipTitle}>
			<Typography fontSize={'inherit'}>{formattedLabelAmount}</Typography>
		</Tooltip>
	);
};

export default AmountCurrencyTooltipGridCell;
