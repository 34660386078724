import InfoIcon from '@mui/icons-material/Info';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import {
	Alert,
	Box,
	Checkbox,
	Collapse,
	Divider,
	FormControlLabel,
	Grid,
	Typography,
	useTheme,
} from '@mui/material';
import { CustomModal } from 'features/entity4/entities/components/customModal';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { observer } from 'mobx-react-lite';
import { FC, useRef } from 'react';
import { T4SecondaryButton, T4SubmitButton } from 'shared/components/buttons';
import { FileUploadBox } from 'shared/components/fileUploadBox/fileUploadBox';
import { useUser } from 'shared/hooks/useUser';
import { UploadDocumentViewModel } from '../models/uploadDocumentViewModel';

export interface UploadDocumentModalProps {
	viewModel: UploadDocumentViewModel;
}
export const UploadDocumentModal: FC<UploadDocumentModalProps> = observer(
	({ viewModel }) => {
		const theme = useTheme();
		const { name } = useUser();
		const inputRef = useRef<HTMLInputElement | null>(null);

		return (
			<CustomModal
				open={viewModel.isOpen()}
				title="Add Document"
				actions={[
					<T4SecondaryButton
						label="Cancel"
						onClick={() => viewModel.close()}
					/>,
					<T4SubmitButton
						loading={viewModel.isUploading()}
						label="Add Document"
						disabled={!viewModel.canSave()}
						onClick={() => viewModel.upload(name)}
					/>,
				]}
			>
				<Grid container rowSpacing={1}>
					{!viewModel.fileSelected() ? (
						<Grid container item xs={12} rowSpacing={1} justifyContent="center">
							<Grid item xs={viewModel.urlProvided() ? 12 : 11}>
								<Typography
									textAlign="center"
									fontSize=".9em"
									justifyContent="center"
								>
									Link to document by specifying URL
								</Typography>
								<T4TextFieldV2
									value={viewModel.getUrl()}
									onChange={(value) => viewModel.setUrl(value)}
									disabled={viewModel.isExternalUrlFieldDisabled()}
									placeholder="https://"
								/>
							</Grid>
						</Grid>
					) : null}
					{!viewModel.urlProvided() && !viewModel.fileSelected() ? (
						<Grid item xs={12}>
							<Box
								sx={{
									color: theme.palette.grey[600],
									display: 'flex',
									height: '22px',
									marginTop: '1em',
									marginBottom: '1em',
									'&::before,&::after': {
										content: '""',
										display: 'block',
										flexGrow: 1,
										height: '1px',
										backgroundColor: theme.palette.grey[600],
										position: 'relative',
										top: '50%',
										transform: 'translateY(-50%)',
									},
									'&::before': {
										marginRight: '.5em',
									},
									'&::after': {
										marginLeft: '.5em',
									},
								}}
							>
								OR
							</Box>
						</Grid>
					) : null}
					{!viewModel.urlProvided() ? (
						<Grid item xs={12} sx={{ paddingBottom: '0.5rem' }}>
							<FileUploadBox
								validation={viewModel.getDocumentValidation()}
								disabled={viewModel.getUrl() !== ''}
								onChange={() => {
									setTimeout(() => {
										inputRef?.current?.focus();
									}, 300);
								}}
								onClear={viewModel.clear}
								file={viewModel.getFile()}
								setFile={viewModel.setFile}
							/>
						</Grid>
					) : (
						<Grid item xs={12} sx={{ marginTop: '1em', marginBottom: '1em' }}>
							<Divider />
						</Grid>
					)}
					{viewModel.fileSelected() || viewModel.urlProvided() ? (
						<Grid
							container
							item
							xs={12}
							rowSpacing={1}
							sx={{ justifyContent: 'center', marginBottom: '1em' }}
						>
							<Grid item xs={12}>
								<T4TextFieldV2
									label="Document Name*"
									value={viewModel.getFileName()}
									onChange={(value) => viewModel.setName(value)}
									inputRef={(el) => (inputRef.current = el)}
								/>
							</Grid>
							<Grid item xs={11}>
								<Typography sx={{ display: 'flex' }}>
									<InfoIcon
										sx={{
											fontSize: '1.25em',
											marginRight: '.5em',
											color: theme.palette.grey[600],
										}}
									/>
									<Typography
										component="span"
										sx={{ fontSize: '.9em', color: theme.palette.grey[600] }}
									>
										Choose a Document Name to locate this file easier within
										Treasury4.
										<br />
										This can be different than the file name.
									</Typography>
								</Typography>
							</Grid>
						</Grid>
					) : null}
					<Grid item xs={12}>
						<Typography>
							<Typography component="span" sx={{ fontWeight: '800' }}>
								<WarningAmberOutlinedIcon sx={{ marginBottom: '-5px' }} />
								{'WARNING: '}
							</Typography>
							Use discretion when uploading potentially sensitive documents. The
							ability to restrict access to personally identifiable information
							(PII), or other confidential documents, is not yet supported.
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<FormControlLabel
							control={
								<Checkbox
									checked={viewModel.getMultipleDocumentUploads()}
									onChange={(_) => viewModel.toggleMultipleUploads()}
								/>
							}
							label={'Add additional documents'}
						/>
					</Grid>
					<Grid item xs={12}>
						<Collapse in={Boolean(viewModel.getError())}>
							<Alert severity="error">{viewModel.getError()}</Alert>
						</Collapse>

						<Collapse in={viewModel.isSuccessSnackbarOpen()}>
							<Alert
								onClose={viewModel.closeSuccessSnackbar}
								severity="success"
							>
								Successfully uploaded document.
							</Alert>
						</Collapse>
					</Grid>
				</Grid>
			</CustomModal>
		);
	},
);
