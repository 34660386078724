import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { FC, forwardRef } from 'react';

export type SigmaButtonProps = LoadingButtonProps;

export const SigmaButton: FC<SigmaButtonProps> = forwardRef(
	({ sx, ...rest }, ref) => (
		<LoadingButton
			{...rest}
			ref={ref}
			sx={{
				...sx,
				background: 'white',
				verticalAlign: 'center',
				'&:hover': {
					...((sx as any)?.['&:hover'] ?? {}),
					background: 'white',
				},
			}}
		/>
	),
);
