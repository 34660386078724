import { useApprovalConfigurations } from 'features/administration/approvals/fieldApprovalConfiguration/useApprovalConfigurations';
import { useProfileView } from 'features/entity4/entityProfile/providers/entityProfileContextProvider';
import { NETWORK_ERROR } from 'features/entity4/globalDocuments/pages/models/globalDocumentViewModel';
import { T4AdornmentType } from 'features/entity4/shared/components/molecules/t4FieldAdornment';
import { FieldViews } from 'features/entity4/shared/fieldSets/fieldViews/fieldViewTypes';
import { Field, Option } from 'modules/clients/customer-api/src/api/common';
import {
	Field as FieldData,
	ObjectType,
	PhoneNumber,
} from 'modules/clients/customer-api/src/api/objects';
import { T4Response } from 'modules/clients/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { useUser } from 'shared/hooks/useUser';
import { convertDate } from 'shared/utilities/dateUtilities';
import { ApiResponse } from 'utilities/api';
import { flattenErrors } from 'utilities/errors/errorUtils';
import { Collection } from '../models/collection';
import { ObjectField } from '../models/objectField';
import { T4Object as ObjectData } from '../models/t4Object';
import { useSensitiveField } from '../providers/sensitiveFieldProvider';
import {
	ViewType,
	getInitalValue,
	getObjectInputType,
	getValidationWarning,
} from '../utilities';

export type UseFieldViewProps<TReturn> = {
	isReadOnly: boolean;
	hasPermission: boolean;
	showVisibilityIcon: boolean;
	error: string | undefined;
	warning: string | undefined;
	label: string;
	adornmentState: T4AdornmentType;
	value: TReturn | undefined;
	setValue: (value: TReturn | undefined) => void;
	update: (value: TReturn | undefined) => Promise<void>;
};

export function useFieldView<TReturn>(
	object: ObjectData,
	field: Field,
	fieldViewType: ViewType,
	updateLastAutoSave: (autoSaveDate?: Date) => void,
	collection?: Collection,
): UseFieldViewProps<TReturn> {
	const { isStaffUser, isAuthor } = useUser();
	const { viewType } = useProfileView();
	const {
		isSensitive,
		hasRequestedSensitiveFieldData,
		setHasRequestedSensitiveFieldData,
	} = useSensitiveField();
	const { configuration } = useApprovalConfigurations();
	const { customerApiClient } = useClients();

	const objectField = useMemo(() => {
		if (collection) {
			return collection?.fields.find((x) => x.identifier === field.identifier);
		} else {
			return object?.fields?.find((x) => x.identifier === field.identifier);
		}
	}, [
		collection,
		field.identifier,
		object?.fields,

		// please leave this here so we can calculate the correct values
		// eslint-disable-next-line react-hooks/exhaustive-deps
		collection?.fields.find((x) => x.identifier === field.identifier),

		// please leave this here so we can calculate the correct values
		// eslint-disable-next-line react-hooks/exhaustive-deps
		object?.fields?.find((x) => x.identifier === field.identifier),
	]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [hasUpdated, setHasUpdated] = useState<boolean | undefined>();
	const [error, setError] = useState<string | undefined>();
	const [value, setValue] = useState(() =>
		getInitalValue<TReturn>(objectField, field, fieldViewType, configuration),
	);
	const hasPermission = useMemo<boolean>(() => {
		if (field.isPII) {
			return isStaffUser;
		}

		return true;
	}, [field.isPII, isStaffUser]);
	const warning = useMemo(
		() =>
			objectField
				? getValidationWarning(objectField, field, fieldViewType)
				: undefined,

		// please leave this disable so we can caluate values
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[
			field,
			fieldViewType,
			objectField,
			objectField?.approvedValue,
			objectField?.pendingValue,
		],
	);
	const label = useMemo(
		() =>
			`${
				field.requiresApproval &&
				configuration.isActive &&
				fieldViewType === 'approved' &&
				viewType !== FieldViews.default
					? 'Approved '
					: ''
			}${field.name}`,
		[
			configuration.isActive,
			field.name,
			field.requiresApproval,
			fieldViewType,
			viewType,
		],
	);
	const adornmentState = useMemo<T4AdornmentType>(
		() => (isLoading ? 'loading' : hasUpdated === true ? 'success' : 'info'),
		[hasUpdated, isLoading],
	);
	const isReadOnly = useMemo<boolean>(
		() =>
			(field.requiresApproval &&
				configuration.isActive &&
				fieldViewType === 'approved') ||
			viewType !== FieldViews.edit ||
			!isAuthor,
		[
			configuration.isActive,
			field.requiresApproval,
			fieldViewType,
			isAuthor,
			viewType,
		],
	);
	const showVisibilityIcon = useMemo(() => {
		if (isSensitive) {
			if (viewType === FieldViews.default && fieldViewType === 'approved') {
				return true;
			}

			return (
				(fieldViewType === 'approved' && !field.requiresApproval) ||
				fieldViewType === 'pending'
			);
		}

		return false;
	}, [field.requiresApproval, fieldViewType, isSensitive, viewType]);

	const get = useCallback(async () => {
		if (objectField) {
			try {
				if (object.objectType !== ObjectType.subaccount) {
					setIsLoading(true);
					setError(undefined);

					const response = await customerApiClient.api.objects.fields.get({
						objectType: getObjectInputType(object.objectType!),
						objectId: object.id!,
						fieldId: objectField.id,
					});

					if (
						response &&
						response.data &&
						response.data.value &&
						response.data.value
					) {
						objectField.update(response.data.value);
						setValue(
							getInitalValue<TReturn>(
								objectField,
								field,
								fieldViewType,
								configuration,
							),
						);
						setHasRequestedSensitiveFieldData(true);
					} else {
						setError('Unable to get field data.');
					}
				} else {
					setError('Subaccount sensitive fields are not supported.');
				}
			} catch (error: any) {
				setError(
					error?.message ?? error?.toString() ?? 'Unable to get field data.',
				);
			} finally {
				setIsLoading(false);
			}
		}
	}, [
		configuration,
		customerApiClient.api.objects.fields,
		field,
		fieldViewType,
		object.id,
		object.objectType,
		objectField,
		setHasRequestedSensitiveFieldData,
	]);

	const update = useCallback(
		async (newValue: TReturn | undefined) => {
			if (typeof newValue === 'string') {
				newValue = newValue?.trim() as TReturn;
			}
			setValue(newValue);

			const initalValue = getInitalValue<any>(
				objectField,
				field,
				fieldViewType,
				configuration,
			);

			const isTextFieldDifferent =
				(field.fieldType === 'Text' ||
					field.fieldType === 'LongText' ||
					field.fieldType === 'Boolean') &&
				initalValue !== newValue;
			const isDateFieldDifferent =
				field.fieldType === 'Date' &&
				!(
					convertDate(initalValue)?.isSame(convertDate(newValue as any)) ||
					convertDate(newValue as any)?.isSame(convertDate(initalValue))
				);
			const isOptionFieldDifferent =
				field.fieldType === 'Option' &&
				initalValue?.id !== (newValue as any)?.id;
			const isPhoneNumberFieldDifferent =
				field.fieldType === 'PhoneNumber' &&
				(initalValue?.countryCode !== (newValue as any)?.countryCode ||
					initalValue?.number !== (newValue as any)?.number ||
					initalValue?.extension !== (newValue as any)?.extension);

			if (
				object &&
				viewType === FieldViews.edit &&
				(isTextFieldDifferent ||
					isDateFieldDifferent ||
					isOptionFieldDifferent ||
					isPhoneNumberFieldDifferent)
			) {
				try {
					setIsLoading(true);
					setError(undefined);

					const isSubaccount = object.objectType === ObjectType.subaccount;
					const objectInput = isSubaccount
						? {
								objectType: getObjectInputType(object.parentObjectType!),
								objectId: object.parentObjectId!,
								subaccountId: object.id,
						  }
						: {
								objectType: getObjectInputType(object.objectType),
								objectId: object.id,
								subaccountId: undefined!,
						  };
					let response: T4Response<ApiResponse<FieldData>, any>;
					if (field.fieldType === 'PhoneNumber') {
						const requestValue = newValue as PhoneNumber;

						if (objectField) {
							if (collection) {
								const updatePhoneNumber = isSubaccount
									? customerApiClient.api.objects.subaccounts.collections.fields
											.updatePhoneNumber
									: customerApiClient.api.objects.collections.fields
											.updatePhoneNumber;
								const input = {
									collectionId: collection.id,
									fieldId: objectField.id,
									countryCode: requestValue?.countryCode ?? null,
									number: requestValue?.number ?? null,
									extension: requestValue?.extension ?? null,
									...objectInput,
								};

								response = await updatePhoneNumber(input);

								if (response && response.data && response.data.value) {
									objectField.update(response.data.value);
									setHasUpdated(true);
									updateLastAutoSave();
								}
							} else {
								const updatePhoneNumber = isSubaccount
									? customerApiClient.api.objects.subaccounts.fields
											.phoneNumberUpdate
									: customerApiClient.api.objects.fields.phoneNumberUpdate;
								const input = {
									...objectInput,
									objectType: getObjectInputType(object.objectType),
									objectId: object.id,
									fieldId: objectField.id,
									countryCode: requestValue?.countryCode ?? null,
									number: requestValue?.number ?? null,
									extension: requestValue?.extension ?? null,
								};

								response = await updatePhoneNumber(input);

								if (response && response.data && response.data.value) {
									objectField.update(response.data.value);
									setHasUpdated(true);
									updateLastAutoSave();
								}
							}
						} else {
							if (collection) {
								const createPhoneNumber = isSubaccount
									? customerApiClient.api.objects.subaccounts.collections.fields
											.createPhoneNumber
									: customerApiClient.api.objects.collections.fields
											.createPhoneNumber;
								const input = {
									...objectInput,
									collectionId: collection.id,
									fieldIdentifier: field.identifier,
									countryCode: requestValue?.countryCode ?? null,
									number: requestValue?.number ?? null,
									extension: requestValue?.extension ?? null,
								};

								response = await createPhoneNumber(input);

								if (response && response.data && response.data.value) {
									collection.fields.push(new ObjectField(response.data.value));
									setHasUpdated(true);
									updateLastAutoSave();
								}
							} else {
								const createPhoneNumber = isSubaccount
									? customerApiClient.api.objects.subaccounts.fields
											.phoneNumberCreate
									: customerApiClient.api.objects.fields.phoneNumberCreate;
								const input = {
									...objectInput,
									identifier: field.identifier,
									countryCode: requestValue?.countryCode ?? null,
									number: requestValue?.number ?? null,
									extension: requestValue?.extension ?? null,
								};

								response = await createPhoneNumber(input);

								if (response && response.data && response.data.value) {
									object.fields.push(new ObjectField(response.data.value));
									setHasUpdated(true);
									updateLastAutoSave();
								}
							}
						}
					} else {
						let requestValue = newValue;
						if (field.fieldType === 'Option') {
							requestValue = ((requestValue as Option)?.id ?? null) as any;
						}

						if (collection) {
							if (objectField) {
								const udpateField = isSubaccount
									? customerApiClient.api.objects.subaccounts.collections.fields
											.update
									: customerApiClient.api.objects.collections.fields.update;
								const input = {
									...objectInput,
									collectionId: collection.id,
									fieldId: objectField.id,
									value: requestValue ?? null,
								};

								response = await udpateField(input);
							} else {
								const createField = isSubaccount
									? customerApiClient.api.objects.subaccounts.collections.fields
											.create
									: customerApiClient.api.objects.collections.fields.create;
								const input = {
									...objectInput,
									collectionId: collection.id,
									fieldIdentifier: field.identifier,
									value: requestValue ?? null,
								};

								response = await createField(input);
							}
						} else {
							const updateField = isSubaccount
								? customerApiClient.api.objects.subaccounts.fields.update
								: customerApiClient.api.objects.fields.update;
							const input = {
								...objectInput,
								fieldIdentifier: field.identifier,
								value: requestValue ?? null,
							};

							response = await updateField(input);
						}

						if (response && response.data && response.data.value) {
							if (objectField) {
								objectField.update(response.data.value);
							} else {
								if (collection) {
									collection.fields.push(new ObjectField(response.data.value));
								} else {
									object?.fields.push(new ObjectField(response.data.value));
								}
							}
							setHasUpdated(true);
							updateLastAutoSave();
						} else {
							setValue(initalValue);
						}

						if (response && response.data && response.data.error) {
							setError(response.data.error);
							setHasUpdated(false);
						}
						if (
							response &&
							response.data &&
							response.data.errors &&
							(Object.values(response.data.errors)?.length ?? 0) > 0
						) {
							setError(
								error?.concat(flattenErrors(response.data.errors).join(', ')),
							);
							setHasUpdated(false);
						}
					}
				} catch (error: any) {
					setError(NETWORK_ERROR);
				} finally {
					setIsLoading(false);
				}
			}
		},
		[
			collection,
			configuration,
			customerApiClient.api.objects.collections.fields.create,
			customerApiClient.api.objects.collections.fields.createPhoneNumber,
			customerApiClient.api.objects.collections.fields.update,
			customerApiClient.api.objects.collections.fields.updatePhoneNumber,
			customerApiClient.api.objects.fields.phoneNumberCreate,
			customerApiClient.api.objects.fields.phoneNumberUpdate,
			customerApiClient.api.objects.fields.update,
			customerApiClient.api.objects.subaccounts.collections.fields.create,
			customerApiClient.api.objects.subaccounts.collections.fields
				.createPhoneNumber,
			customerApiClient.api.objects.subaccounts.collections.fields.update,
			customerApiClient.api.objects.subaccounts.collections.fields
				.updatePhoneNumber,
			customerApiClient.api.objects.subaccounts.fields.phoneNumberCreate,
			customerApiClient.api.objects.subaccounts.fields.phoneNumberUpdate,
			customerApiClient.api.objects.subaccounts.fields.update,
			error,
			field,
			fieldViewType,
			object,
			objectField,
			updateLastAutoSave,
			viewType,
		],
	);

	useEffect(() => {
		if (isSensitive && !hasRequestedSensitiveFieldData) {
			get();
		}
	}, [get, hasRequestedSensitiveFieldData, isSensitive]);

	useEffect(() => {
		if (objectField && fieldViewType === 'approved') {
			setValue(
				getInitalValue<TReturn>(
					objectField,
					field,
					fieldViewType,
					configuration,
				),
			);
		}
	}, [
		configuration,
		field,
		fieldViewType,
		objectField,
		// please leave this here so we can calculate the correct values
		objectField?.approvedDate,
	]);

	return {
		isReadOnly: isReadOnly,
		hasPermission: hasPermission,
		showVisibilityIcon: showVisibilityIcon,
		error: error,
		warning: warning,
		label: label,
		adornmentState: adornmentState,
		value: value,
		setValue: setValue,
		update: update,
	};
}
