import { DateRange } from '@mui/x-date-pickers-pro';
import { T4DataResponse } from 'modules/clients/types';
import { Moment } from 'moment';
import { customerApi } from 'shared/providers/customerApi';
import {
	dateRangeToQueryParam,
	isValidDateRange,
} from 'shared/utilities/dateUtilities';
import { Holdings, InvestmentTransaction } from '../models';
import { Cash4Configurations } from 'modules/clients/customer-api/src/api/administration';

const investmentsPath = 'api/v1.0/cash4/investments';
const investmentHoldingsPath = investmentsPath + '/holdings';
const investmentTransactionsPath = investmentsPath + '/transactions';

export const fetchHoldings = async (
	dateRange: DateRange<Moment>,
	configurations: Cash4Configurations | undefined,
) => {
	try {
		if (!isValidDateRange(dateRange)) return Promise.resolve([]);

		let queryParams = new URLSearchParams();
		queryParams = dateRangeToQueryParam(queryParams, dateRange);

		const classesResponse = await customerApi.get<T4DataResponse<Holdings[]>>(
			investmentHoldingsPath,
			{
				params: queryParams,
			},
		);
		if (classesResponse.data.error) throw classesResponse.data.error;
		if (classesResponse.data.data) {
			classesResponse.data.data.forEach(
				(x) =>
					(x.marketValue.reportingCurrencyCode =
						configurations?.reportingCurrencyCode),
			);
		}
		return classesResponse.data.data;
	} catch (error) {
		throw error;
	}
};

export const deleteInvestmentHolding = async (investmentHoldingId: string) => {
	try {
		const response = await customerApi.delete<T4DataResponse<string>>(
			`${investmentHoldingsPath}/${investmentHoldingId}`,
		);

		if (response.data.error) {
			throw response.data.error;
		}

		return response.data.data;
	} catch (error) {
		throw error;
	}
};

export const fetchTransactions = async (
	dateRange: DateRange<Moment>,
	configurations: Cash4Configurations | undefined,
) => {
	try {
		if (!isValidDateRange(dateRange)) return Promise.resolve([]);

		let queryParams = new URLSearchParams();
		queryParams = dateRangeToQueryParam(queryParams, dateRange);

		const classesResponse = await customerApi.get<
			T4DataResponse<InvestmentTransaction[]>
		>(investmentTransactionsPath, {
			params: queryParams,
		});

		if (classesResponse.data.data) {
			classesResponse.data.data.forEach(
				(x) =>
					(x.settlementAmount.reportingCurrencyCode =
						configurations?.reportingCurrencyCode),
			);
		}
		if (classesResponse.data.error) throw classesResponse.data.error;

		return classesResponse.data.data;
	} catch (error) {
		throw error;
	}
};

export const deleteInvestmentTransaction = async (
	investmentTransactionId: string,
) => {
	try {
		const response = await customerApi.delete<T4DataResponse<string>>(
			`${investmentTransactionsPath}/${investmentTransactionId}`,
		);

		if (response.data.error) {
			throw response.data.error;
		}

		return response.data.data;
	} catch (error) {
		throw error;
	}
};
