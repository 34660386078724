import { FC, useEffect, useState } from 'react';
import { FormModal } from 'shared/components/formModal';
import { TransactionRuleListItem } from '../models';
import { useCreateRule, useUpdateRule } from '../services';
import RuleForm from './components/RuleForm';
import { useFormContext } from './providers/FormProvider';
import { isFormValid } from './validation';
import { useDataContext } from './providers/DataProvider';
import { CancellationModal } from 'shared/components/CancellationModal';

interface RuleModalProps {
	open: boolean;
	onClose: () => void;
	rule?: TransactionRuleListItem | null | undefined;
	isEditing: boolean;
}

export const RuleModal: FC<RuleModalProps> = ({
	open,
	onClose,
	rule,
	isEditing,
}) => {
	const [cancelOpen, setCancelOpen] = useState(false);
	const [isInitialised, setIsInitialised] = useState(false);
	const { initializeRule, disposeRule, getRule } = useFormContext();
	const { isLoadingCategories, categoriesError, categories } = useDataContext();
	const handleClose = () => {
		onClose();
		setIsInitialised(false);
		disposeRule();
	};

	const {
		mutate: updateRule,
		isLoading: isLoadingUpdate,
		isError: isErrorUpdating,
	} = useUpdateRule(handleClose);

	const {
		mutate: createRule,
		isLoading: isLoadingCreate,
		isError: isErrorCreating,
	} = useCreateRule(handleClose);

	const handleCancelOpenDialog = () => {
		setCancelOpen(true);
	};

	const handleCancelCloseDialog = () => {
		setCancelOpen(false);
	};

	const handleCancel = () => {
		handleCancelCloseDialog();
		handleClose();
	};

	const handleSubmit = async () => {
		if (isEditing) {
			await updateRule(getRule());
		} else {
			await createRule(getRule());
		}
	};

	useEffect(() => {
		if (rule && open === true && !isInitialised && !isLoadingCategories()) {
			initializeRule(rule);
			setIsInitialised(true);
		}
	}, [rule, open, categories]);

	return (
		<>
			<FormModal
				isDraggable={true}
				open={open}
				onClose={handleCancelOpenDialog}
				onSubmit={handleSubmit}
				submitButtonLabel={isEditing ? 'Edit Rule' : 'Create Rule'}
				title={isEditing ? 'Edit Rule' : 'Create Rule'}
				loading={
					(isLoadingUpdate || isLoadingCreate || isLoadingCategories()) &&
					!categoriesError
				}
				error={
					categoriesError
						? 'Error fetching data.  If you continue to see this error, contact our Customer Success team at support@treasury4.com.'
						: ''
				}
				fullWidth
				maxWidth="lg"
				submitDisabled={!isFormValid(getRule())}
			>
				<RuleForm isEditing={isEditing} />
			</FormModal>
			<CancellationModal
				isOpen={cancelOpen}
				resourceType="rule"
				variant={isEditing ? 'edit' : 'create'}
				onClose={handleCancelCloseDialog}
				onSubmit={handleCancel}
			/>
		</>
	);
};
