import { useQuery } from '@tanstack/react-query';
import { CashFlowClass } from 'features/cash4/categories/categoriesViewModel';
import { GLCode, TransactionRuleRequest } from 'features/cash4/rules/models';
import { T4CustomerClient } from 'modules/clients/customer-api/src/client';
import { T4DataResponse } from 'modules/clients/types';
import { useClients } from 'shared/hooks/useClients';
import { customerApi } from 'shared/providers/customerApi';
import { ApiResponse } from 'utilities/api';
import { TransactionListItem } from '../models';
import { getTransactionListItem } from '../utilities';

export const fetchCategoriesForRules = async () => {
	try {
		const classesResponse = await customerApi.get<ApiResponse<CashFlowClass[]>>(
			'/categories?includeTransactionCount=false',
		);
		if (classesResponse.data.error) throw classesResponse.data.error;
		return classesResponse.data.value;
	} catch (error) {
		throw error;
	}
};

export const fetchGlCodes = async () => {
	try {
		const glCodesResponse = await customerApi.get<T4DataResponse<GLCode[]>>(
			'api/v1/cash4/transactionRules/glcodes',
		);
		if (!glCodesResponse.data.success) throw glCodesResponse.data.errors;
		return glCodesResponse.data.data;
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to fetch GL codes', error);
		throw error;
	}
};

export const saveManualCategorization = async (
	transactionRuleRequest: TransactionRuleRequest,
): Promise<void> => {
	try {
		// Save request
		await customerApi
			.post(`/api/v1/cash4/transactionRules`, transactionRuleRequest)
			.then((response) => {
				const data = response.data as ApiResponse<string>;
				if (data.success) {
					return data.value;
				} else if (data.error) {
					throw data.error;
				} else {
					throw new Error('Failed to categorize transaction');
				}
			});
	} catch (error) {
		throw error;
	}
};

export const deleteRule = async (ruleId: string) => {
	try {
		await customerApi.delete(`/api/v1/cash4/transactionRules/${ruleId}`);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error('Failed to delete rule', error);
		throw error;
	}
};

export const fetchTransaction = async (
	transactionId: string,
	customerApiClient: T4CustomerClient,
) => {
	if (transactionId) {
		const response = await customerApiClient.transactions.getTransaction(
			transactionId,
		);

		return response.data.data;
	}

	return null;
};

const cash4Path = 'api/v1.0/cash4';
const projectedTransactionsPath = cash4Path + '/projectedTransactions';

export const deleteProjectedTransactionApiCall = async (
	projectedTransactionId: string,
) => {
	const apiResponse = await customerApi.delete<T4DataResponse<string>>(
		`${projectedTransactionsPath}/${projectedTransactionId}`,
		{},
	);
	if (apiResponse.data.error) throw apiResponse.data.error;

	return apiResponse.data.data;
};
