import { ExpandMore, OpenInNew } from '@mui/icons-material';
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	List,
	ListItem,
	ListItemButton,
	ListItemIcon,
	ListItemText,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { useT4Layout } from 'shared/hooks/useT4Layout';
import { stonlyData } from 'stonly/functions';

export type NavigationItemProps = {
	id: string;
	to?: string;
	label: string;
	icon?: ReactNode;
	disabled?: boolean;
	selected?: boolean;
	children?: NavigationItemProps[];
	inset?: boolean;
	onClick?: () => void;
	rightAlignIcon?: boolean;
};

export const NavigationItem: FC<NavigationItemProps> = observer(
	({
		id,
		to,
		label,
		icon,
		disabled,
		selected,
		children,
		inset,
		rightAlignIcon,
		...rest
	}) => {
		const { sideMenuOpen, setSideMenuOpen } = useT4Layout();
		const [childrenAccordionOpen, setChildrenAccordionOpen] =
			useState<boolean>(false);

		useEffect(() => {
			console.log(label);
			console.log(rightAlignIcon);
		}, [rightAlignIcon]);
		useEffect(() => {
			if (selected === true || (children?.some((x) => x.selected) ?? false))
				setChildrenAccordionOpen(true);
		}, [selected, children]);

		useEffect(() => {
			if (!sideMenuOpen) {
				setChildrenAccordionOpen(false);
			}
		}, [sideMenuOpen, setChildrenAccordionOpen]);

		const ListItemButtonMemo = useMemo(
			() => (
				<ListItemButton
					{...rest}
					{...stonlyData({ id: id })}
					data-testid={id}
					disabled={disabled ?? false}
					selected={selected ?? false}
					sx={(theme) => ({
						color: theme.palette.charcoal[700],
						'&.Mui-selected': {
							backgroundColor: 'rgba(255, 255, 255, 0.1)',
							'&:hover': {
								backgroundColor: 'rgba(255, 255, 255, 0.1)',
							},
						},
						'&:hover': {
							backgroundColor: 'rgba(255, 255, 255, 0.1)',
						},
					})}
				>
					{icon && (rightAlignIcon == undefined || rightAlignIcon == false) && (
						<ListItemIcon
							sx={() => ({
								color: 'white',
							})}
						>
							{icon}
						</ListItemIcon>
					)}
					<ListItemText
						sx={() => ({
							whiteSpace: 'nowrap',
							paddingLeft: inset ? '20px' : undefined,
							'& .MuiListItemText-primary': {
								color: 'white',
							},
						})}
					>
						{label}
					</ListItemText>
					{icon && rightAlignIcon && (
						<ListItemIcon
							sx={() => ({
								color: 'white',
							})}
						>
							{icon}
						</ListItemIcon>
					)}
					{rest.onClick && (
						<ListItemIcon
							sx={() => ({
								color: 'white',
								minWidth: 0,
							})}
						>
							<OpenInNew fontSize="small" />
						</ListItemIcon>
					)}
				</ListItemButton>
			),
			[rest, id, disabled, selected, icon, label, inset],
		);

		if (children) {
			return (
				<ListItem
					disableGutters
					disablePadding
					sx={(theme) => ({
						borderBottom: `1px solid ${theme.palette.grey[50]}`,
					})}
				>
					<Accordion
						disableGutters
						expanded={childrenAccordionOpen}
						elevation={0}
						square
						onChange={(_, expanded) => {
							if (expanded === true) {
								setSideMenuOpen(true);
							}

							setChildrenAccordionOpen(expanded);
						}}
						sx={(theme) => ({
							width: '100%',
							backgroundColor: theme.palette.charcoal[700],
						})}
					>
						<AccordionSummary
							expandIcon={
								<ExpandMore
									sx={(theme) => ({ color: theme.palette.grey[50] })}
								/>
							}
							sx={{
								padding: 0,
								margin: 0,

								'&:hover': {
									backgroundColor: 'rgba(255, 255, 255, 0.1)',
								},

								'& .MuiAccordionSummary-content': {
									padding: 0,
									margin: 0,
								},
								'& .MuiAccordionSummary-expandIconWrapper': {
									paddingRight: '8px',
									marginLeft: '-8px',
								},
								'& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
									paddingLeft: '8px',
									paddingRight: 0,
								},
							}}
						>
							<ListItemButton
								sx={{
									'&:hover': {
										backgroundColor: 'transparent',
									},
								}}
							>
								{icon && (
									<ListItemIcon
										sx={{
											color: 'white',
										}}
									>
										{icon}
									</ListItemIcon>
								)}
								<ListItemText
									sx={{
										whiteSpace: 'nowrap',
										textWrap: 'wrap',
										'& .MuiListItemText-primary': {
											color: 'white',
										},
									}}
									{...stonlyData({ id: id })}
								>
									{label}
								</ListItemText>
							</ListItemButton>
						</AccordionSummary>
						<AccordionDetails style={{ padding: 0 }}>
							<List disablePadding>
								{children.map((child) => (
									<NavigationItem
										key={`navigation-item-${child.id}`}
										id={child.id}
										to={child.to}
										onClick={child.onClick}
										label={child.label}
										icon={child.icon}
										selected={child.selected}
										disabled={child.disabled}
										children={child.children}
										rightAlignIcon={child.rightAlignIcon}
										inset
									/>
								))}
							</List>
						</AccordionDetails>
					</Accordion>
				</ListItem>
			);
		} else {
			return (
				<ListItem
					disableGutters
					disablePadding
					sx={(theme) => ({
						'& .MuiTouchRipple-child': {
							backgroundColor: `${theme.palette.charcoal['500']} !important`,
						},
					})}
				>
					{!disabled ? (
						<Link
							to={to}
							style={{
								textDecoration: 'none',
								width: '100%',
							}}
						>
							{ListItemButtonMemo}
						</Link>
					) : (
						ListItemButtonMemo
					)}
				</ListItem>
			);
		}
	},
);
