import { CssBaseline, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { t4Theme } from '../theme/t4Theme';

export type T4ThemeProviderProps = {
	children: ReactNode;
};

export const T4ThemeProvider: FC<T4ThemeProviderProps> = observer(
	({ children }) => (
		<ThemeProvider theme={t4Theme()}>
			<CssBaseline />
			{children}
		</ThemeProvider>
	),
);
