import { Add, AltRoute } from '@mui/icons-material';
import { Alert, Button, CircularProgress, Grid } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import { FC, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ActuallyPrettyGoodDataGridWrapper } from 'shared/components/actuallyPrettyGoodDataGridWrapper';
import { PageHeader, pageHeaderStonlyIds } from 'shared/components/pageHeader';
import { T4View } from 'shared/components/t4View';
import { paths } from 'shared/constants/paths';
import { useUser } from 'shared/hooks/useUser';
import { stonlyData } from 'stonly/functions';
import ListRules from './ListRules';
import { RuleModal } from './createRuleModal/RuleModal';
import {
	DataProvider,
	useDataContext,
} from './createRuleModal/providers/DataProvider';
import { FormProvider } from './createRuleModal/providers/FormProvider';
import { DeleteRuleModal } from './deleteRuleModal/deleteRuleModal';
import { TransactionRuleListItem } from './models';
import { fetchRule, useRunRules } from './services';
import { dataTestIds, getColumns, stonlyIds } from './utilities';

export const RulesRoute: FC = observer(() => {
	return (
		<DataProvider>
			<RulesPage />
		</DataProvider>
	);
});

export const RulesPage: FC = observer(() => {
	const [openRule, setOpenRule] = useState(false);
	const [isEditing, setIsEditing] = useState(false);
	const [openDeleteRule, setOpenDeleteRule] = useState(false);

	const { cash4 } = useUser();
	const history = useHistory();
	const { ruleId } = useParams<{ ruleId: string }>();
	const { categories, rule, setRule } = useDataContext();

	const { data: fetchedRule } = useQuery(
		['rule', ruleId],
		() => fetchRule(ruleId),
		{
			enabled: !!ruleId && ruleId !== 'create' && categories !== undefined,
			onSuccess: (data) => {
				if (data) {
					setIsEditing(true);
					setRule(data);
					setOpenRule(true);
				}
			},
		},
	);

	const handleRowClick = (row: TransactionRuleListItem) => {
		setIsEditing(true);
		setRule(row);
	};

	const handleOpenRule = useCallback(() => {
		history.push(
			`${paths.cash4.rules.href}/${isEditing ? rule?.id : 'create'}`,
		);
		setOpenRule(true);
	}, [isEditing, rule, history]);

	const handleCloseRule = () => {
		setOpenRule(false);
		setIsEditing(false);
		setRule(null);
		history.push(paths.cash4.rules.href);
	};

	const handleOpenDeleteRule = (row: TransactionRuleListItem) => {
		setRule(row);
		setOpenDeleteRule(true);
	};

	const handleCloseDeleteRule = () => {
		setOpenDeleteRule(false);
	};

	useEffect(() => {
		if ((isEditing && rule) || ruleId === 'create') {
			handleOpenRule();
		}
	}, [isEditing, rule, ruleId, handleOpenRule]);

	const { mutate: runRules, isLoading } = useRunRules();

	const handleGetColumns = () => {
		return getColumns(cash4, handleRowClick, handleOpenDeleteRule);
	};

	return (
		<T4View
			header={<PageHeader id={pageHeaderStonlyIds.rulesPage} title="Rules" />}
		>
			<Grid
				container
				sx={{
					height: '100%',
					flexDirection: 'column',
					flexWrap: 'nowrap',
					gap: 2,
				}}
			>
				<Grid item xs="auto">
					<Alert severity="info">
						Rules are applied to transactions according to the priority
						specified in the table below. Drag rules to change their priority.
						The first rule that matches a transaction is used to categorize that
						transaction, so it is best to assign general rules lower on the
						list.
					</Alert>
				</Grid>
				{cash4.isAuthor && (
					<Grid item xs="auto">
						<Grid
							container
							columnSpacing={2}
							sx={{
								justifyContent: 'flex-end',
							}}
						>
							<Grid item>
								<Button
									variant="outlined"
									onClick={() => runRules()}
									disabled={isLoading}
									startIcon={
										isLoading ? (
											<CircularProgress size="1.5rem" />
										) : (
											<AltRoute />
										)
									}
									{...stonlyData({ id: stonlyIds.runRulesButton })}
									data-testid={dataTestIds.runRulesButton}
								>
									{isLoading ? 'Running' : 'Run Rules'}
								</Button>
							</Grid>
							<Grid item>
								<Button
									startIcon={<Add />}
									type="button"
									variant="outlined"
									color="primary"
									data-testid={dataTestIds.createRuleButton}
									{...stonlyData({ id: stonlyIds.createRuleButton })}
									onClick={handleOpenRule}
								>
									Create Rule
								</Button>
							</Grid>
						</Grid>
					</Grid>
				)}
				<Grid item xs={true}>
					<ActuallyPrettyGoodDataGridWrapper
						sx={{
							'& .conditions-cell': {
								whiteSpace: 'normal!important',
							},
						}}
					>
						<ListRules
							getColumns={handleGetColumns}
							cash4User={cash4}
							onRowClick={handleRowClick}
						/>
					</ActuallyPrettyGoodDataGridWrapper>
				</Grid>
			</Grid>
			<FormProvider>
				<RuleModal
					open={openRule}
					onClose={handleCloseRule}
					rule={rule || fetchedRule}
					isEditing={isEditing}
				/>
			</FormProvider>
			<DeleteRuleModal
				open={openDeleteRule}
				onClose={handleCloseDeleteRule}
				rule={rule || fetchedRule}
			/>
		</T4View>
	);
});
