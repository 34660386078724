/* eslint-disable mobx/missing-observer */
import {
	Autocomplete,
	Box,
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Paper,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useState } from 'react';
import { useClients } from 'shared/hooks/useClients';
import { Cash4Account, Entity4Account } from 'models/apiModels';
import { useSnackbar } from 'notistack';
import { formatDateSimpleUpperCase } from 'shared/utilities/dateUtilities';
import { stonlyIds } from '..';
import { CancellationModal } from 'shared/components/CancellationModal';

interface LinkDialogProps {
	open: boolean;
	handleClose: () => void;
	account: Cash4Account;
}

const LinkDialog: React.FC<LinkDialogProps> = ({
	open,
	handleClose,
	account,
}) => {
	const [e4Accounts, setE4Accounts] = useState<Entity4Account[]>();
	const [selectedE4Account, setSelectedE4Account] = useState<
		Entity4Account['entity4AccountId'] | null
	>(null);
	const [openCancel, setOpenCancel] = useState(false);
	const [isRefetching, setIsRefetching] = React.useState(false);
	const { customerApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();
	const queryClient = useQueryClient();

	const handleRefetch = () => {
		return queryClient.refetchQueries(['accounts']);
	};

	const query = useQuery(['e4Accounts'], () =>
		customerApiClient.cash4.accounts.getAllEntity4Accounts(),
	);

	const linkAccounts = (data: {
		Cash4AccountId: string;
		Entity4AccountId: string;
	}) => {
		const { Cash4AccountId, Entity4AccountId } = data;
		return customerApiClient.cash4.accounts.linkAccounts(
			Cash4AccountId,
			Entity4AccountId,
		);
	};

	const { mutate, isLoading: isMutating } = useMutation(linkAccounts, {
		onSuccess: (data, variables) => {
			setIsRefetching(true);
			handleRefetch()
				.then(() => {
					if (data && data.success) {
						enqueueSnackbar('Account link created successfully', {
							variant: 'success',
						});
					} else {
						// Handle unexpected success response (e.g., 404)
						enqueueSnackbar('Account link failed unexpectedly', {
							variant: 'error',
						});
					}
				})
				.catch((error) => {
					enqueueSnackbar('Error refetching accounts.', {
						variant: 'error',
					});
				})
				.finally(() => {
					setIsRefetching(false);
					handleClose();
				});
		},
		onError: (err) => {
			enqueueSnackbar('Failed to link account.', { variant: 'error' });
			handleClose();
		},
	});

	const isLoading = isMutating || isRefetching;

	useEffect(() => {
		if (query.isSuccess) {
			setE4Accounts(query.data.data);
		}
	}, [query.isSuccess, query.data]);

	const handleSave = () => {
		const data = {
			Cash4AccountId: account.id,
			Entity4AccountId: selectedE4Account,
		};
		mutate({
			Cash4AccountId: data.Cash4AccountId,
			Entity4AccountId: data.Entity4AccountId as string,
		});
	};

	const handleOpenCancel = () => {
		setOpenCancel(true);
	};

	const handleCloseCancel = () => {
		setOpenCancel(false);
	};

	const handleConfirmCancel = () => {
		setSelectedE4Account(null);
		handleCloseCancel();
		handleClose();
	};

	const handleSelect = (
		event: React.SyntheticEvent,
		value: Entity4Account | null,
	) => {
		setSelectedE4Account(value?.entity4AccountId || null);
	};

	return (
		<Dialog
			onClose={handleOpenCancel}
			open={open}
			maxWidth="xs"
			PaperProps={{ sx: { pb: 1 } }}
			data-stonlyid={stonlyIds.accountLinkModal}
		>
			<DialogTitle>Link with Entity4</DialogTitle>
			<DialogContent>
				<DialogContentText sx={{ pb: 2 }}>
					Select an Entity4 account record to link with this Cash4 account data
				</DialogContentText>
				<Paper
					elevation={0}
					sx={{
						bgcolor: '#f2f5f7',
						p: 2,
						border: '1px solid #c3cfd9',
						borderRadius: 0,
						mb: 2,
					}}
				>
					<ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
						<li style={{ fontWeight: 600 }}>Cash4 record</li>
						<li>Account Identifier: {account.accountIdentifier}</li>
						<li>Bank Code: {account.bankName}</li>
						<li>
							Last Statement Date:{' '}
							{account.latestSyncEvent?.postDate
								? formatDateSimpleUpperCase(account.latestSyncEvent?.postDate)
								: ''}
						</li>
					</ul>
				</Paper>
				{e4Accounts && (
					<AutoCompleteE4Account
						accounts={e4Accounts}
						onSelect={handleSelect}
						stonlyIds={stonlyIds}
					/>
				)}
			</DialogContent>
			<DialogActions sx={{ pr: 3 }}>
				<Button
					onClick={handleOpenCancel}
					variant="outlined"
					color="primary"
					disabled={isLoading}
					data-stonlyid={stonlyIds.cancelButtonLinkDialog}
				>
					Cancel
				</Button>
				<Button
					onClick={handleSave}
					variant="contained"
					disableElevation
					color="primary"
					disabled={isLoading || selectedE4Account === null}
					data-stonlyid={stonlyIds.linkAccountButton}
				>
					Link Account
					{isLoading && (
						<CircularProgress
							size={24}
							sx={{
								color: 'primary',
								position: 'absolute',
								top: '50%',
								left: '50%',
								marginTop: '-12px',
								marginLeft: '-12px',
							}}
						/>
					)}
				</Button>
			</DialogActions>
			<CancellationModal
				isOpen={openCancel}
				variant="create"
				resourceType="account link"
				onClose={handleCloseCancel}
				onSubmit={handleConfirmCancel}
			/>
		</Dialog>
	);
};

export default LinkDialog;

interface AutoCompleteE4AccountProps {
	accounts: Entity4Account[];
	onSelect: (event: React.SyntheticEvent, value: Entity4Account | null) => void;
	stonlyIds?: { [key: string]: string };
}

const AutoCompleteE4Account: React.FC<AutoCompleteE4AccountProps> = ({
	accounts,
	onSelect,
	stonlyIds,
}) => {
	return (
		<Autocomplete
			id="combo-box-demo"
			options={accounts}
			getOptionLabel={(option) => option.displayName || ''}
			onChange={onSelect}
			renderOption={(props, option) => (
				<Box component="li" {...props}>
					<Stack>
						<Typography sx={{ fontWeight: 600 }}>
							{option.displayName}
						</Typography>
						<Stack direction={'row'} spacing={1}>
							<Typography>
								{option.naturalAccountNumber &&
									`*${option.naturalAccountNumber.slice(-5)}`}
							</Typography>
							<span>|</span>
							<Typography>
								{option.priorDayStatementsAccountNumber &&
									option.priorDayStatementsAccountNumber}
							</Typography>
						</Stack>
					</Stack>
				</Box>
			)}
			renderInput={(params) => (
				<TextField {...params} label="Entity4 Account" />
			)}
			data-stonlyid={stonlyIds?.entity4AccountSelector}
		/>
	);
};

interface BlueBoxProps {
	account: Cash4Account;
}

const BlueBox: React.FC<BlueBoxProps> = ({ account }) => {
	return (
		<Paper
			elevation={0}
			sx={{
				bgcolor: '#f2f5f7',
				p: 2,
				border: '1px solid #c3cfd9',
				borderRadius: 0,
				mb: 2,
			}}
		>
			<ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
				<li style={{ fontWeight: 600 }}>Cash4 record</li>
				<li>Account Identifier: {account.accountIdentifier}</li>
				<li>Bank: {account.bankName}</li>
			</ul>
		</Paper>
	);
};

export { AutoCompleteE4Account, BlueBox };
