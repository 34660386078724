import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';

export interface IConfirmationDialogProps {
	open: boolean;
	onClose: () => void;
	title?: string;
	text?: string;
	primaryButtonText?: string;
	secondaryButtonText?: string;
	onPrimaryButtonClick?: () => Promise<void> | void;
	onSecondaryButtonClick?: () => void;
	stonlyIds: { [key: string]: string | undefined };
	loading: boolean;
}

export const ConfirmationDialog: FC<IConfirmationDialogProps> = observer(
	({
		open,
		onClose,
		title,
		text,
		primaryButtonText,
		secondaryButtonText,
		onPrimaryButtonClick,
		onSecondaryButtonClick,
		stonlyIds,
		loading,
	}) => {
		return (
			<Dialog
				open={open}
				onClose={onClose}
				data-stonlyid={stonlyIds['confirmationModal'] ?? ''}
			>
				{title && <DialogTitle>{title}</DialogTitle>}
				{text && <DialogContent>{text}</DialogContent>}
				<DialogActions>
					{secondaryButtonText && (
						<Button
							color="secondary"
							onClick={onSecondaryButtonClick}
							data-stonlyid={stonlyIds['secondaryButton'] ?? ''}
							disabled={loading}
						>
							{secondaryButtonText}
						</Button>
					)}
					{primaryButtonText && (
						<Button
							color="error"
							variant="contained"
							onClick={onPrimaryButtonClick}
							data-stonlyid={stonlyIds['primaryButton'] ?? ''}
							disabled={loading}
						>
							{primaryButtonText}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	},
);
