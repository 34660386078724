import { GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro';
import { useState } from 'react';
import { CurrencySource, defaultCurrencyCode } from 'utilities/currencyUtils';

const defaultAmountWhenMissing = NaN;

export interface GridRowCalculationModel {
	id: string;
	accountAmount: number;
	accountCurrencyCode: string;
	reportingAmount?: number | undefined;
	reportingCurrencyCode: string;
}

export type UseRowSelectionCalculationsProps = {
	selectedRowIds: GridRowId[];
	selectedCount: number;
	selectedTotal: number;
	selectedCurrency: string;
	selectedCurrencySource: CurrencySource;
	setRowSelectionHandler: (gridSelectionModel: GridRowSelectionModel) => void;
	isSelectedTotalValid: boolean;
};

export const useRowSelectionCalculations = (
	rows?: GridRowCalculationModel[],
): UseRowSelectionCalculationsProps => {
	const [selectedRowIds, setSelectedRowIds] = useState<GridRowId[]>([]);
	const [selectedCount, setSelectedCount] = useState<number>(0);
	const [selectedTotal, setSelectedTotal] = useState<number>(0);
	const [selectedCurrency, setSelectedCurrency] =
		useState<string>(defaultCurrencyCode);
	const [selectedCurrencySource, setSelectedCurrencySource] =
		useState<CurrencySource>(CurrencySource.Account);
	const [isSelectedTotalValid, setIsSelectedTotalValid] =
		useState<boolean>(true);

	const rowSelection = (gridSelectionModel: GridRowSelectionModel) => {
		// Calculate RowIds and Count
		const selection = gridSelectionModel as GridRowId[];
		setSelectedRowIds(selection);
		const count = selection.length;
		setSelectedCount(count);

		// Calculate Currency Code
		const uniqueCurrencyCodes = new Set<string>();
		var reportingCurrency = defaultCurrencyCode;
		rows?.forEach((value: GridRowCalculationModel) => {
			if (selection.includes(value.id)) {
				uniqueCurrencyCodes.add(value.accountCurrencyCode);
				reportingCurrency = value.reportingCurrencyCode || defaultCurrencyCode;
			}
		});
		var useReportingCurrency: boolean = false;
		if (uniqueCurrencyCodes.size === 0) {
			setSelectedCurrency(defaultCurrencyCode);
			setSelectedCurrencySource(CurrencySource.Account);
		} else if (uniqueCurrencyCodes.size === 1) {
			setSelectedCurrency([...uniqueCurrencyCodes][0]);
			setSelectedCurrencySource(CurrencySource.Account);
		} else {
			useReportingCurrency = true;
			setSelectedCurrency(reportingCurrency);
			setSelectedCurrencySource(CurrencySource.Reporting);
		}

		// Calculate Total Amount
		var total = 0;
		rows?.forEach((value: GridRowCalculationModel) => {
			if (selection.includes(value.id)) {
				const reportingAmount =
					value.reportingAmount !== undefined
						? value.reportingAmount
						: value.accountAmount === 0
						? 0
						: defaultAmountWhenMissing;
				total += useReportingCurrency ? reportingAmount : value.accountAmount;
			}
		});
		setSelectedTotal(total);
		setIsSelectedTotalValid(!isNaN(total));
	};

	return {
		selectedRowIds,
		selectedCount,
		selectedTotal,
		selectedCurrency,
		selectedCurrencySource,
		setRowSelectionHandler: rowSelection,
		isSelectedTotalValid,
	};
};
