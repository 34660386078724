import { Box } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { T4TextFieldV2 } from 'features/entity4/shared/components/atoms/t4TextField';
import { Payment } from 'modules/clients/apiGateway/payments4/payments';
import { useSnackbar } from 'notistack';
import { FC, useCallback, useState } from 'react';
import { FormModal } from 'shared/components/formModal';
import { useClients } from 'shared/hooks/useClients';
import { isStringUndefinedOrNullOrWhitespace } from 'utilities/stringUtils';

export const CancelPaymentModal: FC<{
	paymentId: string | null;
	onClose: () => void;
	refetch: () => Promise<QueryObserverResult<Payment[], Error>>;
}> = ({ paymentId, onClose, refetch }) => {
	const { applicationApiClient } = useClients();
	const { enqueueSnackbar } = useSnackbar();

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [cancelReason, setCancelReason] = useState<string | null>(null);

	const closeModal = useCallback(() => {
		onClose();
		setCancelReason(null);
		setIsLoading(false);
	}, [onClose]);

	const cancelPayment = useCallback(async () => {
		if (paymentId === null || isStringUndefinedOrNullOrWhitespace(cancelReason))
			return;

		try {
			setIsLoading(true);
			const repsonse = await applicationApiClient.payments4.payments.cancel({
				id: paymentId!,
				data: { reason: cancelReason! },
			});

			if (repsonse.status === 200) {
				enqueueSnackbar('Successfully canceled payment.', {
					variant: 'success',
				});
				refetch();
				closeModal();
			} else throw new Error();
		} catch (error) {
			enqueueSnackbar('Unable to cancel payment. Please try again later.', {
				variant: 'error',
			});
		} finally {
			setIsLoading(false);
		}
	}, [
		paymentId,
		cancelReason,
		enqueueSnackbar,
		closeModal,
		refetch,
		applicationApiClient,
	]);

	return (
		<FormModal
			title="Cancel Payment Initiation?"
			description="Canceling payment initiation will stop this payment from being sent to the bank. This action cannot be undone."
			open={paymentId !== null}
			onClose={closeModal}
			onSubmit={cancelPayment}
			loading={isLoading}
			submitButtonLabel="Cancel Payment"
			submitButtonColor="error"
			submitDisabled={
				isLoading || isStringUndefinedOrNullOrWhitespace(cancelReason)
			}
			cancelButtonLabel="Return"
			cancelDisabled={isLoading}
		>
			<Box sx={{ width: '100%' }}>
				<T4TextFieldV2
					id="cancel-payment-reason"
					label="Cancelation Reason"
					value={cancelReason ?? ''}
					onChange={(value) => setCancelReason(value)}
					InputProps={{ readOnly: isLoading }}
					inputProps={{
						maxLength: 200,
					}}
					required
					multiline
					maxRows={3}
					minRows={3}
					helperText={`${cancelReason?.length ?? 0}/200`}
				/>
			</Box>
		</FormModal>
	);
};
