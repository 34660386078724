import {
	AccountBalance,
	AutoGraph,
	BackupTable,
	DescriptionOutlined,
	Diversity3,
	Domain,
	FiberNew,
	Group,
	Home,
	InputOutlined,
	Insights,
	Key,
} from '@mui/icons-material';
import { SigmaPage } from 'features/_shared/_pages/sigmaPage';
import { observer } from 'mobx-react-lite';
import { FC, useEffect, useState } from 'react';
import { Route, Switch, generatePath, useLocation } from 'react-router-dom';
import { Layout } from 'shared/components/layout';
import { Navbar } from 'shared/components/navbar';
import { NavigationItemProps } from 'shared/components/navigation/navigationItem';
import { NavigationMenu } from 'shared/components/navigation/navigationMenu';
import SolutionPicker from 'shared/components/solutionPicker/solutionPicker';
import { paths, validObjectTypeRegex } from 'shared/constants/paths';
import { useT4FeatureFlags } from 'shared/hooks/useT4FeatureFlags';
import { useUser } from 'shared/hooks/useUser';
import { T4Solution } from 'shared/t4Solutions';
import { CannotDisplay } from '../../shared/components/cannotDisplay';
import {
	ACCESS_DENIED_MESSAGING,
	NOT_FOUND_MESSAGING,
	RETURN_TO_HOME,
} from '../../shared/constants/cannotDisplayMessaging';
import {
	SigmaDashboardProvider,
	SigmaEntity4DashboardCode,
} from './dashbaord/_providers/dashboardProvider';
import { DashbaordPage } from './dashbaord/pages/dashboardPage';
import { DashboardPageV2 } from './dashbaord/pages/dashboardPageV2';
import { Entity4AnalyticsStudioPage } from './dashboardPage/entity4AnalyticsStudioPage';
import { DataImportsPage } from './dataImports/components/dataImportsPage';
import { ObjectsRoutes } from './entities/objectsRoutes';
import { EntityType } from './entity4Constants';
import { GlobalDocumentRoutes } from './globalDocuments/globalDocumentRoutes';
import { NotificationsPage } from './notifications/notificationsPage';
import { VisualizationProvider } from './visualizations/_shared/_providers/visualizationProvider';
import { AccountMapProvider } from './visualizations/accountMap/_providers/accountMapProvider';
import { AccountMapPage } from './visualizations/accountMap/accountMapPage';
import { EntityAccountViewPage } from './visualizations/entityAccountView/entityAccountViewPage';
import { LevelOne } from './visualizations/levelOne/levelOne';
import { OrgChartProvider } from './visualizations/orgChart/_shared/_providers/orgChartProvider';
import { OrgChartPage } from './visualizations/orgChart/orgChartPage';

export const Entity4Routes: FC = observer(() => {
	const location = useLocation();
	const { isViewer, isAuthor, isAccountUser, isDataImporter } = useUser();
	const {
		sigmaStudioEnabled,
		sigmaDashboardEnabled,
		hidePowerBi,
		hideBetaMessaging,
	} = useT4FeatureFlags();

	const [selectedItem, setSelectedItem] = useState('');
	const [navigationItems, setNavigationItems] = useState<NavigationItemProps[]>(
		[],
	);

	useEffect(() => {
		const path: string = location.pathname;

		if (path.includes('/entities')) {
			setSelectedItem('Entities');
		} else if (path.includes('/partners')) {
			setSelectedItem('Partners');
		} else if (path.includes('/counterparties')) {
			setSelectedItem('Counterparties');
		} else if (path.includes('/accounts')) {
			setSelectedItem('Accounts');
		} else if (path.includes('/staff')) {
			setSelectedItem('Staff');
		} else if (path.includes(paths.entity4.documents.href)) {
			setSelectedItem('documents');
		} else if (path.includes(paths.entity4.orgChart.href)) {
			setSelectedItem('org-chart');
		} else if (path.includes(paths.entity4.accountMap.href)) {
			setSelectedItem('account-map');
		} else if (path.includes(paths.entity4.entityAccountMap.href)) {
			setSelectedItem('entity-account-map');
		} else if (path.includes(paths.entity4.analyticsStudioClassic.href)) {
			setSelectedItem('analyticsStudioClassic');
		} else if (path.includes(paths.entity4.analyticsStudio.href)) {
			setSelectedItem('analyticsStudio');
		} else if (path.includes(paths.entity4.dataImports.href)) {
			setSelectedItem('dataImports');
		} else if (path.includes(paths.entity4.href)) {
			setSelectedItem('dashboard');
		} else {
			setSelectedItem('');
		}
	}, [location.pathname]);

	useEffect(() => {
		const navigationItems: NavigationItemProps[] = [
			{
				id: 'dashboard-navigation-button',
				to: generatePath(paths.entity4.href),
				label: 'Dashboard',
				icon: <Home />,
				selected: selectedItem === 'dashboard',
			},
			{
				id: 'entities-navigation-button',
				to: generatePath(paths.entity4.objects.href, {
					objectType: EntityType.Entity,
				}),
				label: 'Entities',
				icon: <Domain />,
				selected: selectedItem === 'Entities',
			},
			{
				id: 'partners-navigation-button',
				to: generatePath(paths.entity4.objects.href, {
					objectType: EntityType.Partner,
				}),
				label: 'Partners',
				icon: <Diversity3 />,
				selected: selectedItem === 'Partners',
			},
			{
				id: 'counterparties-navigation-button',
				to: generatePath(paths.entity4.objects.href, {
					objectType: EntityType.Counterparty,
				}),
				label: 'Counterparties',
				icon: <AccountBalance />,
				selected: selectedItem === 'Counterparties',
			},
		];

		if (isAccountUser) {
			navigationItems.push({
				id: 'accounts-navigation-button',
				to: generatePath(paths.entity4.objects.href, {
					objectType: EntityType.Account,
				}),
				label: 'Accounts',
				icon: <Key />,
				selected: selectedItem === 'Accounts',
			});
		}

		navigationItems.push(
			{
				id: 'staff-navigation-button',
				to: generatePath(paths.entity4.objects.href, {
					objectType: EntityType.Staff,
				}),
				label: 'Staff',
				icon: <Group />,
				selected: selectedItem === 'Staff',
			},
			{
				id: 'documents-navigation-button',
				to: generatePath(paths.entity4.documents.href),
				label: 'Documents',
				icon: <DescriptionOutlined />,
				selected: selectedItem === 'documents',
			},
			{
				id: 'visualization-sidebar-navigation',
				label: 'Visualizations',
				icon: <Insights />,
				children: [
					{
						id: 'org-chart-sidebar-navigation',
						to: generatePath(paths.entity4.orgChart.href),
						label: 'Entity Org Chart',
						selected: selectedItem === 'org-chart',
					},
					{
						id: 'account-map-sidebar-navigation',
						to: generatePath(paths.entity4.accountMap.href),
						label: 'Account Map',
						selected: selectedItem === 'account-map',
					},
					{
						id: 'entity-account-view-sidebar-navigation',
						to: generatePath(paths.entity4.entityAccountMap.href),
						label: 'Entity Account Map',
						selected: selectedItem === 'entity-account-map',
					},
				],
			},
		);

		if (isAccountUser && sigmaStudioEnabled && !hidePowerBi) {
			const analyticsStudio: NavigationItemProps = {
				id: 'analytics-sidebar-navigation',
				label: 'Analytics Studio',
				icon: <BackupTable />,
				children: [
					{
						id: 'analytics-studio-sidebar-navigation',
						to: generatePath(paths.entity4.analyticsStudio.href),
						label: hideBetaMessaging ? 'Analytics Studio' : 'Beta',
						icon: hideBetaMessaging && <FiberNew />,
						rightAlignIcon: hideBetaMessaging,
						selected: selectedItem === 'analyticsStudio',
					},
					{
						id: 'analytics-studio-classic-sidebar-navigation',
						to: generatePath(paths.entity4.analyticsStudioClassic.href),
						label: hideBetaMessaging ? 'Analytics Studio - Classic' : 'Classic',
						selected: selectedItem === 'analyticsStudioClassic',
					},
				],
			};

			navigationItems.push(analyticsStudio);
		} else if (isAccountUser && sigmaStudioEnabled) {
			navigationItems.push({
				id: 'analytics-studio-sidebar-navigation',
				to: generatePath(paths.entity4.analyticsStudio.href),
				label: 'Analytics Studio',
				icon: hideBetaMessaging ? <FiberNew /> : <BackupTable />,
				selected: selectedItem === 'analyticsStudio',
			});
		} else if (!hidePowerBi && isAccountUser) {
			navigationItems.push({
				id: 'analytics-studio-classic-sidebar-navigation',
				to: generatePath(paths.entity4.analyticsStudioClassic.href),
				label: 'Analytics Studio',
				icon: <BackupTable />,
				selected: selectedItem === 'analyticsStudioClassic',
			});
		}

		if (isDataImporter) {
			navigationItems.push({
				id: 'entity4-data-imports',
				to: paths.entity4.dataImports.href,
				label: 'Data Imports',
				icon: <InputOutlined />,
				selected: selectedItem === 'dataImports',
			});
		}

		setNavigationItems(navigationItems);
	}, [
		isAccountUser,
		selectedItem,
		isDataImporter,
		sigmaStudioEnabled,
		hidePowerBi,
	]);

	return (
		<Layout
			appBar={
				<Navbar logo={<SolutionPicker activeSolution={T4Solution.Entity4} />} />
			}
			navElements={
				<NavigationMenu
					sections={[
						{
							navigationItems: navigationItems,
						},
					]}
				/>
			}
		>
			{isViewer || isAuthor ? (
				<Switch>
					<Route exact path={paths.entity4.href}>
						{sigmaDashboardEnabled ? (
							<SigmaDashboardProvider code={SigmaEntity4DashboardCode}>
								<DashboardPageV2 />
							</SigmaDashboardProvider>
						) : (
							<DashbaordPage />
						)}
					</Route>
					<Route exact path={paths.entity4.orgChart.href}>
						<VisualizationProvider>
							<OrgChartProvider>
								<OrgChartPage />
							</OrgChartProvider>
						</VisualizationProvider>
					</Route>
					<Route exact path={paths.entity4.accountMap.href}>
						<VisualizationProvider>
							<AccountMapProvider visualizationKey="accountMap">
								<AccountMapPage />
							</AccountMapProvider>
						</VisualizationProvider>
					</Route>
					<Route exact path={paths.entity4.entityAccountMap.href}>
						<VisualizationProvider>
							<AccountMapProvider visualizationKey="entityAccountMap">
								<EntityAccountViewPage />
							</AccountMapProvider>
						</VisualizationProvider>
					</Route>
					<Route exact path={paths.entity4.href.concat('/level-one')}>
						<LevelOne />
					</Route>
					<Route path={paths.entity4.documents.href}>
						<GlobalDocumentRoutes />
					</Route>
					{!hidePowerBi && (
						<Route exact path={paths.entity4.analyticsStudioClassic.href}>
							<Entity4AnalyticsStudioPage />
						</Route>
					)}
					{sigmaStudioEnabled && (
						<Route exact path={paths.entity4.analyticsStudio.href}>
							<SigmaPage solution="Entity4" />
						</Route>
					)}
					<Route exact path={paths.entity4.notifications.href}>
						<NotificationsPage />
					</Route>
					{isDataImporter && (
						<Route exact path={paths.entity4.dataImports.href}>
							<DataImportsPage />
						</Route>
					)}
					<Route path={paths.entity4.objects.href.concat(validObjectTypeRegex)}>
						<ObjectsRoutes />
					</Route>

					<Route>
						<CannotDisplay
							headingText={NOT_FOUND_MESSAGING.HEADING}
							bodyText={NOT_FOUND_MESSAGING.BODY}
							imageSrc={NOT_FOUND_MESSAGING.IMAGE}
							buttonText={RETURN_TO_HOME}
							buttonHref={paths.root.href}
						/>
					</Route>
				</Switch>
			) : (
				<CannotDisplay
					headingText={ACCESS_DENIED_MESSAGING.HEADING}
					bodyText={ACCESS_DENIED_MESSAGING.BODY}
					imageSrc={ACCESS_DENIED_MESSAGING.IMAGE}
				/>
			)}
		</Layout>
	);
});
