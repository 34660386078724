import moment, { Moment } from 'moment';
import { useState } from 'react';

const keys = {
	organizationId: 'organizationId',
	balanceStartDate: 'balanceDtartDate',
	balanceEndDate: 'balanceEndDate',
	transactionStartDate: 'transactionStartDate',
	transactionEndDate: 'transactionEndDate',
	projectedTransactionStartDate: 'projectedTransactionStartDate',
	projectedTransactionEndDate: 'projectedTransactionEndDate',
};

function _getItem(key: string): string | undefined {
	return sessionStorage.getItem(`t4-${key}`) ?? undefined;
}

function _updateItem(key: string, value?: string): void {
	if (value) {
		sessionStorage.setItem(`t4-${key}`, value);
	} else {
		sessionStorage.removeItem(`t4-${key}`);
	}
}

export type UseSessionStorageProps = {
	organizationId: string | undefined;
	setOrganizationId: (organizationId?: string) => void;

	balanceStartDate: Moment;
	setBalanceStartDate: (date: Moment) => void;
	balanceEndDate: Moment;
	setBalanceEndDate: (date: Moment) => void;

	transactionStartDate: Moment;
	setTransactionStartDate: (date: Moment) => void;
	transactionEndDate: Moment;
	setTransactionEndDate: (date: Moment) => void;

	projectedTransactionStartDate: Moment;
	setProjectedTransactionStartDate: (date: Moment) => void;
	projectedTransactionEndDate: Moment | null;
	setProjectedTransactionEndDate: (date: Moment) => void;
};

export function useSessionStorage(): UseSessionStorageProps {
	//#region organizationId

	const [organizationId, _setOrganizationId] = useState<string | undefined>(
		_getItem(keys.organizationId),
	);

	function setOrganizationId(organizationId?: string) {
		_updateItem(keys.organizationId, organizationId);
		_setOrganizationId(organizationId);
	}

	//#endregion

	//#region balanceStartDate

	const [balanceStartDate, _setBalanceStartDate] = useState<Moment>(() => {
		const date = _getItem(keys.balanceStartDate);

		if (date) {
			return moment(date);
		} else {
			return moment().subtract(8, 'days').startOf('day');
		}
	});
	const [balanceEndDate, _setBalanceEndDate] = useState<Moment>(() => {
		const date = _getItem(keys.balanceEndDate);

		if (date) {
			return moment(date);
		} else {
			return moment().startOf('day');
		}
	});

	function setBalanceStartDate(date: Moment) {
		_updateItem(keys.balanceStartDate, date.toISOString());
		_setBalanceStartDate(date);
	}

	function setBalanceEndDate(date: Moment) {
		_updateItem(keys.balanceEndDate, date.toISOString());
		_setBalanceEndDate(date);
	}

	const [transactionStartDate, _setTransactionStartDate] = useState<Moment>(
		() => {
			const date = _getItem(keys.transactionStartDate);

			if (date) {
				return moment(date);
			} else {
				return moment().subtract(8, 'days').startOf('day');
			}
		},
	);
	const [transactionEndDate, _setTransactionEndDate] = useState<Moment>(() => {
		const date = _getItem(keys.transactionEndDate);

		if (date) {
			return moment(date);
		} else {
			return moment().startOf('day');
		}
	});

	function setTransactionStartDate(date: Moment) {
		_updateItem(keys.transactionStartDate, date.toISOString());
		_setTransactionStartDate(date);
	}

	function setTransactionEndDate(date: Moment) {
		_updateItem(keys.transactionEndDate, date.toISOString());
		_setTransactionEndDate(date);
	}

	const [projectedTransactionStartDate, _setProjectedTransactionStartDate] =
		useState<Moment>(() => {
			const date = _getItem(keys.projectedTransactionStartDate);

			if (date) {
				return moment(date);
			} else {
				return moment().subtract(14, 'days').startOf('day');
			}
		});
	const [projectedTransactionEndDate, _setProjectedTransactionEndDate] =
		useState<Moment>(() => {
			const date = _getItem(keys.projectedTransactionEndDate);

			if (date) {
				return moment(date);
			} else {
				return moment().add(14, 'days').startOf('day');
			}
		});

	function setProjectedTransactionStartDate(date: Moment) {
		_updateItem(keys.projectedTransactionStartDate, date.toISOString());
		_setTransactionStartDate(date);
	}

	function setProjectedTransactionEndDate(date: Moment) {
		_updateItem(keys.projectedTransactionEndDate, date.toISOString());
		_setTransactionEndDate(date);
	}

	//#endregion

	return {
		organizationId,
		setOrganizationId,

		balanceStartDate,
		setBalanceStartDate,
		balanceEndDate,
		setBalanceEndDate,

		transactionStartDate,
		setTransactionStartDate,
		transactionEndDate,
		setTransactionEndDate,

		projectedTransactionStartDate,
		setProjectedTransactionStartDate,
		projectedTransactionEndDate,
		setProjectedTransactionEndDate,
	};
}
