import { EntityType } from '../../features/entity4/entity4Constants';

//#region Path Validations

export const validObjectTypeRegex =
	'(entities|partners|counterparties|accounts|staff)';
export const validIdRegex = '([a-f0-9-]{36})';

//#endregion

//#region Path Params

export type GroupPathParams = {
	groupId: string;
};

export type ObjectsPathParams = {
	objectType: EntityType;
};

export type ObjectPathParams = ObjectsPathParams & {
	objectId: string;
};

export type ObjectDocumentPathParams = ObjectPathParams & {
	documentId: string;
};

export type ObjectRegistrationPathParams = ObjectPathParams & {
	registrationId: string;
};

export type ObjectRegisteredAgentDetailPathParams = ObjectPathParams & {
	registeredAgentDetailId: string;
};

export type ObjectTaxIdPathParams = ObjectPathParams & {
	taxIdId: string;
};

export type ObjectSubaccountPathParams = ObjectPathParams & {
	subaccountId: string;
};

//#endregion

//#region Path Keys

export type PathKeys =
	| 'root'
	| 'error'
	| 'accessDenied'
	| 'notifications'
	| 'entity4'
	| 'cash4'
	| 'payments4'
	| 'administration';
export type AdministrationPathKeys =
	| 'auditLog'
	| 'securityGroupApprovals'
	| 'entity4Configurations'
	| 'cash4Configuration'
	| 'legalEntityGroups'
	| 'userEntitlements'
	| 'groups'
	| 'payments4';
export type Payments4AdminPathKeys = 'paymentApprovalsManagement';
export type SecurityGroupsPathKeys = 'ungroupedEntities' | 'group';
export type SecurityGroupPathKeys = 'information' | 'members' | 'entities';
export type Entity4PathKeys =
	| 'documents'
	| 'orgChart'
	| 'accountMap'
	| 'entityAccountMap'
	| 'analyticsStudioClassic'
	| 'analyticsStudio'
	| 'notifications'
	| 'dataImports'
	| 'objects';
export type Cash4PathKeys =
	| 'balances'
	| 'transactions'
	| 'categories'
	| 'rules'
	| 'analyticsStudioClassic'
	| 'analyticsStudio'
	| 'dataImports'
	| 'accountIntegrations'
	| 'investments'
	| 'reconciliations'
	| 'projectedTransactions'
	| 'dashboard'
	| 'portals';
export type AccountIntegrationPathKeys = 'accounts' | 'connections';
export type InvestmentPathKeys = 'holdings' | 'transactions';
export type GlobalDocumentsPathKeys = 'document';
export type ObjectsPathKeys = 'object';
export type ObjectPathKeys =
	| 'information'
	| 'relationships'
	| 'documents'
	| 'registrations'
	| 'registeredAgentDetails'
	| 'taxIds'
	| 'subaccounts'
	| 'legal';

export type Payments4PathKeys =
	| 'paymentStatus'
	| 'paymentApprovals'
	| 'templates';

export type T4Path = {
	href: string;
};

export type T4Paths = {
	[key in PathKeys]: T4Path;
} & {
	administration: T4Path & { [key in AdministrationPathKeys]: T4Path } & {
		groups: T4Path & {
			[key in SecurityGroupsPathKeys]: T4Path;
		} & {
			group: T4Path & {
				[key in SecurityGroupPathKeys]: T4Path;
			};
		};
		payments4: T4Path & { [key in Payments4AdminPathKeys]: T4Path };
	};
	entity4: T4Path & {
		[key in Entity4PathKeys]: T4Path;
	} & {
		objects: T4Path & {
			[key in ObjectsPathKeys]: T4Path;
		} & {
			object: T4Path & {
				[key in ObjectPathKeys]: T4Path;
			} & {
				documents: T4Path & {
					document: T4Path;
				};
				registrations: T4Path & {
					registration: T4Path;
				};
				registeredAgentDetails: T4Path & {
					registeredAgentDetail: T4Path;
				};
				taxIds: T4Path & {
					taxId: T4Path;
				};
				subaccounts: T4Path & {
					subaccount: T4Path;
				};
			};
		};
		documents: T4Path & {
			[key in GlobalDocumentsPathKeys]: T4Path;
		};
	};
	cash4: T4Path & {
		[key in Cash4PathKeys]: T4Path;
	} & {
		accountIntegrations: T4Path & {
			[key in AccountIntegrationPathKeys]: T4Path;
		};
		investments: T4Path & {
			[key in InvestmentPathKeys]: T4Path;
		};
	};
	payments4: T4Path & {
		[key in Payments4PathKeys]: T4Path;
	};
};

//#endregion

//#region Paths

export const paths: T4Paths = {
	root: {
		href: '/',
	},
	error: {
		href: '/error',
	},
	accessDenied: {
		href: '/access-denied',
	},
	notifications: {
		href: '/notifications',
	},

	administration: {
		href: '/administration',

		auditLog: {
			href: '/administration/audit-log',
		},
		userEntitlements: {
			href: '/administration/user-entitlements',
		},
		entity4Configurations: {
			href: '/administration/entity4-configuration',
		},
		cash4Configuration: {
			href: '/administration/cash4-configuration',
		},
		securityGroupApprovals: {
			href: '/administration/security-group-approvals',
		},
		legalEntityGroups: {
			href: '/administration/legal-entity-groups',
		},
		groups: {
			href: '/administration/groups',

			ungroupedEntities: {
				href: '/administration/groups/ungrouped-entities',
			},
			group: {
				href: '/administration/groups/:groupId',

				information: {
					href: '/administration/groups/:groupId/information',
				},
				members: {
					href: '/administration/groups/:groupId/members',
				},
				entities: {
					href: '/administration/groups/:groupId/entities',
				},
			},
		},
		payments4: {
			href: '/administration/payments4',

			paymentApprovalsManagement: {
				href: '/administration/payments4/payment-approvals-management',
			},
		},
	},
	entity4: {
		href: '/entity4',

		objects: {
			href: '/entity4/:objectType',

			object: {
				href: '/entity4/:objectType/:objectId',

				information: {
					href: '/entity4/:objectType/:objectId/information',
				},
				relationships: {
					href: '/entity4/:objectType/:objectId/relationships',
				},
				documents: {
					href: '/entity4/:objectType/:objectId/documents',

					document: {
						href: '/entity4/:objectType/:objectId/documents/:documentId',
					},
				},
				registrations: {
					href: '/entity4/:objectType/:objectId/registrations',

					registration: {
						href: '/entity4/:objectType/:objectId/registrations/:registrationId',
					},
				},
				registeredAgentDetails: {
					href: '/entity4/:objectType/:objectId/registered-agent-details',

					registeredAgentDetail: {
						href: '/entity4/:objectType/:objectId/registered-agent-details/:registeredAgentDetailId',
					},
				},
				taxIds: {
					href: '/entity4/:objectType/:objectId/tax-ids',

					taxId: {
						href: '/entity4/:objectType/:objectId/tax-ids/:taxIdId',
					},
				},
				legal: {
					href: '/entity4/:objectType/:objectId/legal',
				},
				subaccounts: {
					href: '/entity4/:objectType/:objectId/subaccounts',

					subaccount: {
						href: '/entity4/:objectType/:objectId/subaccounts/:subaccountId',
					},
				},
			},
		},
		documents: {
			href: '/entity4/documents',

			document: {
				href: '/entity4/documents/:documentId',
			},
		},
		orgChart: {
			href: '/entity4/org-chart',
		},
		accountMap: {
			href: '/entity4/account-map',
		},
		entityAccountMap: {
			href: '/entity4/entity-account-map',
		},
		analyticsStudioClassic: {
			href: '/entity4/analytics-studio-classic',
		},
		analyticsStudio: {
			href: '/entity4/analytics-studio',
		},
		notifications: {
			href: '/entity4/notifications',
		},
		dataImports: {
			href: '/entity4/data-imports',
		},
	},
	cash4: {
		href: '/cash4',

		balances: {
			href: '/cash4/balances',
		},
		dashboard: {
			href: '/cash4/dashboard',
		},
		transactions: {
			href: '/cash4/transactions',
		},
		projectedTransactions: {
			href: '/cash4/transactions/projected',
		},
		categories: {
			href: '/cash4/categories',
		},
		rules: {
			href: '/cash4/rules',
		},
		analyticsStudioClassic: {
			href: '/cash4/analytics-studio-classic',
		},
		analyticsStudio: {
			href: '/cash4/analytics-studio',
		},
		dataImports: {
			href: '/cash4/data-imports',
		},
		reconciliations: {
			href: '/cash4/reconciliations',
		},
		portals: {
			href: '/cash4/portals',
		},
		accountIntegrations: {
			href: '/cash4/account-integrations',

			accounts: {
				href: '/cash4/account-integrations/accounts',
			},
			connections: {
				href: '/cash4/account-integrations/connections',
			},
		},
		investments: {
			href: '/cash4/investments',

			holdings: {
				href: '/cash4/investments/holdings',
			},
			transactions: {
				href: '/cash4/investments/transactions',
			},
		},
	},
	payments4: {
		href: '/payments4',

		paymentStatus: {
			href: '/payments4/payment-status',
		},
		paymentApprovals: {
			href: '/payments4/payment-approvals',
		},
		templates: {
			href: '/payments4/templates',
		},
	},
};

//#endregion
