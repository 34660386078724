import {
	useT4Query,
	UseT4QueryProps,
} from 'features/cash4/_shared/_utilities/useT4Query';
import { SigmaDataRefresh } from 'modules/clients/customer-api/src/sigmaEmbed';
import { useCallback } from 'react';
import { useClients } from 'shared/hooks/useClients';

export type UseDataRefreshesQueryProps = UseT4QueryProps<SigmaDataRefresh[]>;

export function useDataRefreshesQuery(): UseDataRefreshesQueryProps {
	const { customerApiClient } = useClients();

	const getDataRefreshes = useCallback(async () => {
		const response = await customerApiClient.sigmaEmbed.getDataRefreshes();

		if (response && response.data && response.data.data) {
			return response.data.data;
		} else {
			throw new Error('Failed to load sigma data refreshes.');
		}
	}, [customerApiClient.sigmaEmbed]);

	return useT4Query<SigmaDataRefresh[]>(getDataRefreshes, {
		errorMessage: 'Failed to load sigma data refreshes.',
	});
}
