import { Grid } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { FC, useState } from 'react';
import { EntityCollection } from '../../../entities/objects/entityCollection';
import { AddressFields } from '../../../fields/objects/addressList';
import { FieldSet } from '../fieldTypes';
import { FieldViewReference } from '../fieldViews/fieldViewReference';
import { FieldViewString } from '../fieldViews/fieldViewString';
import { FieldViews } from '../fieldViews/fieldViewTypes';

interface IAddressViewProps {
	isFromTo: boolean;
	addressType: string;
	addressCollection: EntityCollection;
	collectionDefinition: FieldSet;
	fieldViewType: FieldViews;
}

export const AddressView: FC<IAddressViewProps> = observer(
	({ addressType, addressCollection, collectionDefinition }) => {
		const [countryField, setCountryField] = useState(
			addressCollection.getField(AddressFields.country),
		);
		const [buildingNumberField, setBuildingNumberField] = useState(
			addressCollection.getField(AddressFields.building_number),
		);
		const [streetNameField, setStreetNameField] = useState(
			addressCollection.getField(AddressFields.street_name),
		);
		const [poBoxField, setPoBoxField] = useState(
			addressCollection.getField(AddressFields.po_box),
		);
		const [address1Field, setAddress1Field] = useState(
			addressCollection.getField(AddressFields.address_1),
		);
		const [address2Field, setAddress2Field] = useState(
			addressCollection.getField(AddressFields.address_2),
		);
		const [cityField, setCityField] = useState(
			addressCollection.getField(AddressFields.city),
		);
		const [stateProvinceField, setStateProvinceField] = useState(
			addressCollection.getField(AddressFields.state_province),
		);
		const [postalCodeField, setPostalCodeField] = useState(
			addressCollection.getField(AddressFields.postal_code),
		);
		const isMailingAddress = addressType.toLowerCase().indexOf('mail') > -1;

		const provinceDoesNotMatchCountry =
			countryField?.initialReferenceValue &&
			stateProvinceField?.initialReferenceValue &&
			countryField.initialReferenceValue.identifier !==
				stateProvinceField.initialReferenceValue.parentIdentifier;

		return (
			<Grid container rowSpacing={0.5} columnSpacing={2}>
				<Grid item xs={12}>
					<FieldViewReference
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={countryField}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(AddressFields.country)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.country, value ?? null)
								.then(() => {
									setCountryField(
										addressCollection.getField(AddressFields.country),
									);
								});
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldViewString
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={buildingNumberField}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(
								AddressFields.building_number,
							)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.building_number, value ?? null)
								.then(() => {
									setBuildingNumberField(
										addressCollection.getField(AddressFields.building_number),
									);
								});
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldViewString
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={streetNameField}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(
								AddressFields.street_name,
							)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.street_name, value ?? null)
								.then(() => {
									setStreetNameField(
										addressCollection.getField(AddressFields.street_name),
									);
								});
						}}
					/>
				</Grid>
				{isMailingAddress ? (
					<Grid item xs={12}>
						<FieldViewString
							fieldLayoutProps={{
								excludeDivider: true,
							}}
							fieldData={poBoxField}
							fieldDefinition={
								collectionDefinition.getFieldDefinition(AddressFields.po_box)!
							}
							createField={(value?: string) => {
								addressCollection
									.v2AddField(AddressFields.po_box, value ?? null)
									.then(() => {
										setPoBoxField(
											addressCollection.getField(AddressFields.po_box),
										);
									});
							}}
						/>
					</Grid>
				) : null}
				<Grid item xs={12}>
					<FieldViewString
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={address1Field}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(AddressFields.address_1)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.address_1, value ?? null)
								.then(() => {
									setAddress1Field(
										addressCollection.getField(AddressFields.address_1),
									);
								});
						}}
					/>
				</Grid>
				<Grid item xs={12}>
					<FieldViewString
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={address2Field}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(AddressFields.address_2)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.address_2, value ?? null)
								.then(() => {
									setAddress2Field(
										addressCollection.getField(AddressFields.address_2),
									);
								});
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldViewString
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={cityField}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(AddressFields.city)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.city, value ?? null)
								.then(() => {
									setCityField(addressCollection.getField(AddressFields.city));
								});
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldViewReference
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={stateProvinceField}
						customError={
							provinceDoesNotMatchCountry
								? 'State/Province does not match selected country. Please update.'
								: undefined
						}
						parentField={countryField}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(
								AddressFields.state_province,
							)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.state_province, value ?? null)
								.then(() => {
									setStateProvinceField(
										addressCollection.getField(AddressFields.state_province),
									);
								});
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FieldViewString
						fieldLayoutProps={{
							excludeDivider: true,
						}}
						fieldData={postalCodeField}
						fieldDefinition={
							collectionDefinition.getFieldDefinition(
								AddressFields.postal_code,
							)!
						}
						createField={(value?: string) => {
							addressCollection
								.v2AddField(AddressFields.postal_code, value ?? null)
								.then(() => {
									setPostalCodeField(
										addressCollection.getField(AddressFields.postal_code),
									);
								});
						}}
					/>
				</Grid>
			</Grid>
		);
	},
);
