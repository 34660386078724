import { CancelOutlined, Refresh, Share } from '@mui/icons-material';
import {
	CircularProgress,
	Divider,
	ListItemIcon,
	ListItemText,
	MenuItem,
	Tab,
	Tabs,
	Tooltip,
} from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import {
	Payment,
	PaymentStatusTypes,
} from 'modules/clients/apiGateway/payments4/payments';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { CopyToClipboardIconButton } from 'shared/components/copyToClipboardIconButton';
import { T4DrawerBase } from 'shared/components/drawer/drawerBase';
import { DrawerCancelButton } from 'shared/components/drawer/drawerButtons';
import { OverflowMenu } from 'shared/components/overflowMenu';
import { useUser } from 'shared/hooks/useUser';
import { useGetPayment } from '../hooks/usePayments';
import { canCancelPayment, canGetPaymentBankStatus } from '../utilities';
import { PaymentApprovalsTab } from './paymentApprovalsTab';
import { PaymentHistoryTab } from './paymentHistoryTab';
import { PaymentStatusAndDetailsTab } from './paymentStatusAndDetailsTab';

interface PaymentDetailsDrawerProps {
	paymentId: string | null;
	onClose: () => void;
	refetch: () => Promise<QueryObserverResult<Payment[], Error>>;
	setPaymentIdToCancel?: (id: string) => void;
	isBankStatusLoading?: boolean;
	getBankStatus?: (id: string) => Promise<boolean>;
}

export const PaymentDetailsDrawer: FC<PaymentDetailsDrawerProps> = ({
	paymentId,
	onClose,
	refetch,
	setPaymentIdToCancel,
	isBankStatusLoading,
	getBankStatus,
}) => {
	// #region State
	const { payments4 } = useUser();
	const { enqueueSnackbar } = useSnackbar();

	const {
		isLoading,
		isFetching,
		data: payment,
		error,
		refetch: refetchPayment,
	} = useGetPayment(paymentId);
	useEffect(() => {
		if (!isLoading && error?.message) {
			enqueueSnackbar(error.message, {
				variant: 'error',
			});
			onClose();
		}
	}, [isLoading, error, enqueueSnackbar, onClose]);

	const [tabIndex, setTabIndex] = useState<number>(0);

	// if payent is rejected, open in approvals tab first
	useEffect(() => {
		if (
			payment?.currentStatus === PaymentStatusTypes[PaymentStatusTypes.Rejected]
		)
			setTabIndex(1);
	}, [payment]);

	// #endregion

	return (
		<T4DrawerBase
			title="Payment Record Details"
			open={!!paymentId}
			onClose={() => {
				onClose();
				setTabIndex(0);
			}}
			tabs={
				<Tabs
					indicatorColor="primary"
					value={tabIndex}
					onChange={(_, index) => setTabIndex(index)}
				>
					<Tab label="Status and Details" tabIndex={0} />
					<Tab label="Approvals" tabIndex={1} />
					<Tab label="History" tabIndex={2} />
				</Tabs>
			}
			utilityActions={[
				<CopyToClipboardIconButton
					valueToCopy={window.location.href}
					initialTooltipText="Copy Link"
				>
					<Share />
				</CopyToClipboardIconButton>,
				payment &&
					(getBankStatus || setPaymentIdToCancel) &&
					(canGetPaymentBankStatus(payment) || canCancelPayment(payment)) && (
						<OverflowMenu id="payment-options-menu">
							{getBankStatus && canGetPaymentBankStatus(payment) && (
								<MenuItem
									onClick={async () => {
										if (!paymentId) return;
										if (await getBankStatus(paymentId)) {
											refetch();
											refetchPayment();
										}
									}}
									disabled={isBankStatusLoading}
								>
									<ListItemIcon>
										{isBankStatusLoading ? (
											<CircularProgress size="20px" color="secondary" />
										) : (
											<Refresh color="secondary" />
										)}
									</ListItemIcon>
									<ListItemText
										sx={(theme) => ({
											'& .MuiTypography-root': {
												color: theme.palette.secondary.main,
											},
										})}
									>
										Get Status
									</ListItemText>
								</MenuItem>
							)}
							{setPaymentIdToCancel && canCancelPayment(payment) && <Divider />}
							{setPaymentIdToCancel && canCancelPayment(payment) && (
								<Tooltip
									title={
										!payments4.isPaymentApprover ||
										!payments4.isPaymentInitiator
											? 'You do not have the correct permission to cancel payments. Contact your administrator if you require access.'
											: ''
									}
									placement="right"
									arrow
								>
									<span>
										<MenuItem
											onClick={() => {
												if (!paymentId) return;
												setPaymentIdToCancel(paymentId);
											}}
											disabled={
												!payments4.isPaymentApprover ||
												!payments4.isPaymentInitiator
											}
										>
											<ListItemIcon>
												<CancelOutlined color="error" />
											</ListItemIcon>
											<ListItemText
												sx={(theme) => ({
													'& .MuiTypography-root': {
														color: theme.palette.error.main,
													},
												})}
											>
												Cancel Payment
											</ListItemText>
										</MenuItem>
									</span>
								</Tooltip>
							)}
						</OverflowMenu>
					),
			]}
			actions={[
				<DrawerCancelButton
					label="Close"
					onCancel={() => {
						onClose();
						setTabIndex(0);
					}}
				/>,
			]}
			initializing={isLoading || isFetching}
			disableNavigationCollapse
		>
			<PaymentStatusAndDetailsTab tabIndex={tabIndex} payment={payment} />
			<PaymentApprovalsTab
				tabIndex={tabIndex}
				payment={payment}
				refetchPayments={() => {
					refetch();
					return refetchPayment();
				}}
			/>
			<PaymentHistoryTab tabIndex={tabIndex} payment={payment} />
		</T4DrawerBase>
	);
};
