import { createTheme, PaletteColorOptions, Theme } from '@mui/material';
import { ColorPartial, PaletteColor } from '@mui/material/styles/createPalette';
import { brandColors } from './brandColors';

export function t4Theme(): Theme {
	return createTheme({
		typography: {
			allVariants: {
				color: brandColors.charcoal['500'],
			},
			banner: {
				color: '#fff',
			},
			h1: {
				color: '#fff',
				fontSize: '2rem',
			},
			h2: {
				fontSize: '1.75rem',
			},
			h3: {
				fontSize: '1.5rem',
			},
			h4: {
				fontSize: '1.25rem',
			},
			h5: {
				fontSize: '1.25rem',
			},
			h6: {
				fontSize: '1rem',
			},
		},
		common: {
			text: brandColors.grey.legacy,
			maize: {
				main: brandColors.maize['500'],
				...brandColors.maize,
			},
			denim: {
				main: brandColors.denim['500'],
				...brandColors.denim,
			},
			charcoal: {
				main: brandColors.charcoal['500'],
				...brandColors.charcoal,
			},
			cornflower: {
				main: brandColors.cornflower['500'],
				...brandColors.cornflower,
			},
			equityGreen: {
				main: brandColors.equityGreen['500'],
				...brandColors.equityGreen,
			},
			jasper: {
				main: brandColors.jasper['500'],
				...brandColors.jasper,
			},
			debtRed: {
				main: brandColors.debtRed['500'],
				...brandColors.debtRed,
			},
		},
		cash4: {
			negativeCell: {
				background: brandColors.debtRed.A15,
				text: brandColors.debtRed['500'],
			},
		},
		palette: {
			primary: {
				main: brandColors.equityGreen['500'],
				dark: brandColors.equityGreen['500'],
				contrastText: '#fff',
			},
			secondary: {
				main: brandColors.denim['500'],
				contrastText: '#fff',
			},
			error: {
				light: brandColors.errorRed.A80,
				main: brandColors.errorRed.A100,
				contrastText: '#fff',
			},
			background: {
				default: '#fff',
			},
			lightBackground: {
				main: '#eeeeee',
			},
			text: {
				primary: brandColors.charcoal['500'],
				disabled: 'rgba(0, 0, 0, 0.38)',
			},
			header: {
				main: brandColors.charcoal['500'],
				contrastText: '#fff',
			},
			secondaryHeader: {
				main: brandColors.denim['500'],
				contrastText: '#fff',
			},
			charcoal: {
				...brandColors.charcoal,
			},
			equityGreen: {
				...brandColors.equityGreen,
			},
			denim: {
				...brandColors.denim,
			},
			maize: {
				...brandColors.maize,
			},
			cornflower: {
				...brandColors.cornflower,
			},
			grey: {
				...brandColors.grey,
			},
			debtRed: {
				...brandColors.debtRed,
			},
			jasper: {
				...brandColors.jasper,
			},
		},
		components: {
			MuiGrid: {
				styleOverrides: {
					root: {
						'& .MuiDataGrid-row.Mui-selected .MuiDataGrid-cell': {
							backgroundColor: brandColors.equityGreen['50'],
						},
						'& .MuiDataGrid-row.Mui-selected.Mui-hovered .MuiDataGrid-cell': {
							backgroundColor: brandColors.equityGreen['50'],
						},
						'& .MuiDataGrid-row.Mui-hovered .MuiDataGrid-cell': {
							backgroundColor: brandColors.grey['50'],
						},
					},
				},
			},
			MuiAppBar: {
				styleOverrides: {
					colorPrimary: {
						background: brandColors.charcoal['500'],
					},
					colorSecondary: {
						background: brandColors.denim['500'],
					},
				},
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {
						'& .MuiTypography-root': {
							color: brandColors.charcoal,
						},
					},
				},
			},
			MuiListItemButton: {
				styleOverrides: {
					root: {
						'&.Mui-selected': {
							backgroundColor: '#eeeeee',
						},
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						padding: '.375rem 1rem',
						'&.MuiButton-text.MuiButton-textSecondary': {
							'&:hover': {
								backgroundColor: brandColors.denim.A10,
							},
						},
					},
					startIcon: {
						marginTop: '-1px',
					},
				},
			},
			MuiChip: {
				styleOverrides: {
					colorPrimary: {
						background: brandColors.denim['100'],
						color: brandColors.denim['900'],
					},
					root: {
						'&.MuiButtonBase-root.MuiChip-root': {
							'&:hover': {
								background: brandColors.denim['100'],
								color: brandColors.denim['900'],
							},
							'&.MuiChip-deletable': {
								'&:hover': {
									background: brandColors.errorRed['50'],
									color: brandColors.errorRed['900'],

									'.MuiChip-deleteIcon': {
										color: brandColors.errorRed['900'],
									},
								},
								'.MuiChip-deleteIcon': {
									color: brandColors.denim['900'],
								},
							},
						},
					},
				},
			},
			MuiTabs: {
				defaultProps: {
					textColor: 'inherit',
					indicatorColor: 'secondary',
				},
			},
		},
	});
}

//#region Theme Overrides

declare module '@mui/material/styles' {
	interface Theme {
		common?: {
			text?: React.CSSProperties['color'];
			maize?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			denim?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			charcoal?: {
				main: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			cornflower?: {
				main: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			equityGreen?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			jasper?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			debtRed?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
		};
		cash4?: {
			negativeCell?: {
				background?: React.CSSProperties['color'];
				text?: React.CSSProperties['color'];
			};
		};
	}

	interface ThemeOptions {
		common?: {
			text?: React.CSSProperties['color'];
			maize?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			denim?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			charcoal?: {
				main: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			cornflower?: {
				main: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			equityGreen?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			jasper?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
			debtRed?: {
				main: React.CSSProperties['color'];
				50: React.CSSProperties['color'];
				500: React.CSSProperties['color'];
			};
		};
		cash4?: {
			negativeCell?: {
				background?: React.CSSProperties['color'];
				text?: React.CSSProperties['color'];
			};
		};
	}

	interface TypographyVariants {
		banner: React.CSSProperties;
	}

	interface TypographyVariantsOptions {
		banner: React.CSSProperties;
	}

	interface Palette {
		header?: PaletteColor;
		secondaryHeader?: PaletteColor;
		lightBackground?: PaletteColor;
		charcoal: ColorPartial;
		equityGreen: ColorPartial;
		denim: ColorPartial;
		maize: ColorPartial;
		cornflower: ColorPartial;
		debtRed: ColorPartial;
		jasper: ColorPartial;
	}

	interface PaletteOptions {
		header?: PaletteColorOptions;
		secondaryHeader?: PaletteColorOptions;
		lightBackground?: PaletteColorOptions;
		charcoal: ColorPartial;
		equityGreen: ColorPartial;
		denim: ColorPartial;
		maize: ColorPartial;
		cornflower: ColorPartial;
		debtRed: ColorPartial;
		jasper: ColorPartial;
	}
}

declare module '@mui/material/Typography' {
	interface TypographyPropsVariantOverrides {
		banner?: true;
	}
}

//#endregion
