import { useAuth0 } from '@auth0/auth0-react';
import { LDProvider, basicLogger } from 'launchdarkly-react-client-sdk';
import { observer } from 'mobx-react-lite';
import { FC, ReactNode } from 'react';
import { useConfigurations } from 'shared/hooks/useConfigurations';

export interface T4LanchDarklyProviderProps {
	children: ReactNode;
}

export const T4LanchDarklyProvider: FC<T4LanchDarklyProviderProps> = observer(
	({ children }) => {
		const { featureFlagKey } = useConfigurations();
		const { user } = useAuth0();

		return (
			<LDProvider
				clientSideID={featureFlagKey}
				context={{
					kind: 'user',
					key: user?.sub,
					custom: {
						organization: user?.org_id,
					},
				}}
				options={{
					...(process.env.REACT_APP_DISABLE_LAUNCHDARKLY_LOGGING === 'true'
						? { logger: basicLogger({ level: 'none' }) }
						: {}),
				}}
			>
				{children}
			</LDProvider>
		);
	},
);
