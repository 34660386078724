import { Box, Tooltip, Typography, useTheme } from '@mui/material';
import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { FC } from 'react';
import { PartyValueChange } from './utilities';

export const TemplatePartyErrorTooltip: FC<{
	id: string;
	header: string;
	errors: string[];
	labelBox: JSX.Element;
}> = ({ id, header, errors, labelBox }) => {
	return (
		<Tooltip
			title={<TemplatePartyErrorBox id={id} header={header} errors={errors} />}
			placement="left"
			arrow
		>
			<span>{labelBox}</span>
		</Tooltip>
	);
};

export const TemplatePartyErrorBox: FC<{
	id: string;
	header: string;
	errors: string[];
	defaultTextColor?: boolean;
}> = ({ id, header, errors, defaultTextColor }) => {
	const theme = useTheme();
	const typographySx = {
		color: defaultTextColor ? undefined : theme.palette.primary.contrastText,
		display: 'block',
	};
	return (
		<Box>
			<Typography variant="caption" sx={typographySx}>
				{header}
			</Typography>
			{errors.map((errorMessage: string, index) => (
				<Typography key={`${id}-${index}`} variant="caption" sx={typographySx}>
					- {errorMessage}
				</Typography>
			))}
		</Box>
	);
};

export const TemplatePartyAlert: FC<{
	id: string;
	errorMessage: string;
	warningMessage: string;
	partyDiff: PartyValueChange[];
	validate: () => void;
}> = ({ id, errorMessage, warningMessage, partyDiff, validate }) => {
	try {
		validate();
	} catch (error: any) {
		return (
			<T4Alert severity="error">
				<TemplatePartyErrorBox
					id={id}
					header={errorMessage}
					errors={error?.errors ?? []}
					defaultTextColor
				/>
			</T4Alert>
		);
	}

	if (partyDiff.length > 0) {
		return (
			<T4Alert severity="warning">
				<Typography variant="caption">{warningMessage}</Typography>
			</T4Alert>
		);
	}

	return null;
};
