import { T4Alert } from 'features/entity4/shared/components/atoms/t4Alert';
import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { CurrencySource, formatCurrency } from 'utilities/currencyUtils';
import { C4AlertBold } from './c4AlertBold';

export type SelectedTotalAlertProps = {
	amount: number;
	currencyCode: string;
	currencySource: CurrencySource;
	isAmountValid: boolean;
};

export const SelectedTotalAlert: FC<SelectedTotalAlertProps> = observer(
	({ amount, currencyCode, currencySource, isAmountValid }) => {
		if (isAmountValid) {
			return (
				<T4Alert severity="info" fullWidth sx={{ width: '100% ' }}>
					{'The '}
					<C4AlertBold>{`${
						currencySource === CurrencySource.Account
							? 'account currency'
							: 'reporting currency'
					}`}</C4AlertBold>
					{' total of the selected rows is '}
					<C4AlertBold>{`${formatCurrency(amount, {
						currency: currencyCode,
					})} ${currencyCode}`}</C4AlertBold>
					{'.'}
				</T4Alert>
			);
		} else {
			return (
				<T4Alert severity="info" fullWidth sx={{ width: '100% ' }}>
					{'The '}
					<C4AlertBold>{`${
						currencySource === CurrencySource.Account
							? 'account currency'
							: 'reporting currency'
					}`}</C4AlertBold>
					{
						' total of the selected rows is unavailable due to missing exchange rates.'
					}
				</T4Alert>
			);
		}
	},
);
